Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.manufacturerAPIEndPoint = "bx_block_categories/manufacturers";
exports.familyAPIEndPoint = "bx_block_categories/families";
exports.sectorAPIEndPoint = "bx_block_categories/sectors";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"


// Customizable Area End