import { Link } from 'react-router-dom';
import React, { Component } from 'react';
// Customizable Area Start
import {
  styled,
} from "@material-ui/core";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  carouselItem?:any;
  // Customizable Area End
}

export class Footer extends Component<Props>{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFooter = () => {
        return (
          <>
            <div style={styles.footerContainer}>
              <div>
                <div style={{ ...styles.footerBody, flexDirection: "row", flexWrap: "wrap" }}>
                  <div style={{ ...styles.leftFooter, textAlign: "center", flexDirection: "column" }}>
                    <div style={{ ...styles.footerLogoBody, flexDirection: "row" }}>
                      <img src={require("./logo.png")} alt="Icon" style={styles.logoicon} />
                      <p style={styles.footerLogoHeading}>QED Food Tech</p>
                    </div>
                    <Parangraph>{console.log(this.props,"Footer")}
                     {this.props.carouselItem}
                    </Parangraph>
                  </div>
                  <div style={{...styles.rightFooter, flexWrap: "wrap", textAlign: "center", }}>
                    <div>
                      <p style={{...styles.footerHeading, textAlign:"left"}}> Contact Us </p>
                     <Link to="/Contactus" style={styles.footerText}> <p style={{textDecoration:"none"}}> General Contact Form</p> </Link>
                    </div>
                    <div style={{textAlign: "justify" }}>
                      <p style={styles.footerHeading}> Legal Information</p>
                      <Link to="/TermsAndConditions" style={styles.footerText}>
                      <p style={{textAlign:"left", textDecoration:"none"}}> Terms & Conditions </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
   <div>
      {this.renderFooter()}
      <div style={{...styles.footerBottom, textAlign: "center",}}>
        <p style={styles.bottomText}>
          <span> © </span> qed food tech all rights reserved{" "}
        </p>
      </div>
    </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Parangraph = styled("p")({
  width: "22vw", 
  textAlign: "justify",
  "@media (min-width:320px) and (max-width:820px)": {
    width: "100% !important",
  },
});

const styles = {
  footerText: {
   textDecoration:"none",
   color:"#000"
  },
  footerContainer: {
    width: "100%",
    backgroundColor: "#E9B848",
    color: "#1B2437",
    marginTop:"40px"
  },
  footerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent:"space-between",
    padding: "20px 4% 20px",
    margin: "auto",
  },
  leftFooter: {
    marginBottom: "20px",
  },
  footerLogoBody: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  footerLogoHeading: {
    fontSize: "22px",
    fontFamily: "Mulish",
    marginLeft: "10px",
  },
  footerDescription: {
    maxWidth: "400px",
    textAlign: "center",
    fontFamily: "Mulish",
  },
  rightFooter: {
    display: "flex",
    justifyContent: "center",
    gap: "60px",
  },
  footerHeading: {
    fontSize: "20px",
    fontFamily: "Mulish",
    marginBottom: "5px",
  },
  footerBottom: {
    backgroundColor: "#DCAE42",
    padding: "10px 0",
  },
  bottomText: {
    margin: "0",
    fontSize: "14px",
    fontFamily: "Mulish",
  },
  logoicon: {
    height: "22px",
  },
  };
// Customizable Area End