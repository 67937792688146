//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// const baseUrl = require("../../../framework/src/config.js")
const baseUrl = require("../../../framework/src/config.js")
import StorageProvider from "../../../framework/src/StorageProvider";
import { setStorageData } from "../../../framework/src/Utilities";
export interface ValidResponseType {
  data: Category
  meta: {
    message: string
  }
  message: string;
  catalogue_id: number;
}

export interface Category {
  id: string;
  type: string;
  attributes: {
    part_number: string;
    description: string;
    discount_price: null;
    price: string | number;
    images: { url: string }[];
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategory[];
    category_name: string;
    manufacturer_name: string;
    family_name: string;
    complete_range: CompleteRange[];
  };
}

interface CompleteRange {id:string; name:string; images: { url: string }[]}
interface CompleteRange {suitable_id:string; suitable_item_name:string; images: { url: string }[]}
interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  error: string;
}

export interface InvalidResponseTypeToken {
  errors: Array<ErrorPayloadType>;
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

interface ReviewAttributes {
  id: number;
  catalogue_id: number;
  rating: number;
  anonymous: boolean;
  admin_verify: boolean;
  comment: string;
  created_at: string;
  updated_at: string;
  customer_name: string;
}

interface Review {
  isReadMore: boolean;
  id: string;
  type: string;
  attributes: ReviewAttributes;
}

interface Meta {
  message: string;
}

interface ReviewData {
  data: Review[];
  meta: Meta;
  average_rating: number;
  review_count: number;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  activeDiv:{[key:string]:boolean};
  productData: {
    part_number: string;
    description: string;
    discount_price: null;
    price: string | number;
    images: { url: string }[];
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategory[];
    category_name: string;
    manufacturer_name: string;
    family_name: string
    complete_range: CompleteRange[]
    suitable_products: CompleteRange[]
  };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number
  hasSuccessErrorForProDesc: HasSuccessErrorType;
  isFeedbackOpen: boolean;
  feedbackItem: ReviewData;
  completeRangeTab: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  postAddToCartItemApiCallId: string = '';
  postAddToWishlistItemApiCallId: string = '';
  getFeedbackItemApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      activeDiv:{},
      productData: {
        part_number: "",
        description: "",
        discount_price: null,
        price: "",
        images: [],
        id: 0,
        name: "",
        created_at: "",
        updated_at: "",
        sub_categories: [],
        category_name: "",
        manufacturer_name: "",
        family_name: "",
        complete_range: [],
        suitable_products: [],
      },
      product_id: 111,
      focusedImageIndex:1,
      hasSuccessErrorForProDesc:  {
        isOpen: false,
        isSeverity: "error",
        isMessage: "",
      },
      isFeedbackOpen: false,
      feedbackItem: {
        data: [],
        meta: {
          message: ""
        },
        average_rating: 0,
        review_count: 0
      },
      completeRangeTab:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.isValidResponseProDesc(responseJson)) {
        this.apiSuccessCallBacksProDesc(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseProDesc(responseJson)) {
        this.apiFailureCallBacksProDesc(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setState({product_id:parseInt(this.props.navigation.getParam('id'))},()=>{

      this.getPostData();
      this.getFeedbackItem();
    })
  }

  onGoBack = () => {

    this.props.navigation.navigate("BuildingBlocks");
  };
  getPostData = () => {
    this.setState({
      productData: { name: "" }
    });
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}/${this.state.product_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClick = (clickID: string) => {

    this.setState(prevState => ({
      activeDiv: {
        // ...prevState.activeDiv,
        [clickID]: !prevState.activeDiv[clickID]
      }
    }));
  };
  reRenderPrdoctDescription=(productID:number)=>{
    this.setState({product_id:productID},()=>{
      this.getPostData();
      this.getFeedbackItem();
    });

  }
  goBack=()=>{
    this.props.navigation.goBack()
  }

  isValidResponseProDesc = (responseJson: ValidResponseType & ReviewData) => {
    return responseJson && (responseJson.data || responseJson.catalogue_id);
  };

  isInValidResponseProDesc = (responseJson: InvalidResponseType  & InvalidResponseTypeToken) => {
    return responseJson && (responseJson.errors || responseJson.error);
  };

  apiSuccessCallBacksProDesc = (
    apiRequestCallId: string,
    responseJson: ValidResponseType & ReviewData
  ) => {
    if (apiRequestCallId === this.postAddToCartItemApiCallId) {
      this.handleNavigateFromProDesc("Cart")
    }
    if (apiRequestCallId === this.postAddToWishlistItemApiCallId) {
      this.handleNavigateFromProDesc("Wishlist")
    }
    if (apiRequestCallId === this.productApiItemCallId) {
      this.setState({
        activeDiv: { [responseJson.data.attributes.images[0].url]: true },
        productData: responseJson.data.attributes
      });
    }
    if (apiRequestCallId === this.getFeedbackItemApiCallId) {
      responseJson.data.map((item)=>{
        item.isReadMore = false
      })
      this.setState({
        feedbackItem:responseJson
      });
    }
  };

  apiFailureCallBacksProDesc = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType & InvalidResponseTypeToken
  ) => {
    if (apiRequestCallId === this.postAddToWishlistItemApiCallId) {
      this.snackBarShowAProDesc(true, "error", responseJson.error);
    }
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      setStorageData("productDesID", this.state.product_id)
      this.handleNavigateFromProDesc("login");
    }
  };

  addToCart = async () => {
    const order_items = {
      catalogue_id: this.state.product_id,
    };
    const httpBody = {
      order_items,
    };
    this.postAddToCartItemApiCallId = await this.apiCallProduct({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postAddToCartItemApiEndPoint,
      body: httpBody
    });
  };

  addToWishlistProd = async () => {

    const httpBody = {
      catalogue_id: this.state.product_id,
    };
    this.postAddToWishlistItemApiCallId = await this.apiCallProduct({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postAddToWishlistItemApiEndPoint,
      body: httpBody
    });

  };

  handleNavigateFromProDesc = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  snackBarShowAProDesc = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForProDesc: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };

  handleProDescCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForProDesc: {
        isSeverity: "error",
        isOpen: false,
        isMessage: "",
      },
    });
  };

  apiCallProduct = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    let tokenApi = (await StorageProvider.get("token")) || "sdfghgfbvnvbbn";

    const header = {
      "Content-Type": contentType,
      "token": tokenApi
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleCloseFeedbackModal = () => {
    this.setState({
      isFeedbackOpen: false,
    })
  }

  handleOpenFeedbackModal = () => {
    this.setState({
      isFeedbackOpen: true,
    })
  }

  getFeedbackItem = async () => {
    this.getFeedbackItemApiCallId = await this.apiCallProduct({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getFeedbackItemApiEndPoint + this.state.product_id,
    });
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dayNum = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const suffix = this.getDaySuffix(dayNum);
    return `${dayNum}${suffix} ${month} ${year}`;
  }

  getDaySuffix(dayNum: number) {
    if (dayNum >= 11 && dayNum <= 13) {
      return 'th';
    }
    switch (dayNum % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  toggleExpand = (feedbackId: string) => {
    let feedbackItem = this.state.feedbackItem
    let feedbackItemIndex = this.state.feedbackItem.data.findIndex((item) => item.id === feedbackId);
    if (feedbackItemIndex !== -1) (
      feedbackItem.data[feedbackItemIndex].isReadMore = !feedbackItem.data[feedbackItemIndex].isReadMore
    )
    this.setState({
      feedbackItem: feedbackItem
    });
  }

  completeRangeTab = () => {
    this.setState({
      completeRangeTab: !this.state.completeRangeTab
    });
  }
  // Customizable Area End
}
