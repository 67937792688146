import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled
} from "@material-ui/core";
import { address, cart, editprofile, headerLogo, helpIcon, logout, orders, search, searchFilled, settings, user, wishlist } from "./assets";

// Customizable Area End
import HeaderNewController, { Props } from "./HeaderNewController";
export default class HeaderNew extends HeaderNewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeader = () => {
    return (
      <MainHeader>
        <div>
          <img src={headerLogo} alt="Logo" style={styles.headerLogo} />
        </div>
        <div style={styles.buttonContainer}>
          {this.state.token ?
            <div style={{ ...styles.iconsContainer, position: "relative" }} data-test-id="innerTestId">
              <img src={wishlist} alt="Icon" className='imageUserProfile' data-test-id="wishlistTestId" onClick={() => this.navigateToLandingPage("Wishlist")} />
              <img src={cart} alt="Icon" className='imageUserProfile' data-test-id="cartTestId" onClick={() => this.navigateToLandingPage("Cart")} />
              <button data-test-id="userProfileTestId" onClick={() => this.navigateToLandingPage("CustomisableUserProfilesWeb")} style={styles.iconButton}>
                <img src={user} alt="Icon" className='imageUserProfile' data-test-id="TestUserProfileHover" onClick={this.userProfileHover} onMouseEnter={this.userProfileHover} />
              </button>
              {this.state.userProfileHover && <div className='hoverConatiner' data-test-id="innerHoverTestId" onMouseLeave={this.userProfileHoverFalse}>
                <div className='textContaoner'>
                  <div className="arrow"></div>
                  <Box className="imagehead logoutPaddingTop">
                    <img className='imageUserProfileDown' src={this.imageShowSrc()} alt="userPofile" />
                    <Box className="nameHead">
                      <Typography className="nametext">{this.state.full_name}</Typography>
                      <Typography className="numbertext">+91 {this.state.contact}</Typography>
                    </Box>
                  </Box>
                  <hr className="hrLine" />
                  <Box className="imagehead" data-test-id="ordersTestId" onClick={() => this.navigateToLandingPage("Orders")} >
                    <img src={orders} alt="" className="imageUserProfile imagehoveHeading" />
                    <Typography className="hovertext" >Orders</Typography>
                  </Box>
                  <Box className="imagehead" data-test-id="settingsTestId" onClick={() => this.navigateToLandingPage("CustomisableUserProfilesSettings")}>
                    <img src={settings} alt="" className="imageUserProfile imagehoveHeading" />
                    <Typography className="hovertext"  >Settings</Typography>
                  </Box>
                  <Box className="imagehead" data-test-id="addressTestId" onClick={() => this.navigateToLandingPage("ProfileAddressWeb")}>
                    <img src={address} alt="" className="imageUserProfile imagehoveHeading" />
                    <Typography className="hovertext">Saved Address</Typography>
                  </Box>
                  <hr className="hrLine" />
                  <Box className="imagehead" data-test-id="profileTestId" onClick={() => this.navigateToLandingPage("CustomisableUserProfilesWeb")}>
                    <img src={editprofile} alt="" className="imageUserProfile imagehoveHeading" />
                    <Typography className="hovertext">Edit Profile</Typography>
                  </Box>
                  <Box className="imagehead logoutPadding" data-test-id="logoutTestId" onClick={this.handleLogout}>
                    <img src={logout} alt="" className="imageUserProfile imagehoveHeading" />
                    <Typography className="hovertext">Logout</Typography>
                  </Box>
                </div>
              </div>}
            </div>
            :
            <>
              <button style={styles.iconButton}><img src={helpIcon} alt="Icon" style={styles.icon} /></button>
              <button data-test-id="TestNavigationLogin" onClick={() => this.navigateToLandingPage("login")} style={styles.signUpButton}>
                Login
              </button>
            </>
          }
        </div>
      </MainHeader>
    );
  };
  renderNav = () => {
    return (
      <div style={styles.navigation}>
        <div style={{ ...styles.navigations, flexWrap: "wrap" }}>
          <CustomButton style={{ fontWeight: this.handleActive(window.location.pathname, '/') }} data-test-id="TestNavigationLanding" onClick={() => this.navigateToLandingPage("LandingPage")}>Home</CustomButton>
          <CustomButton
            data-test-id="AboutUs"

            style={{ fontWeight: this.handleActive(window.location.pathname, '/AboutUs') }}

            onClick={() => this.navigateToLandingPage("AboutUs")}>
            About Us
          </CustomButton>
          <CustomButton
            data-test-id="BrandListing"
            style={{ fontWeight: this.handleActive(window.location.pathname, '/Spares') }}
            onClick={() => this.navigateToLandingPage("BrandListing")}>Spares</CustomButton>
          <CustomButton
            data-test-id="Catalogue"
            style={{ fontWeight: 400 }}
            onClick={this.handleServiceReq}>Service</CustomButton>
          <CustomButton
            data-test-id="CatalogueTestId"
            style={{ fontWeight: this.handleActive(window.location.pathname, '/Retail') }}

            onClick={() => this.navigateToLandingPage("Catalogue")}>Retail</CustomButton>
          <CustomButton
            data-test-id="News"

            style={{ fontWeight: this.handleActive(window.location.pathname, '/News') }}

            onClick={() => this.navigateToLandingPage("News")}>News</CustomButton>

          <CustomButton
            data-test-id="contactTestId"

            style={{ fontWeight: this.handleActive(window.location.pathname, '/Contactus') }}

            onClick={() => this.navigateToLandingPage("Contactus")}>Contact Us</CustomButton>
        </div>
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div>{this.renderHeader()}</div>
        <div>{this.renderNav()}</div>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CustomButton = styled('button')({
    cursor: "pointer",
    color: "#6A6A6A",
    textDecoration: "none",
    background: "none",
    border: "none",
    fontSize: "16px",
    fontFamily: "Mulish",
    padding:"8px 12px",
    whiteSpace:"nowrap",
    transition: "color 0.3s",
    '@media (max-width: 768px)':{
      fontSize:"14px",
      padding:"6px 10px"
    },
    '@media (max-width: 400px)':{
      fontSize:"12px",
      padding:"4px 18px",
    },
})
const MainHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 20px",
  background: "#fff",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  "@media (max-width: 768px)": {
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "10px",
  },
  "@media (min-width:320px) and (max-width:575px)": {
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px"
  },
  "& .arrow": {
    position: "absolute",
    top: "-6px",
    left: "calc(100px + 38px)",
    width: "50px",
    height: "25px",
    backgroundColor: "#fff",
    transform: "rotate(-45deg)",
    transformOrigin: "top right",
    lineHeight: "50px",
    zIndex: -1,
  },
  "& .hrLine": {
    width: "100%",
    color: "#F2F2F2",
  },
  "& .imageUserProfileDown": {
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  },
  "& .imagehoveHeading": {
    opacity: "0.5"
  },
  "& .numbertext": {
    fontSize: "12px",
    opacity: "0.5"
  },
  "& .nametext": {
    fontSize: "16px",
    fontWeight: "bold"
  },
  "& .hovertext": {
    fontSize: "16px",
    opacity: "0.5"
  },
  "& .imagehead": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
    padding: "5px 20px",
  },
  "& .logoutPadding": {
    paddingBottom: "16px"
  },
  "& .logoutPaddingTop": {
    paddingTop: "16px"
  },
  "& .nameHead": {
    display: "flex",
    flexDirection: "column",
  },
  "& .imageUserProfile": {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  "& .hoverConatiner": {
    background: "trasprant",
    position: "absolute",
    top: "4px",
    right: "-14px",
    zIndex: -1,
    padding: "20px"
  },
  "& .hovercontainerText": {
    fontSize: "12px",
    textDecoration: "none",
    color: "#000"
  },
  "& .textContaoner": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    background: "#fff",
    width: "200px",
    position: "relative",
    boxShadow: "0px 0px 9px -4px rgb(0, 0, 0)",
    borderRadius: "8px",
  },
});
const styles : {[key:string]:any} =  {
  headerLogo: {
    height: "auto",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    height: "4vh",
    borderRadius: "20px",
    overflow: "hidden",
    background: "white",
    padding: "5px",
    border: "1px solid #898A8B",
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    padding: "10px",
    background: "transparent",
    fontSize: "14px",
  },
  searchIcon: {
    marginLeft: "10px",
    marginRight: "5px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "18px",
  },
  signUpButton: {
    border: "none",
    background: "black",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none"
  },
  iconsContainer: {
    display: "flex",
    gap: "18px",
    zIndex: 9999
  },
  iconButton: {
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  navigation: {
    display: "flex",
    background: "linear-gradient(to right, #E8B846 10%, #F3E34E 80%)",
    padding: "12px",
    flexWrap: "wrap",
  },
  navigations: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  navButton: {
    cursor: "pointer",
    color: "#6A6A6A",
    textDecoration: "none",
    background: "none",
    border: "none",
    fontSize: "16px",
    fontFamily: "Mulish",
    marginRight: "20px",
    transition: "color 0.3s",
  },
};
// Customizable Area End