import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
export interface ValidResponseType {
  message:string;
}

export interface ErrorPayloadType {
  key: string;
  email: string;
  failed_login: string;
}

export interface InvalidResponseType {
  error: string
}

export interface HasSuccessErrorTypeForReset {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  blankPasswordError: boolean;
  passwordError: boolean;
  password: string;
  newPassword: string;
  blankNewPasswordError: boolean;
  newPasswordMatchError: boolean;
  resetPasswordSuccessModel: boolean;
  resetPasswordToken: string;
  hasSuccessErrorForReset: HasSuccessErrorTypeForReset;
  showPasswordReset: boolean;
  showPasswordConfirmReset: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postResetApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      blankPasswordError: false,
      passwordError: false,
      password: "",
      newPassword: "",
      blankNewPasswordError: false,
      newPasswordMatchError: false,
      resetPasswordSuccessModel: false,
      resetPasswordToken: "",
      hasSuccessErrorForReset: {
        isOpen: false,
        isSeverity: "error",
        isMessage: ""
      },
      showPasswordReset: false,
      showPasswordConfirmReset: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        return;
      }

      if (this.isValidResponseReset(responseJson)) {
        this.apiSuccessCallBacksReset(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseReset(responseJson)) {
        this.apiFailureCallBacksReset(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let resetToken = await StorageProvider.get("resetToken");
    this.setState({
      resetPasswordToken:resetToken
    })
  }

  apiCallReset = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    let header = {
      "Content-Type": contentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponseReset = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.message;
  };

  isInValidResponseReset = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.error;
  };

  apiFailureCallBacksReset = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.postResetApiCallId) {
      this.snackBarErrorApiLogin(responseJson)
    }
  }

  apiSuccessCallBacksReset = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.postResetApiCallId) {
      this.setState({
       resetPasswordSuccessModel:true
      })
    }
  }

  handleResetCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForReset: {
        isSeverity: "error",
        isOpen: false,
        isMessage: ""
      }
    });
  };

  snackBarErrorApiLogin = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessErrorForReset: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.error
      }
    });
  };

  handleNavigateFromReset = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  getLoginPasswordErrorMessage() {
    const { blankPasswordError, passwordError } = this.state;

    if (blankPasswordError) {
      return "Please enter a new password.";
    } else if (passwordError) {
      return "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character (!@#$&*?<>',[]}{=-)(^%`~+.:;_).";
    }
    
    return null;
  }

  getLoginPasswordNewErrorMessage() {
    const {newPasswordMatchError, blankNewPasswordError } = this.state;

    if (blankNewPasswordError) {
      return "Please enter a confirm password.";
    }else if (newPasswordMatchError) {
      return "New password and confirmation password do not match."
    }

    return null;
  }

  handleClickShowPasswordResetInput = () => {
    this.setState({
      showPasswordConfirmReset: !this.state.showPasswordConfirmReset
    })
  };

  handleResetPasswordInput = (confirmEvent: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const isValidPassword = passwordRegex.test(confirmEvent.target.value)
    this.setState({
      password: confirmEvent.target.value
    });
    if (confirmEvent.target.value === "") {
      this.setState({
        passwordError: false,
        blankPasswordError: true,
      });
    } else if (isValidPassword === false) {
      this.setState({
        passwordError: true,
        blankPasswordError: false,
      });
    }
    else {
      this.setState({
        passwordError: false,
        blankPasswordError: false,
      });
    }
  };

  handleClickShowPasswordReset = () => {
    this.setState({
      showPasswordReset: !this.state.showPasswordReset
    })
  };

  handleResetNewPasswordInput = (resetNewEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newPassword: resetNewEvent.target.value
    });
    if (resetNewEvent.target.value === "") {
      this.setState({
        blankNewPasswordError: true,
      });
    }  else if (this.state.password !== resetNewEvent.target.value) {
      this.setState({
        newPasswordMatchError: true,
      });
    }
    else {
      this.setState({
        newPasswordMatchError: false,
        blankNewPasswordError: false,
      });
    }
  };

  handleReset = async (preventEvent: { preventDefault: () => void; }) => {
    preventEvent.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;

    const errors = {
      blankPasswordError: !this.state.password,
      passwordError: !passwordRegex.test(this.state.password),
      newPasswordMatchError: this.state.password !== this.state.newPassword,
      blankNewPasswordError: !this.state.newPassword
    };

    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      let body = {
        new_password: this.state.password,
        confirm_new_password: this.state.newPassword,
        reset_token: this.state.resetPasswordToken
      }
      this.postResetApiCallId = await this.apiCallReset({
        contentType: configJSON.forgotPasswordAPiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.postResetPasswordAPIEndPoint,
        body: body
      });
    }
  };
  // Customizable Area End
}