// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist";
exports.labelBodyText = "Wishlist Body";
exports.deleteAPiMethod = "DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.urlGetWishlist = "bx_block_item/items";
exports.getWishlistApiEndPoint = "bx_block_categories/wishlist";
exports.toggleWishlist = "bx_block_wishlist/toggle_wishlist"
exports.deleteWIshlistItemApiEndPoint = "bx_block_categories/remove_from_wishlist"
exports.postAddToCartItemApiEndPoint = "bx_block_shopping_cart/add_to_cart"
// Customizable Area End
