import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  styled,
} from "@material-ui/core";
import { Singuptextborder, backgroundImage, logo } from "../../email-account-registration/src/assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderLoginUserEmailInput = () => {
    return (
      <Box className="loginFormFieldContainer">
        <Typography className="loginFormFieldLabel">Email*</Typography>
        <TextField
          error={this.state.emailError || this.state.blankEmailError} helperText={
            this.state.emailError || this.state.blankEmailError
              ? this.state.emailErrorMsg
              : ""
          }
          placeholder="Email"
          className={this.state.emailError || this.state.blankEmailError ? "forgotFormInputBoxError" : "forgotFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleLoginUserEmailInput}
          data-test-id="emailInputTestID"
        />
      </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainForgotContainer container>
        <Grid item xs={false} sm={6} md={7} lg={7} className="image_Container_Box" >
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box className="signUp_Main_Container">
            <Box className="login_Logo_Container">
              <Box>
                <img src={logo} alt="logo" className="logo" />
              </Box>
              <Box className="loginTextHeader">
                <Typography className="loginUpText">
                  Forgot Password
                </Typography>
                <img src={Singuptextborder} alt="logintextborder" className="logintextborder" />
              </Box>
            </Box>
            <form data-test-id="formSubmitTestID" autoComplete="off" className="forgot_Main_Container" onSubmit={this.handleForgot}>
              {this.renderLoginUserEmailInput()}
              <Box className="btnMainContainerForgot" >
                <Button className="submitButtonForgot" type="submit">Submit</Button>
              </Box>
            </form>
          </Box>
          <SnackBarAlert
              data-test-id="snackBarTestID"
              snackBarProps={this.state.hasSuccessErrorForForgot}
              handleCloseSnack={this.handleForgotPasswordCloseSnackbar}
            />
        </Grid>
      </MainForgotContainer>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainForgotContainer = styled(Grid)({
  height: "100%",
  width: "100%",
  "@media(max-width:1300px)": {
    "& .signUp_Main_Container": { padding: "8%", }
  },
  "& .btnMainContainerForgot": {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    width: "320px",
    height: "52px",
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
  },
  "& .submitButtonForgot": {
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    background: "#000",
    width: "100%",
    height: "52px",
    border: "none",
    fontFamily: "Montserrat",
    color: "#ffff",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "normal",
    padding: "10px 16px",
    borderRadius: "8px",
    "&:hover": {
      background: "Black",
    },
  },
  "& .MuiButton-root": {
    textTransform: "initial"
  },
  "& .MuiCheckbox-root": {
    width: 20,
    height: 20,
  },
  "& .MuiIconButton-root": {
    padding: "0 !important"
  },
  "& .forgotFormInputBoxError": {
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    color: "#64748B",
    width: "320px",
    border: "none",
    background: "transparent",
    height:"44px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "sans-serif",
      width: "fit-content",
      borderRadius: "6px",
      color: "#DC2626",
      border: "none",
      fontWeight: 400,
      position: "relative",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .forgotFormInputBox": {
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    color: "#64748B",
    background: "transparent",
    border: "none",
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    width: "320px",
    height:"44px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-root": {
      marginBottom: "10px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        border: "1px solid black",
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
    },
  },
  "& .loginFormFieldContainer": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  },
  "& .loginFormFieldLabel": {
    fontSize: "12px",
    marginBottom: "4px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
  },
  "& .forgot_Main_Container": {
    flexDirection: "column",
    display: "flex",
    gap: "50px"
  },
  "& .signUp_Main_Container": {
    display: "flex",
    padding: "180px 20% 180px ",
    gap: "34px",
    flexDirection: "column",
  },
  "& .image_Container_Box": {
    backgroundRepeat: "no-repeat",
    background: `url(${backgroundImage})`,
    backgroundSize: "100% 100%",
  },
  "& .login_Logo_Container": {
    gap: "33px",
    flexDirection: "column",
    display: "flex",
  },
  "& .loginTextHeader": {
    position: "relative",
  },
  "& .logo": {
    height: "36px",
    width: "138px",
    flexShrink: 0,
  },
  "& .logintextborder": {
    top: "14px",
    width: "99.167px",
    left: "-21px",
    position: "absolute",
    height: "35px",
    "@media (min-width:320px) and (max-width:820px)": {
      top: "5px",
      width: "50px",
      left: "-12px",
      position: "absolute",
      height: "20px",
    },
  },
  "& .loginUpText": {
    fontWeight: 700,
    fontSize: "48px",
    zIndex: 99,
    position: "relative",
    fontStyle: "normal",
    color: "#000",
    "@media (min-width:320px) and (max-width:820px)": {
      fontSize: "22px",
    },
  },
});
// Customizable Area End