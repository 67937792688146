import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import StorageProvider from "../../../framework/src/StorageProvider";
import { removeStorageData, setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { Base64 } from 'base64-string';
export interface ValidResponseType {
  data: {
    attributes: {
      email: string;
    };
  };
  meta: {
    token: string;
    id: number
  };
}

export interface ErrorPayloadType {
  key: string;
  email: string;
  failed_login: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>
}

export interface HasSuccessErrorTypeForLogin {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailError: boolean;
  emailErrorMsg: string;
  blankEmailError: boolean;
  blankPasswordError: boolean;
  passwordError: boolean;
  loginCheckbox: boolean;
  singupAsModal: boolean;
  hasSuccessErrorForLogin: HasSuccessErrorTypeForLogin;
  showPasswordLogin: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  postLoginApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailError: false,
      emailErrorMsg: "",
      blankEmailError: false,
      blankPasswordError: false,
      passwordError: false,
      loginCheckbox:false,
      singupAsModal: false,
      hasSuccessErrorForLogin: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      showPasswordLogin:false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.checkDataFromLocalStorage();
    // Customizable Area End
  }

  // Customizable Area Start

  checkDataFromLocalStorage = async () => {
    const userEmail = await getStorageData("UserEmail")
    const userPassword = await getStorageData("UserPassword")
    const enc = new Base64();
    if (userEmail) {
      const sellerEmail = enc.decode(userEmail);
      this.setState({
        email: sellerEmail
      })
    }
    if (userPassword) {
      const sellerPassword = enc.decode(userPassword);
      this.setState({
        password: sellerPassword
      })
    }
    if (userEmail && userPassword) {
      this.setState({
        loginCheckbox: true
      })
    }
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        return;
      }

      if (this.isValidResponseLogin(responseJson)) {
        this.apiSuccessCallBacksLogin(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseLogin(responseJson)) {
        this.apiFailureCallBacksLogin(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area Start
  async componentWillUnmount() {
    removeStorageData("productDesID");
  }
  
  handleLoginUserEmailInput = (emailEvent: React.ChangeEvent<HTMLInputElement>) => {
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const isValidEmail = emailReg.test(emailEvent.target.value)
    this.setState({
      email: emailEvent.target.value
    })
    if (emailEvent.target.value === "") {
      this.setState({
        emailError: false,
        blankEmailError: true,
        emailErrorMsg: configJSON.loginErrorMsg
      });
    } else if (isValidEmail === false) {
      this.setState({
        emailError: true,
        blankEmailError: false,
        emailErrorMsg: configJSON.loginInvalidErrorMsg
      });
    } else {
      this.setState({
        emailError: false,
        blankEmailError: false,
        emailErrorMsg: ""
      });
    }
  };

  handleClickShowPasswordLogin = () => {
    this.setState({
      showPasswordLogin: !this.state.showPasswordLogin
    })
  };

  handleLoginPasswordInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const isValidPassword = passwordRegex.test(nameEvent.target.value)
    this.setState({
      password: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        passwordError: false,
        blankPasswordError: true,
      });
    } else if (isValidPassword === false) {
      this.setState({
        passwordError: true,
        blankPasswordError: false,
      });
    }
    else {
      this.setState({
        passwordError: false,
        blankPasswordError: false,
      });
    }
  };


  getLoginPasswordErrorMessage() {
    const { blankPasswordError, passwordError } = this.state;

    if (blankPasswordError) {
      return "Please enter valid login credentials.";
    } else if (passwordError) {
      return "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character (!@#$&*?<>',[]}{=-)(^%`~+.:;_).";
    }
    
    return null;
  }

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      loginCheckbox: event.target.checked
    })
    
  } 

  handleNavigateFromLogin = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }
  handleClickOnSingup = () => { 
    this.setState({
      singupAsModal: true
    })
  }

  clickOnUserSelectBtn = (userType: string) => {
    if(userType){
      StorageProvider.set("UserType", 'dealer');
      this.handleNavigateFromLogin("Signup")
    }else {
      StorageProvider.set("UserType", 'customer');
      this.handleNavigateFromLogin("Signup")
    }
  }

  handleCloseModal = () =>{
    this.setState({
      singupAsModal:false
    })
  }
  apiCallLogin = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponseLogin = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.meta;
  };

  isInValidResponseLogin = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacksLogin = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.postLoginApiCallId) {
      this.snackBarErrorApiLogin(responseJson);
    }
  }

  apiSuccessCallBacksLogin = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.postLoginApiCallId) {
      this.setState({
        hasSuccessErrorForLogin: {
          isOpen: true,
          isSeverity: "success",
          isMessage: "Login Succesfully"
        }
      });
      setStorageData("userId", responseJson.meta.id);
      setStorageData("token", responseJson.meta.token);
      let productDesID = await getStorageData("productDesID");
      if (productDesID) {
        this.props.navigation.navigate("ProductDescription", { "id": productDesID })
      } else {
        this.handleNavigateFromLogin("LandingPage");
      }
    }
  }

  snackBarErrorApiLogin = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessErrorForLogin: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.errors[0].failed_login
      }
    });
  };
  
  handleLoginCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForLogin: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };

  handleLogin = async (preventEvent: { preventDefault: () => void; }) => {
    preventEvent.preventDefault();
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const enc = new Base64();
    const encodeUserName = enc.encode(this.state.email);
    const encodePassword = enc.encode(this.state.password);

    const errors = {
      blankEmailError: !this.state.email,
      blankPasswordError: !this.state.password,
      emailError: !emailReg.test(this.state.email),
      passwordError: !passwordRegex.test(this.state.password),
    };
    if (!emailReg.test(this.state.email)) {
      this.setState({
        emailErrorMsg: configJSON.loginInvalidErrorMsg,
      });
    }
    if (!this.state.email) {
      this.setState({
        emailErrorMsg: configJSON.loginErrorMsg,
      });
    }
    if (this.state.loginCheckbox) {
      setStorageData("UserEmail", encodeUserName);
      setStorageData("UserPassword", encodePassword);
    } else {
      removeStorageData("UserEmail");
      removeStorageData("UserPassword");
    }
    
    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      let body = {
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
            remember_me: this.state.loginCheckbox
          }
        }
      }
      this.postLoginApiCallId = await this.apiCallLogin({
        contentType: configJSON.validationApiContentType,
        method: configJSON.loginAPiMethod,
        endPoint: configJSON.postLoginAPIEndPoint,
        body: body
      });
    }
  };
  // Customizable Area End
}
