Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.emailErrorMsg = "Please enter your email id.";
exports.emailInvalidErrorMsg = "Please enter a valid email id.";
exports.getUserProfileEndPoint = "account_block/accounts/";
exports.deleteUserProfilePicEndPoint = "delete_profile_image";
exports.httpPutMethodDelete = "DELETE";
exports.putUserProfileEndPoint = "update_profile";
exports.putUserProfileSettingEndPoint = "update_settings";
exports.getAddressEndPoint = "address";
exports.deleteAddressEndPoint = "address/delete/";
exports.patchIsDefaultAddressEndPoint = "address/update/";
exports.postCreateAddressEndPoint = "address/create";
exports.httpPatchMethod = "PATCH";
// Customizable Area End
