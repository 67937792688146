// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import login from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web"
import TermsAndConditions from "../../blocks/landingpage/src/TermsAndConditions.web"
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Signup from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import CustomisableUserProfilesWeb from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import CustomisableUserProfilesSettings from "../../blocks/customisableuserprofiles/src/CustomisableUserProfilesSettings.web";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web"
import ProfileAddress from "../../blocks/customisableuserprofiles/src/ProfileAddress";
import ProfileAddressWeb from "../../blocks/customisableuserprofiles/src/ProfileAddress.web";
import Cart from "../../blocks/shoppingcart/src/ShoppingCartOrders.web";
import BrandListing from "../../blocks/landingpage/src/BrandListing.web";
import Wishlistweb from "../../blocks/wishlist/src/Wishlistweb.web";
import OrderSummary from "../../blocks/ordermanagement/src/OrderSummary";
import OrderSummaryWeb from "../../blocks/ordermanagement/src/OrderSummary.web";
import AboutUs from "../../blocks/contentmanagement/src/AboutUs.web";
import News from "../../blocks/contentmanagement/src/News.web";

const routeMap = {
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Retail"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
Contactus:{
 component:Contactus,
path:"/Contactus",
exact: true,
},
TermsAndConditions:{
  component:TermsAndConditions,
 path:"/TermsAndConditions",
 exact: true,
 },
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/",
exact: true,
},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
ProductDescription:{
  component:ProductDescription,
 path:"/ProductDescription"},
 BrandListing:{
   component:BrandListing,
 path:"/Spares"},
 AboutUs:{
   component:AboutUs,
 path:"/AboutUs"},
 News:{
  component:News,
path:"/News"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription/:id"},
Signup:{
  component:Signup,
 path:"/Signup",
 exact: true
},
login:{
component:login,
path:"/login",
exact: true
},
ForgotPasswordWeb:{
component:ForgotPasswordWeb,
path:"/ForgotPasswordWeb",
exact: true
},
ResetPassword:{
  component:ResetPassword,
  path:"/ResetPassword",
  exact: true
  },
CustomisableUserProfilesWeb:{
    component:CustomisableUserProfilesWeb,
    path:"/UserProfile",
    exact: true
    },
CustomisableUserProfilesSettings:{
  component:CustomisableUserProfilesSettings,
  path:"/UserProfileSetting",
  exact: true
},
ProfileAddressWeb:{
  component:ProfileAddressWeb,
  path:"/Address",
  exact: true
},
Cart:{
  component:Cart,
  path:"/cart",
  exact: true
},
Wishlist:{
  component:Wishlistweb,
  path:"/wishlist",
  exact: true
},
ProfileAddress:{
  component:ProfileAddress,
  path:"/ProfileAddress",
  exact: true
},
OrderSummary:{
  component:OrderSummary,
  path:"/OrderSummary",
  exact: true
},
Orders:{
  component:OrderSummaryWeb,
  path:"/Orders",
  exact: true
},
  Home: {
    component: HomeScreen,
    path: '/Home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;