import React from "react";
// Customizable Area Start
import {
    TCImage,
} from "./assets";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
// Customizable Area End
import TermsAndConditionsController, { Props } from "./TermsAndConditionsController";
export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  //Customizable Area Start

  renderBody = () => {
    const termsAndConditionsData = this.state.termsAndConditions;

    return (
      <div style={styles.bodyContainer}>
        {termsAndConditionsData.map(item => (
          <div style={styles.termItem}>
            <h3 style={{...styles.termTitle, fontWeight:"lighter"}}>{item.title}</h3>
            <p style={styles.termDescription}
             dangerouslySetInnerHTML={{
              __html: item.description,
            }}
            />
          </div>
        ))}
      </div>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={styles.mainContainer}>
        <div><HeaderNew navigation={this.props.navigation} id={""}/></div>
       <div> <img src={TCImage} style={styles.imageWidth} alt="Image"/></div>
        <div style={styles.bodyContent}>
              {this.renderBody()} 
        </div>
        <div>
          <Footer/>
          </div>
        </div> 
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles = {
  landingPageView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imageWidth:{
    width:"100%"
  },
  landingPageText: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "300",
    fontSize: 24,
    fontFamily: "Mulish",
    marginTop: 13,
    color: "#323441",
  },
  landingPageContainer: {
    overflow: "scroll",
  },

  landingPageButton: {
    justifyContent: "center",
    zIndex: -1,
    backgroundColor: "#E7B846",
    padding: 13,
  },
  mainContainer: {
    justifyContent: "center",
    padding: 2,
    alignItems: "center",
  },

  header: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "10px 20px",
    background: "#fff",
  },
  headerLogo: {
    height: "auto",
  },
  searchContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    marginLeft: "25%",
    height: "4vh",
    marginRight: "25%",
    borderRadius: "20px",
    background: "white",
    overflow: "hidden",
    border: "1px solid #898A8B",
    padding: "5px",
  },
  searchInput: {
    outline: "none",
    border: "none",
    flex: 1,
    padding: "10px",
    fontSize: "14px",
    background: "transparent",
  },
  searchIcon: {
    marginRight: "5px",
    marginLeft: "10px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  loginButton: {
    background: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    marginRight: "10px",
  },
  signUpButton: {
    background: "black",
    border: "none",
    color: "white",
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "5px",
  },
  navigation: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    background: "linear-gradient(to right, #E8B846 10%, #F3E34E 80%)",
    padding: "12px",
    paddingRight: "3vw",
    height: "7vh",
    paddingLeft: "3vw",
  },
  navigations: {
    marginLeft: "3%",
    display: "flex",
    gap: "5%",
    justifyContent: "space-between",
  },
  navItem: {
    cursor: "pointer",
    marginRight: "20px",
    color: "#6A6A6A",
    margin: "2",
    textDecoration: "none",
  },
  icon: {
    height: "20px",
    width: "20px",
    marginRight: "3%",
  },

  logoicon: {
    marginTop: "1%",
    height: "22px",
  },
  bodyContent:{
    padding:"2vh",
    margin:"3%",
  },
  heading: {
    fontSize: "32px",
    marginTop: "8%",
    fontFamily: "Mulish",
  },

  bodyContainer: {
    marginTop: "30px",
  },
  termItem: {
    marginBottom: "20px",
  },
  termTitle: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  termDescription: {
    fontSize: "16px",
  },
 
  //Footer
  footerContainer: {
    width: "100vw",
    height: "25vh",
  },
  leftFooter: {
    display: "flex",
    width: "30%",
  },
  footerLogoBody: {
    display: "flex",
    marginTop: "12",
  },
  footerLogoHeading: {
    fontFamily: "Mulish",
    fontSize: "22",
    color: "#1B2437",
    marginLeft: "2%",
    marginTop: 0,
  },
  footerBody: {
    backgroundColor: "#E9B848",
    display: "flex",
    justifyContent: "space-around",
    height: "40vh",
    padding: "1%",
  },
  rightFooter: {
    display: "flex",
    width: "27rem",
    justifyContent: "space-between",
  },
  footerHeading: {
    color: "#1B2437",
    fontSize: 20,
    fontFamily: "Mulish",
  },
  footerBottom: {
    justifyContent: "center",
    display: "flex",
    paddingTop: "2",
    width: "100vw",
    backgroundColor: "#DCAE42",
    marginTop: "5%",
    marginBottom: "0",
    height: "7vh",
  },
  bottomText: {
    marginTop: "2",
  },
};
// Customizable Area End