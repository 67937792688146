Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.exampleAPiMethod = "POST";
exports.productAPiEndPoint = "bx_block_catalogue/catalogue";
exports.getSearchAPiEndPoint = "bx_block_advanced_search/search";
exports.postAddToWishlistItemApiEndPoint = 'bx_block_categories/add_to_wishlist'
// Customizable Area End