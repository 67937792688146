//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Rating from "@material-ui/lab/Rating";
import { Singuptextborder } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { wishlistEmpty } from "../../wishlist/src/assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import { backImage, linkImage } from "./assets";

const CustomBox = styled(Box)({
  '@media(max-width: 600px)': {
    height: '255px !important',
},
  '& .borderLeft-div': {
    borderLeft: '2px solid #000000'
  },
  '& img': {
    marginLeft: 20, width: '80px', height: '80px', borderRadius: '8px', cursor: 'pointer',
    '@media(max-width: 600px)': {
        marginLeft: '10px !important',
    }
  }
})
const CustomParentBox = styled(Box)({
  "& .ratingMainBox": {
    display: "flex",
    alignItems: "center",
    width: "229px",
    height: "53px",
    gap: "12px",
    border: "0.5px solid #D4D5D9",
    padding:"0 12px",
    marginBottom:"26px",
    cursor: "pointer"
  },
  "& .ratingReviewText":{
    fontFamily: "Mulish",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "67px",
    letterSpacing: "-0.11999999731779099px.",
    textAlign: "left",
  },
  "& .horLine":{
    width: "25px",
    height: "2px",
    background: "#D4D5D9",
    transform: "rotate(-90deg)", 
  },
  "& .ratingznumber":{
    fontFamily: "Mulish",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: "-0.11999999731779099px.",
    textAlign: "left",
  },
  '@media(min-width: 960px) and (max-width: 1199px)': {
    '& .productImageBox': {
      width: '56% !important'
    },
    '& .productDetailBox': {
      width: '44% !important'
    },
  },
  '@media(max-width: 960px)': {
    flexDirection: 'column',
    gap: 50,
    alignItems: 'center',
    '& .productImageBox > div': {
      height: 'unset !important'
    },
    '& .productImageBox': {
      width: '100% !important',
    },
    '@media(max-width: 600px)': {
      '& .productImageBox > div': {
        width: '100% !important',
      },
      '& .productImageBox': {
        width: '100% !important',
      },
      '& .productDetailBox': {
        width: '100% !important'
      },
  },
  },
  '@media(max-width: 600px)': {
    '& .image-second-box': {
      gap: '10px !important'
    },
    '& .big-image': {
      minHeight: '255px !important'
    }
  }
})
const TableBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '48px 0px 118px',
  '& .linkIcon': {
    cursor: "pointer"
  },
  '& > div': {
    maxWidth: '1186px',
    width: '100%',
  },
  '& > div > div': {
    maxWidth: '1186px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: 68,
    borderBottom: '1px solid #E2E8F0',
  },
  '& > div > div > div': {
    flex: 1
  },
  '& .borderBottomDiv': {
    display: 'inline-flex',
    height: '68px',
    alignItems: 'center',
    width: '200px',
    borderBottom: '2px solid #E8B846',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
    cursor: "pointer",
  },
  '& .tableText': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20.08px',
    cursor: "pointer"
  },
  '& .tableProductHeading':{
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    letterSpacing: '-0.11999999731779099px',
    textAlign: 'left'
  },
  '& .tableDescription':{
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    color:"#7F7F7F"
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEmptyFeedbackData = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} className="emptyMainGrid" data-test-id="noDataBoxTestID">
          <Box className="emptyMainBox">
            <img src={wishlistEmpty} alt="" className="wishlistEmpty" />
            <Typography className="feedbackItemHeadding">
              Not data found!
            </Typography>
          </Box>
        </Grid>
      </>
    )
  }

  renderFeedbakModal = () => {
    return (
      <DilogBoxMain
        aria-labelledby="customized-dialog-title"
        open={this.state.isFeedbackOpen}
        data-test-id="dialogBoxTestID"
        className={"signUp_ModelContainer"}
      >
        <Box className="dilogBoxMain">
          <img src={Singuptextborder} alt="Singuptextborder" className={"upborderimg"} />
          <img src={Singuptextborder} alt="Singuptextborder" className={"downborderimg"} />
          <Box className="headingHead">
            <Typography data-test-id="feedbackBoxTestID" className="ordersFeedbackHeadding">Rating</Typography>
            <CloseIcon
              className="cancelIcon"
              onClick={this.handleCloseFeedbackModal}
              data-test-id="crossIconTestID"
            />
          </Box>
          <Box className="downInnerBox">
            <Box className="ratingMainBoxDilog">
              <Box className="ratingMainBoxDilogRating">
                <Typography className="ratingznumberDilog">
                  {this.state.feedbackItem.average_rating}
                </Typography>
                <Rating
                  value={
                    1
                  }
                  name="size-large" defaultValue={2} size="large"
                  max={1}
                  className="ratingStarDilog"
                />
              </Box>
              <Typography className="ratingReviewTextDilog">
                Customer Reviews({this.state.feedbackItem.review_count})
              </Typography>
              {this.state.feedbackItem.data.length > 0 ?
                this.state.feedbackItem.data.map((item, index) => {
                  return (
                    <>
                      <Box className="mainratingArea">
                        <Box className="ratingMainBoxDilogRating mainShowArea">
                          <Typography className="ratingznumberDilog showareaRating">
                            {item.attributes.rating}
                          </Typography>
                          <Rating
                            value={
                              1
                            }
                            name="size-large" defaultValue={2} size="large"
                            max={1}
                            className="showareaRatingStar"
                          />
                        </Box>
                        <Box className="innerRatingsecond">
                          <Typography className="DescriptionAreaText">
                            {item.attributes.comment.length > 125 && !item.isReadMore ? (
                              <div>
                                {item.attributes.comment.slice(0, 125)}
                                <button
                                data-test-id={`readMoreTestID${index}` }
                                className="readmoreBtn" onClick={() => this.toggleExpand(item.id)}>Read More</button>
                              </div>
                            ) : (
                              <div>
                                {item.attributes.comment}
                                {item.isReadMore && 
                                <button 
                                data-test-id={`readMoreTestID${index}` }
                                className="readmoreBtn" onClick={() => this.toggleExpand(item.id)}>Read Less</button>}
                              </div>
                            )}
                          </Typography>
                          <Typography className="DescriptionAreaText nameDescriptionArea">
                            {item.attributes.customer_name}  |   {this.formatDate(item.attributes.created_at)}
                          </Typography>
                        </Box>
                      </Box>
                      <hr
                        className={
                          index !== this.state.feedbackItem.data.length - 1
                            ? "hrLine"
                            : ""
                        }
                      />
                    </>
                  )
                })
                : this.renderEmptyFeedbackData()}
            </Box>
          </Box>
        </Box>
      </DilogBoxMain>
    )
  }

  renderCompleteRange = () => {
    return (
      <>
        <Box>
          <Box data-test-id="suitableTestID" className="tableText">Suitable for</Box>
          <Box className="tableText">For machine model</Box>
        </Box>
        {this.state.productData.complete_range.length > 0 ?
          this.state.productData.complete_range.map((element, index) => {
            return (
              <Box>
                <Box style={{ display: "flex", gap: "16px", alignItems: "center" }} className="tableProductHeading">
                  <img data-test-id={`linkTestID${index}` } className="linkIcon" src={linkImage} alt="link Image" onClick={() => this.reRenderPrdoctDescription(element.id)} />
                  <img src={element.images[0].url} alt="product image" width="32px" height="32px" />
                  <Typography className="tableProductHeading">{element.name}</Typography>
                </Box>
                <Box className="tableDescription">{element.description}</Box>
              </Box>
            )
          })
          :
          this.renderEmptyBlock()
        }
      </>
    )
  }

  renderSustainableRange = () => {
    return (
      <>
        <Box>
          <Box data-test-id="suitableTestID" className="tableText">Suitable for</Box>
          <Box className="tableText">For machine model</Box>
        </Box>
        {this.state.productData.suitable_products.length > 0 ?
          this.state.productData.suitable_products.map((element, index) => {
            return (
              <Box>
                <Box style={{ display: "flex", gap: "16px", alignItems: "center" }} className="tableProductHeading">
                  <img className="linkIcon" src={linkImage} alt="link Image" data-test-id={`linkTestID${index}` } onClick={() => this.reRenderPrdoctDescription(element.suitable_id)} />
                  <img src={element.images[0].url} alt="product image" width="32px" height="32px" />
                  <Typography className="tableProductHeading">{element.suitable_item_name}</Typography>
                </Box>
                <Box className="tableDescription">{element.suitable_item_description}</Box>
              </Box>
            )
          })
          :
          this.renderEmptyBlock()
        }
      </>
    )
  }

  renderEmptyBlock = () => {
    return (
      <MainBrandEmptyContainer data-test-id="noDataBoxTestID">
        <Typography className="fontNOdata">No items available!</Typography>
      </MainBrandEmptyContainer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.productData.name ?
        <div style={webStyle.mainContainer} data-test-id="innerTestId">
          <div><HeaderNew navigation={this.props.navigation} id={""}/></div>
          <Box style={{display:"flex",gap:"8px",margin:"32px 0px 22px 40px"}}>
            <img src={backImage} data-test-id="TestGoBack" alt="" onClick={this.goBack} />
            <Typography data-test-id="nameTestID" style={{
              weight: 400,
              size: '14px',
              lineHeight: '24px',
              paragraph: '8px',
              color:"#808080"
            }}>Back to Home / {this.state.productData.name} / <span style={{color:"#000000"}}>{this.state.productData.part_number}</span></Typography>
          </Box>
        <CustomParentBox style={{display:"flex",width:"100%", maxWidth: '1186px', margin: 'auto'}}>
          <Box className="productImageBox" style={{height:"100%",width:"50%"}}>
            <Box className="image-second-box" style={{display: 'flex', gap: 20, height: '583px', overflow: 'hidden', justifyContent: 'center'}}>
              <CustomBox style={{display: 'flex', flexDirection: 'column', gap: 8,height:"432px",overflow:"auto", minWidth: 102}}>
                {this.state.productData.images.map((element)=>{
                
                  return (
                    <div data-test-id="prodImg" onClick={() => this.handleClick(element.url)} className={this.state.activeDiv[element.url] ? 'borderLeft-div' : ''}>
                    <img src={element.url} alt="" />
                    </div>
                  )
                })}
              </CustomBox>
              <Box style={{display: 'flex', flexDirection: 'column', gap: 50}}>
                <img className="big-image" style={{maxWidth: '404px', width: '100%', minHeight: '432px', borderRadius: '10px'}} src={this.state.activeDiv=={}?this.state.productData.images[0].url:Object.keys(this.state.activeDiv)[0]} alt="" />
              </Box>
            </Box>
          </Box>
          <Box className="productDetailBox" style={{height:"100%",width:"50%",marginTop:"-34px"}}>
            <Box style={{display:"flex",flexDirection:"column",gap:"32px",width:"100%",maxWidth:"401px",margin:"auto"}}>
            <Typography style={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '72px',
              letterSpacing: '-0.11999999731779099px',
              textAlign: 'left'
            }}>{this.state.productData.name}</Typography>
                  <Box
                  data-test-id="feedbackItemTestID"
                  className="ratingMainBox" onClick={this.handleOpenFeedbackModal}>
                    <Typography className="ratingznumber" data-test-id="feedbackItemRatingTestID">
                    {this.state.feedbackItem.average_rating}
                    </Typography>
                    <Rating
                      value={
                        1
                      }
                      name="size-large" defaultValue={2} size="large"
                      max={1}
                      className="ratingStar"
                    />
                    <Box className="horLine"></Box>
                    <Typography className="ratingReviewText">
                      {this.state.feedbackItem.review_count} Reviews
                    </Typography>
                  </Box>
            <Typography style={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '23px',
              letterSpacing: '0px',
              textAlign: 'left',
              marginTop:"-32px"
            }}>PART NUMBER: {this.state.productData.part_number&&this.state.productData.part_number.slice(this.state.productData.part_number.lastIndexOf('-') + 1).replace(/\D/g, '').padStart(7, '0')}</Typography>
            <Box style={{
              width: '100%',
              height: '168px',
              top: '369px',
              left: '895px',
              gap: '32px'
            }}>
              <Box style={{
                display:"flex",
                width: '100%',
                // height: '56px',
                padding: '16px 8px 16px 0px',
                border: '1px solid rgba(226, 232, 240, 1)',
                borderRight:0,
                borderLeft:0,
                justifyContent: 'space-between',
              }}>
                <Typography>Unit Price</Typography>
                <Typography>₹ {this.state.productData.discount_price==null?this.state.productData.price:this.state.productData.discount_price}</Typography>
              </Box>
              <Box style={{
                display:"flex",
                width: '100%',
                padding: '16px 8px 16px 0px',
                border: '1px solid rgba(226, 232, 240, 1)',
                borderRight:0,
                borderLeft:0,
                justifyContent: 'space-between',
              }}>
                <Typography>Available Qty.</Typography>
                <Typography>{this.state.productData.stock_qty}</Typography>
              </Box>
            </Box>
            <Box style={{
              display:"flex",
              marginTop:"15px",
              width: '100%',
              height: '52px',
              gap: '24px'
            }}> <Button 
            data-test-id="raiseRequestTestID"
            style={{
              width: '100%',
              height: '100%',
              padding: '10 16 10 16',
              gap: '8px',
              borderRadius: 8,
              background:"rgba(0, 0, 0, 1)",
              color:"white",
              textTransform:"inherit"
            }}
            onClick={()=>this.addToCart()}
            >Raise Request</Button>  
            <Button 
              data-test-id="wistlistTestID"
            style={{
              width: '100%',
              height: '100%',
              padding: '10 16 10 16',
              gap: '8px',
              borderRadius: 8,
              background:"rgba(232, 184, 70, 1)",
              textTransform:"inherit"
            }}
            onClick={()=>this.addToWishlistProd()}
            >Add to Wishlist</Button>  
            </Box>       
          </Box>
          </Box>
          {this.renderFeedbakModal()}
        </CustomParentBox>
            <TableBox>
              <Box>
                <Box>
                  <Box onClick={this.completeRangeTab} className={!this.state.completeRangeTab ? "borderBottomDiv" : "tableText"}>
                    Complete Range
                  </Box>
                  <Box data-test-id="suitableBoxTestID" onClick={this.completeRangeTab} className={this.state.completeRangeTab ? "borderBottomDiv" : "tableText"}>Items suitable for and Machine models</Box>
                </Box>
                {this.state.completeRangeTab ?
                  this.renderSustainableRange()
                  :
                  this.renderCompleteRange()
                }
              </Box>
            </TableBox>
          <div><Footer/></div>
        </div>
      : 
      <CircularProgressBox >
      <CircularProgress className="loader" />
    </CircularProgressBox>
      }
        <SnackBarAlert
          data-test-id="snackBarTestID"
          snackBarProps={this.state.hasSuccessErrorForProDesc}
          handleCloseSnack={this.handleProDescCloseSnackbar}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBrandEmptyContainer = styled(Box)({
  boxShadow: "0px 0px 8px -5px rgb(0, 0, 0)",
  borderRadius: "20px",
  justifyContent: "center",
  padding: "20px 15px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
  width:"100%",
  "@media (min-width:320px) and (max-width:575px)": {
    margin: "0 0px 0 0",
  },
  "& .fontNOdata": {
    fontSize: "18px"
  }
});

const DilogBoxMain = styled(Dialog)({
  width: "100%",
  height: "100%",
  "& .emptyMainGrid": {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  },
  "& .emptyMainBox": {
    width: "100%",
  },
  "& .wishlistEmpty": {
    height: "200px",
    width: "200px",
    paddingBottom: "20px",
  },
  "& .feedbackItemHeadding": {
    color: "#000",
    fontSize: "24px",
    zIndex: 99,
    position: "relative",
    fontStyle: "normal",
    fontWeight: "bold",
    paddingBottom: "20px",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
  "& .hrLine": {
    width: "100%",
    color: "#C1C7D1",
  },
  "& .readmoreBtn": {
    background: "none",
    border: "none",
    color: "rgb(232, 184, 70)",
    borderBottom: "1px solid rgb(232, 184, 70)",
    padding: "0",
    margin: "0 0 0px 5px",
    cursor: "pointer",
  },
  "& .nameDescriptionArea": {
    fontSize: "12px !important",
  },
  "& .DescriptionAreaText":{
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    textAlign: "left",
    letterSpacing: "-0.11999999731779099px.",
    color:"#000000"
  },
  "& .mainratingArea": {
    display: "flex",
    gap: "20px"
  },
  "& .innerRatingsecond": {
    display: "flex",
    flexDirection:"column",
    gap: "12px"
  },
  "& .ratingMainBoxDilogRating": {
    alignItems: "center",
    display: "flex",
    height: "53px",
    width: "229px",
    gap: "12px",
  },
  "& .mainShowArea": {
    border: "0.5px solid #D4D5D9",
    padding:"0 6px",
    width: "unset !important",
    height: "24px",
    gap: "5px",
  },
  "& .ratingMainBoxDilog": {
    display: "flex",
    flexDirection:"column",
    gap: "12px",
  },
  "& .ratingStarDilog": {
    fontSize: "40px",
  },
  "& .showareaRatingStar": {
    fontSize: "22px !important",
  },
  "& .ratingReviewTextDilog":{
    fontSize: "18px",
    fontFamily: "Mulish",
    lineHeight: "67px",
    fontWeight: 600,
    textAlign: "left",
    letterSpacing: "-0.11999999731779099px.",
  },
  "& .ratingznumberDilog":{
    fontSize: "48px",
    fontFamily: "Mulish",
    lineHeight: "72px",
    fontWeight: 600,
    textAlign: "left",
    letterSpacing: "-0.11999999731779099px.",
  },
  "& .showareaRating":{
    fontSize: "16px !important",
    fontWeight: 500,
  },
  "& .MuiDialog-paperWidthSm": {
    maxHeight: "100% !important",
    maxWidth: "100% !important",
  },
  "& .MuiDialog-paper": {
    overflowY: "unset"
  },
  " .signUp_ModelContainer": {
    position: "relative",
    zIndex: 1,
  },
  "& .upborderimg": {
    "height": "37px",
    "width": "119px",
    left: "-66px",
    position: "absolute",
    top: "-5px",
    "@media(min-width:320px) and (max-width:768px)": {
      display: "none"
    }
  },
  "& .downborderimg": {
    position: "absolute",
    "width": "119px",
    "height": "37px",
    transform: "rotate(90deg)",
    bottom: "-15px",
    right: "-52px",
    zIndex: 10,
    "@media(min-width:320px) and (max-width:768px)": {
      display: "none"
    }
  },
  "& .MuiCheckbox-root": {
    width: 20,
    color: "#00000042",
    height: 20,
  },
  "& .MuiIconButton-root": {
    padding: "0 !important",
  },
  "& .dilogBoxMain": {
    width: "fit-content",
    height: "566px",
    overflowY: "scroll",
  },
  "& .cancelIcon": {
    cursor: "pointer",
  },
  "& .downInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    padding: "0px 32px",
    width: "606px",
    "@media (min-width:320px) and (max-width:790px)": {
      width: "fit-content"
    },
  },
  "& .headingHead": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 12px 36px 78px"
  },
  "& .ordersFeedbackHeadding": {
    position: "relative",
    fontSize: "32px",
    color: "#0F172A",
    fontStyle: "normal",
    zIndex: 99,
    fontWeight: 600,
    "@media (min-width:320px) and (max-width:800px)": {
      marginBottom: "12px",
      fontSize: "24px !important",
    },
  },
});
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
};
const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});
// Customizable Area End
