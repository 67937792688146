import React, { Component } from 'react'
// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  createStyles,
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  heading: string;
  description: string;
  classes: Record<string, string>;
  showBtn: boolean;
  image: string;
  btnText?: string;
  loginBtnClick: () => void | undefined;
  // Customizable Area End
}

export class SignUpSuccessModel extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const style = this.props.classes;
    return (
      <>
        <Dialog
          open={true}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={style.signUp_ModelContainer}
        >
          <DialogContent className={style.signUp_Model_MainContainer}>
            <img src={require("./Singuptextborder.png")} alt="Singuptextborder" className={style.upborderimg} />
            <img src={require("./Singuptextborder.png")} alt="Singuptextborder" className={style.downborderimg} />
            <img src={this.props.image} alt="" className={this.props.heading === "Password Changed Successfully!" || "Your Profile have been saved" || "Settings has been saved" || "Your message has been successfully sent!" ? style.imageStyleReset : style.imageStyle} />
            <Typography className={style.messageText} >{this.props.heading}</Typography>
            {this.props.heading === "Password Changed Successfully!" ?
            <>
              <Typography className={style.modalContentText} >
                You have successfully confirmed your new password.
                <p className='downtext'>Please, use your new password when logging in.</p>
              </Typography>
            </>
              :
              <Typography className={style.modalContentText}
              dangerouslySetInnerHTML={{
                __html: this.props.description,
              }}
              >
              </Typography>}

            {this.props.showBtn &&
              <Button onClick={this.props.loginBtnClick} className={style.loginButton} type="submit">{this.props.btnText ? this.props.btnText : "Go to Login"}</Button>
            }
          </DialogContent>
        </Dialog>
      </>
    )
  }
  // Customizable Area End
}
// Customizable Area Start
const Styles = () =>
  createStyles({
    upborderimg: {
      "width": "119px",
      "height": "37px",
      position: "absolute",
      top: "-15px",
      left: "-50px",
      "@media(min-width:320px) and (max-width:930px)": {
        display: "none"
      }
    },
    downborderimg: {
      "width": "119px",
      "height": "37px",
      position: "absolute",
      bottom: "-15px",
      right: "-60px",
      transform: "rotate(90deg)",
      "@media(min-width:320px) and (max-width:930px)": {
        display: "none"
      }
    },
    loginButton: {
      width: "322px",
      height: "52px",
      background: "#000",
      border: "none",
      color: "#ffff",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "8px",
      padding: "10px 16px",
      textTransform: "initial",
      "&:hover": {
        background: "Black",
      },
      "@media(min-width:320px) and (max-width:575px)": {
        width: "100%",
        fontSize: "12px",
      }
    },
    signUp_Model_MainContainer: {
      display: "flex",
      width: "841px",
      height: "489px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      flexShrink: 0,
      "@media (min-width:768px) and (max-width:1199px)": {
        width: "unset",
        height: "unset",
      },
      "@media (min-width:576px) and (max-width:768px)": {
        width: "unset",
        height: "unset",
      },
      "@media(min-width:320px) and (max-width:575px)": {
        width: "unset",
        height: "unset",
        padding: " 20px !important",
      }
    },
    imageStyle: {
      width: "117px",
      height: "120px",
      "@media(min-width:320px) and (max-width:575px)": {
        width: "100px",
        height: "100px",
      }
    },
    imageStyleReset: {
      width: "133px",
      height: "120px",
      "@media(min-width:320px) and (max-width:575px)": {
        width: "100px",
        height: "100px",
      }
    },
    messageText: {
      color: "#000",
      textAlign: "center",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      "@media(min-width:320px) and (max-width:768px)": {
        fontSize: "20px",
      }
    },
    modalContentText: {
      color: "#000",
      textAlign: "center",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      "@media(min-width:320px) and (max-width:768px)": {
        fontSize: "12px",
      },
      "& .downtext":{
        margin: "5px 0 0 0"
      }
    },
    signUp_ModelContainer: {
      position: "relative",
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100% !important",
        maxHeight: "100% !important"
      },
      "& .MuiDialog-paper": {
        overflowY: "unset"
      },
      "& .MuiDialog-container ": {
        backdropFilter: "blur(6px) !important"
      }
    }
  });
export default withStyles(Styles)(SignUpSuccessModel);
// Customizable Area End