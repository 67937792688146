import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
const navigation = require("react-navigation");

export interface ValidResponseType {
  data: Address[];
  message: string;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

interface Address {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    flat_no: string | null;
    address: string;
    address_type: string;
    address_line_2: string | null;
    zip_code: string;
    phone_number: string;
    latitude: number | null;
    longitude: number | null;
    address_for: string;
    is_default: boolean;
    city: string;
    state: string;
    country: string;
    landmark: string;
    created_at: string;
    updated_at: string;
  };
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isOrderDetails?: boolean;
  navigation: typeof navigation;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressData: Address[];
  nameAddress: string;
  isErrorName: boolean;
  contactNumberAddress: string;
  contactNumberAddressError: boolean;
  contactNumberAddressBlankError: boolean;
  pincodeAddress: string;
  pincodeAddressError: boolean;
  pincodeAddressBlankError: boolean;
  stateAddress: string;
  isErrorState: boolean;
  addressAddress: string;
  isErrorAddress: boolean;
  localityAddress: string;
  isErrorLocality: boolean;
  cityAddress: string;
  isErrorCity: boolean;
  countryAddress: string;
  isErrorCountry: boolean;
  defaultCheck: boolean;
  newAddressModal: boolean;
  editAddress: boolean;
  addressId: number;
  hasSuccessErrorForAddress: HasSuccessErrorType;
  isFormSubmit: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAddressDataApiCallId: string = "";
  deleteAddressDataApiCallId: string = "";
  patchIsDefaultAddressApiCallId: string = "";
  postCreateAddressDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addressData: [],
      nameAddress: "",
      isErrorName: false,
      contactNumberAddress: "",
      contactNumberAddressError: false,
      contactNumberAddressBlankError: false,
      pincodeAddress: "",
      pincodeAddressError: false,
      pincodeAddressBlankError: false,
      stateAddress: "",
      isErrorState: false,
      addressAddress: "",
      isErrorAddress: false,
      localityAddress: "",
      isErrorLocality: false,
      cityAddress: "",
      isErrorCity: false,
      countryAddress: "",
      isErrorCountry: false,
      defaultCheck: false,
      newAddressModal: false,
      editAddress: false,
      addressId: 0,
      hasSuccessErrorForAddress: {
        isOpen: false,
        isSeverity: "error",
        isMessage: "",
      },
      isFormSubmit: false,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseAddress(responseJson)) {
        this.apiSuccessCallBacksAddress(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseAddress(responseJson)) {
        this.apiFailureCallBacksAddress(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getAddressData();
  }

  apiCallProfileAddress = async (valueData: {
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
    contentType?: string;
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    let token = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponseAddress = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || responseJson.message;
  };

  isInValidResponseAddress = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksAddress = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.getAddressDataApiCallId) {
      this.setState({
        addressData: responseJson.data,
      });
    }
    if (apiRequestCallId === this.deleteAddressDataApiCallId) {
      this.snackBarShowAddress(true, "success", responseJson.message);
      this.getAddressData();
    }
    if (apiRequestCallId === this.patchIsDefaultAddressApiCallId) {
      this.snackBarShowAddress(
        true,
        "success",
        "Address default set successfully"
      );
      this.getAddressData();
    }
    if (apiRequestCallId === this.postCreateAddressDataApiCallId) {
      if (this.state.editAddress){
        this.snackBarShowAddress(
          true,
          "success",
          "Address updated successfully"
        );
      }else {
        this.snackBarShowAddress(
          true,
          "success",
          "New address created successfully"
        );
      }
      this.getAddressData();
      this.setState({
        newAddressModal: false,
      },()=>{
        this.setState({
          isFormSubmit: false,
          isLoading: false,
        });
      });
    }
  };

  apiFailureCallBacksAddress = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType
  ) => {
    this.setState({
      isLoading: false,
    });
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.handleNavigateFromAddress("login");
    }
  };

  handleNavigateFromAddress = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  getAddressData = async () => {
    this.getAddressDataApiCallId = await this.apiCallProfileAddress({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAddressEndPoint,
      type: "",
    });
  };

  handleDeleteIcon = async (removeId: number) => {
    this.deleteAddressDataApiCallId = await this.apiCallProfileAddress({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPutMethodDelete,
      endPoint: configJSON.deleteAddressEndPoint + removeId,
      type: "",
    });
  };

  handleRadionChange = async (defaultId: number) => {
    let body = {
      address: {
        is_default: true,
      },
    };

    this.patchIsDefaultAddressApiCallId = await this.apiCallProfileAddress({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPatchMethod,
      endPoint: configJSON.patchIsDefaultAddressEndPoint + defaultId,
      type: "",
      body: body,
    });
  };

  handleSubmitChanges = async (preventEvent: {
    preventDefault: () => void;
  }) => {
    preventEvent.preventDefault();
    const {
      nameAddress,
      contactNumberAddress,
      pincodeAddress,
      stateAddress,
      addressAddress,
      localityAddress,
      cityAddress,
      countryAddress,
    } = this.state;
    const errors = {
      isErrorName: !nameAddress,
      contactNumberAddressBlankError: !contactNumberAddress,
      pincodeAddressBlankError: !pincodeAddress,
      isErrorState: !stateAddress,
      isErrorAddress: !addressAddress,
      isErrorLocality: !localityAddress,
      isErrorCity: !cityAddress,
      isErrorCountry: !countryAddress,
      contactNumberAddressError: contactNumberAddress.length !== 10,
      pincodeAddressError: pincodeAddress.length !== 6,
    };
    this.setState(errors);
    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors && !this.state.isFormSubmit) {
      this.setState({
        isFormSubmit: true,
        isLoading: true,
        newAddressModal: false,
      })
      let body = {
        address: {
          name: nameAddress,
          phone_number: contactNumberAddress,
          address: addressAddress,
          zip_code: pincodeAddress,
          state: stateAddress,
          city: cityAddress,
          landmark: localityAddress,
          country: countryAddress,
          is_default: this.state.defaultCheck,
        },
      };

      this.postCreateAddressDataApiCallId = await this.apiCallProfileAddress({
        contentType: configJSON.searchApiContentType,
        method: this.state.editAddress ? configJSON.httpPatchMethod :  configJSON.httpPostMethod,
        endPoint: this.state.editAddress ? configJSON.patchIsDefaultAddressEndPoint + this.state.addressId : configJSON.postCreateAddressEndPoint,
        type: "",
        body: body,
      });
    }
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updates = {};

    if (name === "name") {
      updates = {
        nameAddress: value,
        isErrorName: value === "",
      };
    } else if (name === "state") {
      updates = {
        stateAddress: value,
        isErrorState: value === "",
      };
    } else if (name === "address") {
      updates = {
        addressAddress: value,
        isErrorAddress: value === "",
      };
    } else if (name === "locality") {
      updates = {
        localityAddress: value,
        isErrorLocality: value === "",
      };
    } else if (name === "city") {
      updates = {
        cityAddress: value,
        isErrorCity: value === "",
      };
    } else if (name === "country") {
      updates = {
        countryAddress: value,
        isErrorCountry: value === "",
      };
    }
    this.setState(updates);
  };

  getErrorMessage = (fieldName: string) => {
    const {
      contactNumberAddressBlankError,
      contactNumberAddressError,
      pincodeAddressBlankError,
      pincodeAddressError,
    } = this.state;

    if (fieldName === "contactNumber") {
      if (contactNumberAddressBlankError) {
        return "Please enter your contact number.";
      } else if (contactNumberAddressError) {
        return "Please enter a valid contact number.";
      }
    } else if (fieldName === "pincode") {
      if (pincodeAddressBlankError) {
        return "Please enter your pincode.";
      } else if (pincodeAddressError) {
        return "Please enter a valid pincode.";
      }
    }

    return null;
  };

  handleNumberInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = nameEvent.target;
    let updates = {};
    if (name === "contactNumber") {
      const phoneNumberRegex = /^\d{0,10}$/;
      // Handle contact number input
      updates = {
        contactNumberAddress: value,
        contactNumberAddressError:
          !phoneNumberRegex.test(value) && value !== "",
        contactNumberAddressBlankError: value === "",
      };
    } else if (name === "pincode") {
      // Handle pincode input
      const pincodeRegex = /^\d{0,6}$/;
      updates = {
        pincodeAddress: value,
        pincodeAddressError: !pincodeRegex.test(value) && value !== "",
        pincodeAddressBlankError: value === "",
      };
    }

    this.setState(updates);
  };

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      defaultCheck: event.target.checked,
    });
  };

  handleClickNewAddress = () => {
    this.setState({
      newAddressModal: !this.state.newAddressModal,
      nameAddress: "",
      isErrorName: false,
      contactNumberAddress: "",
      contactNumberAddressError: false,
      contactNumberAddressBlankError: false,
      pincodeAddress: "",
      pincodeAddressError: false,
      pincodeAddressBlankError: false,
      stateAddress: "",
      isErrorState: false,
      addressAddress: "",
      isErrorAddress: false,
      localityAddress: "",
      isErrorLocality: false,
      cityAddress: "",
      isErrorCity: false,
      countryAddress: "",
      isErrorCountry: false,
      defaultCheck: false,
    });
  };

  handleClickEditAddress = (addressItem: Address) => {
    let { name, phone_number, address, zip_code, state, landmark, city, country, is_default, id } = addressItem.attributes

    this.setState({
      newAddressModal: true,
      editAddress: true,
      addressId: id,
      nameAddress: name,
      contactNumberAddress: phone_number,
      pincodeAddress: zip_code,
      stateAddress: state,
      addressAddress: address,
      localityAddress: landmark,
      cityAddress: city,
      countryAddress: country,
      defaultCheck: is_default,
    });
  };

  handleAddressCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForAddress: {
        isSeverity: "error",
        isOpen: false,
        isMessage: "",
      },
    });
  };

  snackBarShowAddress = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForAddress: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };
  // Customizable Area End
}