import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  
  // Customizable Area End
}
  // Customizable Area Start
  
interface Brand {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        image: string;
    };
}

interface BrandData {
    data: Brand;
}
export interface ErrorPayloadType {
  key: string;
  token: string;
}
interface BrandResponse {
    [Keys : string]: BrandData[];
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

interface SearchParams {
  name: string;
  part: string;
  description: string;
  manufacturer: string;
  sector: string;
  family: string;
  codetype: string;
  sorting: string;
}
export interface InvalidResponseTypePost {
  error: string;
}


interface SearchParamsCat{
  category_name: {id : number, name: string};
  subcategory_name: {id : number, name: string};
  manufacturer_name: {id : number, name: string};
  family_name: {id : number, name: string};
  sector_name: {id : number, name: string};
};

export interface ValidResponseType {
  data: Category[];
  meta: {
    message: string;
  };
  message: string;
  count: number
  catalogue_id: number
}
interface SelectedSubCatType {
  [key: string]: string;

}


interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Category {
  id: string;
  type: string;
  attributes: {
    part_number: string;
    description: string;
    discount_price: null;
    price: string | number;
    images: { url: string }[];
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategory[];
    category_name: string;
    subcategory_name: string;
    manufacturer_name: string;
    family_name: string;
  };
}
export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

  // Customizable Area End

interface S {
  // Customizable Area Start
  arrayHolder: any;
  userProfileHover: boolean;
  logout: boolean;
  token: string;
  letters: string[];
  brandsListData:BrandResponse;
  selectedLetter:string;
  allLetters:string[];
  isToggle:boolean;
  isSearchBox: boolean;
  searchParams: SearchParams;
  selectedSubCat: SelectedSubCatType;
  isLoading: boolean;
  isParamsupdated: boolean;
  brandId: number;
  pageNo: number;
  grid4: boolean;
  apiSelectedSubCat: SearchParamsCat;
  searchData: Category[];
  productCount: number;
  arrayHolder1: Category[];
  isNoDataSearch: boolean;
  isNoDataCat: boolean;
  hasSuccessErrorForCat: HasSuccessErrorType;
  // Customizable Area End
}
interface SS {
  id: string|number;
  // Customizable Area Start
  // Customizable Area End
}
export default class BrandListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   getSeachApiCallId: string = "";
   postAddToWishlistItemApiCallId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      , getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      userProfileHover: false,
      logout: false,
      token: "",
      letters:[],
      brandsListData:{},
      selectedLetter:"",
      allLetters:[],
      isToggle: false,
      isSearchBox: false,
      searchParams: {
        name: "",
        part: "",
        description: "",
        manufacturer: "",
        sector: "",
        family: "",
        codetype: "",
        sorting: ""
      },
      selectedSubCat:{},
      isLoading:false,
      isParamsupdated: false,
      brandId:0,
      pageNo: 1,
      grid4: true,
      apiSelectedSubCat:{
        category_name: {
          id: 0,
          name: ""
        },
        subcategory_name: {
          id: 0,
          name: ""
        },
        manufacturer_name: {
          id: 0,
          name: ""
        },
        family_name: {
          id: 0,
          name: ""
        },
        sector_name: {
          id: 0,
          name: ""
        }
      },
      searchData: [],
      arrayHolder1: [],
      productCount: 0,
      hasSuccessErrorForCat: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      isNoDataSearch: false,
      isNoDataCat: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson =
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) 
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.brandListingId) {
        this.setState({brandsListData:responseJson,letters:Object.keys(responseJson),allLetters:Object.keys(responseJson)})
        
      }
      if (this.isValidResponseSearch(responseJson)) {
        this.apiSuccessCallBacksSearch(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseSearch(responseJson)) {
        this.apiFailureCallBacksSearch(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    removeStorageData("brandId");
  }

  getProductApiCallId: any;
  getProductApiCallId1: string = "";
  brandListingId: string = "";

  handleBrandListingAPI = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    this.brandListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getBrandEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  async componentDidMount() {
   this.handleBrandListingAPI()
    const token = await getStorageData("token");
    this.setState({token:token})

    const brandId = await getStorageData("brandId");
    this.setState({brandId:brandId},()=>{
      this.getCatagList();
    })
  }
  selectLetter=(letter:string)=>{
    this.setState({selectedLetter:letter,allLetters:[letter]})
  }
  
  decreasePageNumber = () => {
    this.setState((prevState) => {
      if (prevState.pageNo > 1) {
        return { pageNo: prevState.pageNo - 1 };
      }
      return null;
    }, () => {
      if (this.state.pageNo > 0) {
        if (this.state.isSearchBox) {
          this.getCatgSearchList(this.state.searchParams);
        } else {
          this.getCatagList();        }
      }
    });
  }

  handleClear = () => {
    let apiSelectedSubCat = {
      manufacturer_name: {
        id: 0,
        name: ""
      },
      category_name: {
        id: 0,
        name: ""
      },
      subcategory_name: {
        id: 0,
        name: ""
      },
      family_name: {
        id: 0,
        name: ""
      },
      sector_name: {
        id: 0,
        name: ""
      }
    }
    this.setState({ selectedSubCat: {}, apiSelectedSubCat: apiSelectedSubCat, isParamsupdated: true }, () => {
      this.getCatagList();
    })
  }

  isInValidResponseSearch = (responseJson: InvalidResponseType & InvalidResponseTypePost) => {
    return responseJson && (responseJson.errors || responseJson.error);
  };

  increasePageNumber = () => {
    const productCount = this.state.productCount;
    const productsPerPage = 16;
    const totalPages = Math.ceil(productCount / productsPerPage);
    this.setState((prevState) => {
      if (prevState.pageNo === totalPages) {
        return { pageNo: totalPages };
      }
      return { pageNo: prevState.pageNo + 1 };
    }, () => {
      if (this.state.isSearchBox) {
        this.getCatgSearchList(this.state.searchParams);
      } else {
        this.getCatagList();
      }
    });
  }

  handleNavigateBrand= (brandId: number) => {
    setStorageData("brandId", brandId);
    this.handleNavigateFromBranding("Catalogue");
  };

  handleNavigateFromBranding = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  isValidResponseSearch = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.data || responseJson.message || responseJson.catalogue_id);
  };

  apiSuccessCallBacksSearch = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    window.scrollTo(0, 0);
    if (apiRequestCallId === this.getSeachApiCallId) {
      this.loaderOff();
      this.setState({
        searchData: responseJson.data,
        isParamsupdated: false,
        productCount: responseJson.count,
        isSearchBox: true,
        arrayHolder1: []
      },()=>{
        if(this.state.searchData.length === 0){
          this.setState({
            isNoDataSearch: true,
          })
        }
      });
    }
    if (apiRequestCallId === this.getProductApiCallId1) {
      this.loaderOff();
      if(responseJson.message === "No catalogue found matching the specified criteria."){
        this.setState({
          isSearchBox: false,
          productCount: 0,
          searchData: [],
          isParamsupdated:false,
        },()=>{
          this.setState({
            arrayHolder1: [],
            isNoDataCat: true,
          });
        });
      }else {
        this.setState({
          productCount: responseJson.count,
          isSearchBox: false,
          isParamsupdated:false,
          searchData: [],
          isNoDataCat: false,
          isNoDataSearch: false
        },()=>{
          this.setState({
            arrayHolder1: responseJson.data,
          });
        });
      }
    }
    if (apiRequestCallId === this.getProductApiCallId) {
      this.loaderOff();
      this.setState({ arrayHolder: responseJson.data });
    }
    if (apiRequestCallId === this.postAddToWishlistItemApiCallId) {
      this.handleNavigateFromBranding("Wishlist")
    }
  };

  errorsInvalidToken = (responseJson: InvalidResponseType) => {
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.handleNavigateFromBranding("login");
    }
  }

  apiFailureCallBacksSearch = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType & InvalidResponseTypePost
  ) => {
    this.loaderOff();
    if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
      this.errorsInvalidToken(responseJson)
    } else {
      if (apiRequestCallId === this.postAddToWishlistItemApiCallId) {
        this.snackBarShowCat(true, "error", responseJson.error);
      }
    }
  };


 
  buildApiEndpointCat = () => {
    const { pageNo, grid4 } = this.state;
    const { category_name,subcategory_name, manufacturer_name, family_name, sector_name } = this.state.apiSelectedSubCat;
    const manufacturerIdsParam = manufacturer_name.id ? `manufacturer_ids[]=${manufacturer_name.id}` : '';
    const categoryIdsParam = category_name.id ? `category_ids[]=${category_name.id}` : '';
    const subcategoryIdsParam = subcategory_name.id ? `sub_category_ids[]=${subcategory_name.id}` : '';
    const sectorIdsParam = sector_name.id ? `sector_ids[]=${sector_name.id}` : '';
    const familyIdsParam = family_name.id ? `family_ids[]=${family_name.id}` : '';

    const pageParam = `page=${pageNo}`;
    const perPageParam = `per_page=${grid4 ? 12 : 16}`;
    const cataloguesTypeParam = 'catalogues_type=spare';
    const queryParams = [
      `${configJSON.productAPiEndPoint}?`,
      `${categoryIdsParam}&`,
      `${subcategoryIdsParam}&`,
      `${manufacturerIdsParam}&`,
      `${familyIdsParam}&`,
      `${sectorIdsParam}&`,
      `${pageParam}&`,
      `${perPageParam}&`,
      `${cataloguesTypeParam}`
    ];

    const queryString = queryParams.join('');
    return queryString;
  };

  loaderOff = 
  () => {
    this.setState({
      isLoading: false
    })
  }

  buildApiEndpointBrand = () => {
    const { pageNo, grid4, brandId } = this.state;
    const { category_name,subcategory_name, manufacturer_name, family_name, sector_name } = this.state.apiSelectedSubCat;
    const categoryIdsParam = category_name.id ? `category_ids[]=${category_name.id}` : '';
    const subcategoryIdsParam = subcategory_name.id ? `sub_category_ids[]=${subcategory_name.id}` : '';
    const familyIdsParam = family_name.id ? `family_ids[]=${family_name.id}` : '';
    const manufacturerIdsParam = manufacturer_name.id ? `manufacturer_ids[]=${manufacturer_name.id}` : '';
    const sectorIdsParam = sector_name.id ? `sector_ids[]=${sector_name.id}` : '';

    const brandParam = `brand_id=${brandId}`;
    const perPageParam = `per_page=${grid4 ? 12 : 16}`;
    const pageParam = `page=${pageNo}`;
    const cataloguesTypeParam = 'catalogues_type=spare';
    const queryParams = [
      `${configJSON.productAPiEndPoint}?`,
      `${brandParam}&`,
      `${categoryIdsParam}&`,
      `${subcategoryIdsParam}&`,
      `${manufacturerIdsParam}&`,
      `${familyIdsParam}&`,
      `${sectorIdsParam}&`,
      `${pageParam}&`,
      `${perPageParam}`,
      `${cataloguesTypeParam}`
    ];

    const queryString = queryParams.join('');
    return queryString;
  };

  loaderOn = () => {
    this.setState({
      isLoading: true
    })
  }

  apiEndPointList = () => {
    const { brandId } = this.state;
    const apiEndpoint = brandId ? this.buildApiEndpointBrand() : this.buildApiEndpointCat();
    return apiEndpoint;
  }

  countPage = () => {
    const productCount = this.state.productCount;
    const productsPerPage = 16;
    const totalPages = Math.ceil(productCount / productsPerPage);
    return totalPages;
  }

  getCatagList = () => {
    this.loaderOn();
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.apiEndPointList()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChipDelete = (event: [string, string]) => {

    const keyToRemove = event[0];
    const { [event[0]]: removedValue, ...restData } = this.state.selectedSubCat;
    this.setState({ selectedSubCat: restData, isParamsupdated: true }, () => {

      if (keyToRemove in this.state.searchParams) {
        this.setState(prevState => ({
          searchParams: {
            ...prevState.searchParams,
            [keyToRemove]: "",
          }
        }), () => {
          if (this.state.isSearchBox) {
            this.getCatgSearchList(this.state.searchParams);
          }
        });
      }
    });
  };

  pushSelectedDataRadio = (data: SearchParamsCat) => {
    const selectedSubCat: SelectedSubCatType = {};

    for (const keys in data) {
      if (data[keys as keyof SearchParamsCat].name) {
          selectedSubCat[keys] = data[keys as keyof SearchParamsCat].name;
      }
    }

    this.setState({
      selectedSubCat: selectedSubCat,
      apiSelectedSubCat: data,
      pageNo: 1
    }, () => {
      this.getCatagList();
    });
  }

  handleMenu=()=>{
    this.setState({isToggle:!this.state.isToggle});
  }

  getCatgSearchList = (parmasData: SearchParams) => {

    this.loaderOn();
    
    const { name, family, part, sector, description, sorting, manufacturer } = parmasData

    const head = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSeachApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchAPiEndPoint}?search[manufacturer_names]=${manufacturer}&search[family_names]=${family}&search[part_numbers]=${part}&search[sector_names]=${sector}&search[catalogue_descriptions]=${description}&search[catalogue_names]=${name}&sort_by=${sorting}&page=${this.state.pageNo}&per_page=${this.state.grid4 ? 12 : 16}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(head)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };

  goBack=()=>{
    this.props.navigation.goBack()
  }

  handleChipDeleteCat = (event: [string, string]) => {

    const keyToDelete = event[0];

    const { [event[0]]: removedValue, ...restData } = this.state.selectedSubCat;

    this.setState({ selectedSubCat: restData, isParamsupdated: true }, () => {

      if (keyToDelete in this.state.apiSelectedSubCat) {

        this.setState(prevState => ({
          apiSelectedSubCat: {
            ...prevState.apiSelectedSubCat,
            [keyToDelete]: {id:0, name:""}
          }
        }), () => {
            this.getCatagList();
        });

      }
    });
  }

  changeGrid = () => {
    this.setState((prevState) => ({
      grid4: !prevState.grid4
    }), () => {
      if (this.state.isSearchBox) {
        this.getCatgSearchList(this.state.searchParams);
      } else {
        this.getCatagList();
      }
    });
  }

  advancedSearchBtn = () => {
    this.setState({
      selectedSubCat: {},
      pageNo: 1,
      isSearchBox: true,
      productCount: 0,
      isNoDataCat: false,
      isNoDataSearch: false,
    },()=>{
      let search = {
        name: "",
        part: "",
        description: "",
        manufacturer: "",
        sector: "",
        family: "",
        codetype: "",
        sorting: ""
      }
      this.getCatgSearchList(search)
    });
  }

  handleSearchButton = (data: SearchParams) => {
    const selectedSubCat: SelectedSubCatType = {};

    for (const keys in data) {
      if (data[keys as keyof SearchParams]) {
        selectedSubCat[keys] = data[keys as keyof SearchParams];
      }
    }
    this.setState({
      selectedSubCat: selectedSubCat,
      searchParams: data,
      pageNo: 1
    }, () => {
      this.getCatgSearchList(data)
    });
  };

  advancedSearchCancel = () => {
    let searchParams = {
      name: "",
      part: "",
      description: "",
      manufacturer: "",
      sector: "",
      family: "",
      codetype: "",
      sorting: ""
    };
    this.setState({
      selectedSubCat: {},
      pageNo: 1,
      searchParams: searchParams,
      isNoDataCat: false,
      isNoDataSearch: false,
    },()=>{
      this.getCatagList()
    });
  }

  goToProductDesc = (productDescID: number | string) => {
    this.props.navigation.navigate("ProductDescription", { "id": productDescID })
  }

  addToWishlistCat = async (catId : string) => {
    let tokenApi = (await StorageProvider.get("token")) || "";

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddToWishlistItemApiCallId = requestMessage.messageId;

    const httpBody = {
      catalogue_id: Number(catId),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAddToWishlistItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  snackBarShowCat = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForCat: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };

  checkLabel = (event: string): string => {
    let radioOptions = [
      { label: "A-Z", value: "name_asc" },
      { label: "Price: High to Low", value: "price_desc" },
      { label: "Price: Low to High", value: "price_asc" },
      { label: "Newest to Oldest", value: "newest_first" },
      { label: "Oldest to Newest", value: "oldest_first" }
    ];
    const option = radioOptions.find(optionVa => optionVa.value === event);
    return option ? option.label : event;
  };
  
  // Customizable Area End
}
