import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ChangeEvent } from "react";
interface CategoriesType {
  Check: boolean;
  categoryId: string;
  expand: boolean;
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
interface SubcategoriesType {
  Check?: boolean;
  categoryId?: string;
  expand?: boolean;
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      parent_id: number;
  };
}
interface ManufactureType {
  Check?: boolean;
  categoryId?: string;
  expand?: boolean;
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
interface FamilyType {
  Check?: boolean;
  categoryId?: string;
  expand?: boolean;
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
interface SectorType {
  Check?: boolean;
  categoryId?: string;
  expand?: boolean;
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
interface ChipData {
  category: [];
  manufacturer: [];
  family: [];
  sector: [];
}

interface SearchParams {
  category_name: { id: number; name: string };
  subcategory_name: { id: number; name: string };
  manufacturer_name: { id: number; name: string };
  family_name: { id: number; name: string };
  sector_name: { id: number; name: string };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedSubCat: SearchParams;
  pushSelectedData: (data: SearchParams) => void;
  handleClear: () => void;
  isParamsonUpdated: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: CategoriesType[];
  subCategoriesArray: SubcategoriesType[]
  manufaturerArray: ManufactureType[];
  familyArray: FamilyType[];
  sectorArray: SectorType[];
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: Array<string | number>;
  categoryRadio: string;
  subCategoryRadio:string;
  manufacturerRadio: string;
  familyRadio: string;
  sectorRadio: string;
  showAllCategories: boolean;
  showAllSubCategories:boolean;
  showAllManucaturer: boolean;
  showAllFamily: boolean;
  showAllSector: boolean;
  chipData: ChipData;
  search: {
    category_name: { id: number; name: string };
    subcategory_name: { id: number; name: string };
    manufacturer_name: { id: number; name: string };
    family_name: { id: number; name: string };
    sector_name: { id: number; name: string };
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  // Customizable Area Start
  categoryID: string = "";
  manufacturerID: string = "";
  familyID: string = "";
  sectorID: string = "";
  subCategoriesID: string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      subCategoriesArray:[],
      manufaturerArray: [],
      familyArray: [],
      sectorArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      categoryRadio: "",
      subCategoryRadio:"",
      manufacturerRadio: "a",
      familyRadio: "a",
      sectorRadio: "a",
      showAllCategories: false,
      showAllSubCategories:false,
      showAllManucaturer: false,
      showAllFamily: false,
      showAllSector: false,
      chipData: { category: [], manufacturer: [], family: [], sector: [] },
      search: {
        category_name: {
          id: 0,
          name: "",
        },
        subcategory_name: {
          id: 0,
          name: "",
        },
        manufacturer_name: {
          id: 0,
          name: "",
        },
        family_name: {
          id: 0,
          name: "",
        },
        sector_name: {
          id: 0,
          name: "",
        },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.handleCategory();
    this.handleSubCategories();
    this.handleManufacturer();
    this.handleFamily();
    this.handleSector();
    this.setState({
      search: this.props.selectedSubCat,
    });
    // Customizable Area End
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(nextProps: { isParamsonUpdated: boolean }) {
    if (nextProps.isParamsonUpdated !== this.props.isParamsonUpdated) {
      this.setState({
        search: this.props.selectedSubCat,
      });
    }
  }

  handleRestAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (responseJson && responseJson.data) {
      this.handleDataResponse(apiRequestCallId, responseJson.data);
    } else if (responseJson && responseJson.errors) {
      this.handleErrorResponse(responseJson, errorResponse, apiRequestCallId);
    }
  }

  handleDataResponse(apiRequestCallId: string, data: CategoriesType[]) {
    switch (apiRequestCallId) {
      case this.getCategoriesApiCallId:
        this.handleGetCategories(data);
        break;
      case this.addCategoryApiCallId:
        this.handleAddCategory();
        break;
      case this.addSubCategoryApiCallId:
        this.handleAddSubCategory();
        break;
      case this.categoryID:
        this.setState({ categoriesArray: data });
        break;
      case this.subCategoriesID:
        this.setState({ subCategoriesArray: data as SubcategoriesType[]});
        break;
      case this.manufacturerID:
        this.setState({ manufaturerArray: data as ManufactureType[] });
        break;
      case this.familyID:
        this.setState({ familyArray: data as FamilyType[] });
        break;
      case this.sectorID:
        this.setState({ sectorArray: data as SectorType[] });
        break;
      default:
        break;
    }
  }

  handleGetCategories(data: CategoriesType[]) {
    let array = data;
    for (let element of array) {
      element.expand = false;
      element.Check = false;
    }
    this.setState({ categoriesArray: array });
  }

  handleAddCategory() {
    this.setState({ isVisible: false, category: "" }, () => {
      this.getCategories();
    });
  }

  handleAddSubCategory() {
    this.setState(
      {
        isVisible: false,
        category: "",
        subCategory: "",
        selectedCategoryID: [],
      },
      () => {
        this.getCategories();
      }
    );
  }

  handleErrorResponse(
    responseJson: { error: { message: string } },
    errorResponse: boolean,
    apiRequestCallId: string
  ) {
    if (
      apiRequestCallId === this.deleteCategoriesApiCallId &&
      !responseJson.error
    ) {
      this.getCategories();
    } else if (
      apiRequestCallId === this.deleteCategoriesApiCallId &&
      responseJson.error
    ) {
      this.parseApiCatchErrorResponse(responseJson.error.message);
    } else if (
      apiRequestCallId === this.deleteSubCategoriesApiCallId &&
      !responseJson.error
    ) {
      this.getCategories();
    } else if (
      apiRequestCallId === this.deleteSubCategoriesApiCallId &&
      responseJson.error
    ) {
      this.parseApiCatchErrorResponse(responseJson.error.message);
    } else {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (
    item: { attributes: { id: string | number } },
    catIndex: number
  ) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let indexID = idarray.indexOf(item.attributes.id);

    if (indexID > -1) {
      idarray.splice(indexID, 1);
      array[catIndex].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[catIndex].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus,
    });
  };
  expand = (categoryId: string) => {
    let array = this.state.categoriesArray;
    for (const item of array) {
      if (item.categoryId === categoryId) {
        item.expand = !item.expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(string: string) {
    return string === null || string.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let catData = {
        categories: [{ name: this.state.category }],
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(catData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let subcatData = {
        sub_category: {
          name: this.state.subCategory,
        },
        parent_categories: this.state.selectedCategoryID,
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(subcatData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (deleteId: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (deleteSubId: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${deleteSubId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCategory = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    this.categoryID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleManufacturer = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.manufacturerAPIEndPoint
    );
    this.manufacturerID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleFamily = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.familyAPIEndPoint
    );
    this.familyID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleSector = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectorAPIEndPoint
    );
    this.sectorID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleSubCategories = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoriesAPIEndPoint
    );
    this.subCategoriesID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleRadioChange = (event: ChangeEvent<HTMLInputElement>, manId: number) => {
    const { name, value } = event.target;
    this.setState(
      (prevState) => ({
        search: {
          ...prevState.search,
          [name]: { id: manId, name: value },
        },
      }),
      () => {
        this.props.pushSelectedData(this.state.search);
      }
    );
  };

  handleViewAllCategories = () => {
    this.setState((prevState) => ({
      showAllCategories: !prevState.showAllCategories,
    }));
  };
  handleViewAllSubCategories = () => {
    this.setState((prevState) => ({
      showAllSubCategories: !prevState.showAllSubCategories,
    }));
  };
  handleViewAllManufacturer = () => {
    this.setState((prevState) => ({
      showAllManucaturer: !prevState.showAllManucaturer,
    }));
  };
  handleViewAllFamily = () => {
    this.setState((prevState) => ({
      showAllFamily: !prevState.showAllFamily,
    }));
  };
  handleViewAllSector = () => {
    this.setState((prevState) => ({
      showAllSector: !prevState.showAllSector,
    }));
  };
  handleCategories = () => {
    const {
      showAllCategories,
      categoriesArray,
      showAllSubCategories,
      subCategoriesArray,
      manufaturerArray,
      showAllManucaturer,
      familyArray,
      showAllFamily,
      sectorArray,
      showAllSector,
    } = this.state;
    const categoriesToDisplay = showAllCategories
      ? categoriesArray
      : categoriesArray.slice(0, 5);
    const subCategoriesToDisplay = showAllSubCategories
    ? subCategoriesArray
    : subCategoriesArray.slice(0, 5);
    const manufactureToDisplay = showAllManucaturer
      ? manufaturerArray
      : manufaturerArray.slice(0, 5);
    const familyToDisplay = showAllFamily
      ? familyArray
      : familyArray.slice(0, 5);
    const sectorToDisplay = showAllSector
      ? sectorArray
      : sectorArray.slice(0, 5);
    return {
      categoriesToDisplay,
      subCategoriesToDisplay,
      manufactureToDisplay,
      familyToDisplay,
      sectorToDisplay,
    };
  };

  clearAllFilter = () => {
    this.setState({
      familyRadio: "",
      sectorRadio: "",
      categoryRadio: "",
      subCategoryRadio:"",
      manufacturerRadio: "",
    });
    this.props.handleClear();
  };

  // Customizable Area End
}
