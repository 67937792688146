import React from 'react'
// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  createStyles,
  withStyles,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Singuptextborder, backgroundImage, logo, successModal } from "./assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
import { SnackBarAlert } from '../../../components/src/SnackBarAlert.web';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderUserFirstNameInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>First Name*</Typography>
        <TextField
          error={this.state.firstNameError}
          helperText={
            this.state.firstNameError
              ? <span className={style.errorMsgBox}>Please enter your first name.</span>
              : ""
          }
          placeholder="First Name"
          className={this.state.firstNameError ? style.signUPFormInputBoxError : style.signUPFormInputBox}
          id="outlined-basic"
          variant="outlined"
          value={this.state.firstName}
          onChange={this.handleFirstNameInput}
          data-test-id="firstNameInputTestID"
        />
      </Box>
    )
  }

  renderUserLastNameInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>Last Name*</Typography>
        <TextField
          error={this.state.lastNameError}
          helperText={
            this.state.lastNameError
              ? <span className={style.errorMsgBox}>Please enter your last name.</span>
              : ""
          }
          placeholder="Last Name"
          className={this.state.lastNameError ? style.signUPFormInputBoxError : style.signUPFormInputBox}
          id="outlined-basic"
          variant="outlined"
          value={this.state.lastName}
          onChange={this.handleLasttNameInput}
          data-test-id="lastNameInputTestID"
        />
      </Box>
    )
  }

  renderUserEmailInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>Email*</Typography>
        <TextField
          error={this.state.emailError || this.state.blankEmailError} helperText={
            this.state.emailError || this.state.blankEmailError
              ? <span className={style.errorMsgBox}>{this.state.emailErrorMsg}</span>
              : ""
          }
          placeholder="Email"
          className={this.state.emailError || this.state.blankEmailError ? style.signUPFormInputBoxError : style.signUPFormInputBox}
          id="outlined-basic"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleUserEmailInput}
          data-test-id="emailInputTestID"
        />
      </Box>
    )
  }

  renderUserPasswordInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>Password*</Typography>
        <TextField
          error={this.state.passwordError || this.state.blankPasswordError}
          helperText={this.getPasswordErrorMessage()}
          placeholder="Password"
          className={
            this.state.passwordError || this.state.blankPasswordError
              ? style.signUPFormInputBoxError
              : style.signUPFormInputBox
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.password}
          onChange={this.handlePasswordInput}
          data-test-id="passwordInputTestID"
          type={this.state.showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordSignup}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }

  renderUseContactInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>Contact Number*</Typography>
        <TextField
          error={this.state.contactError || this.state.contactBlankError}
          helperText={this.getContactErrorMessage()}
          placeholder="Contact Number"
          className={this.state.contactError || this.state.contactBlankError ? style.signUPFormInputBoxError : style.signUPFormInputBox}
          id="outlined-basic"
          variant="outlined"
          value={this.state.contact}
          onChange={this.handleContactInput}
          data-test-id="numberInputTestID"
        />
      </Box>
    )
  }

  renderUserGstSelectInput = (style: Record<string, string>) => {
    const options = ['Registered user', 'Unregistered user'];
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>GST Number*</Typography>
        <Autocomplete
          value={this.state.gstSelect}
          id="controllable-states-demo"
          options={options}
          data-test-id="gatSelectInputTestID"
          onChange={this.handleGstSelectInput}
          className={style.signUPFormInputBox || style.gstSelect}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          popupIcon={<KeyboardArrowDownIcon />}
          onOpen={this.onDropdownOpen}
          onClose={this.onDropdownClose}
          forcePopupIcon={"auto"}
        />
      </Box>
    )
  }

  renderUserGstInput = (style: Record<string, string>) => {
    return (
      <Box className={style.signUpFormFieldContainer}>
        <Typography className={style.signUPFormFieldLabel}>{(this.state.userType === 'dealer' || this.state.gstSelect === "Registered user") ? "GST Number*" : "GST Number"}</Typography>
        <TextField
          error={this.state.gstNumberError || this.state.blankGstError}
          helperText={
            this.state.gstNumberError || this.state.blankGstError
              ? <span className={style.errorMsgBox}>{this.state.gstErrorMsg}</span>
              : ""
          }
          placeholder="GST Number"
          className={this.state.gstNumberError || this.state.blankGstError ? style.signUPFormInputBoxError : style.signUPFormInputBox}
          id="outlined-basic"
          variant="outlined"
          value={this.state.gstNumber}
          onChange={this.handleGstNumInput}
          data-test-id="gstInputTestID"
        />
      </Box>
    )
  }

  render() {
    const style = this.props.classes;
    return (
      <>
        <Grid container className={style.signUpContainer} >
          <Grid item xs={false} sm={6} md={7} lg={7} className={style.image_Container_Box}>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <Box className={style.signUp_Main_Container}>
              <Box className={style.signUp_Logo_Container} >
                <Box>
                  <img src={logo} alt="logo" className={style.logo} />
                </Box>
                <Box className={style.signupTextHeader}>
                  <Typography className={style.signUpText}>
                    Sign Up
                  </Typography>
                  <img src={Singuptextborder} alt="Singuptextborder" className={style.Singuptextborder} />
                </Box>
              </Box>
              <form data-test-id="formSubmitTestID" autoComplete="off" className={style.shipment_Form_Main_Container} onSubmit={this.handleSignUp}>
                {this.renderUserFirstNameInput(style)}
                {this.renderUserLastNameInput(style)}
                {this.renderUserEmailInput(style)}
                {this.renderUserPasswordInput(style)}
                {this.renderUseContactInput(style)}
                {this.state.userType === "customer" && this.renderUserGstSelectInput(style)}
                {this.renderUserGstInput(style)}
                <Box className={style.signUpFormFieldContainer}>
                  <Button className={style.signUpButton} type="submit">Sign up</Button>
                </Box>
                <Box className={style.signUpFormFieldContainer}>
                  <Box className={style.signUpFormLoginButton}>
                    <Typography className={style.signUPFormFieldLabelForlogintext}>Already have an account? <span  data-test-id="loginBtnModalTestID" onClick={() => this.handleNavigate("login")} className={style.loginText}>Log in
                      <img src={Singuptextborder} alt="Singuptextborder" className={style.loginTextborder} />
                    </span></Typography>
                  </Box>
                </Box>
              </form>
              {this.state.SignUpSuccessModel &&
                <SignUpSuccessModel
                  data-test-id="successModalTestID"
                  loginBtnClick={() => this.handleNavigate("login")}
                  image={successModal} heading={"Sign Up Successful!"}
                  description={"The admin will review and confirm your account within 48 hours!"}
                  showBtn={true} 
                />
              }
              <SnackBarAlert
                data-test-id="snackBarTestID"
                snackBarProps={this.state.hasSuccessErrorForSignup}
                handleCloseSnack={this.handleCloseSnackbar}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
  // Customizable Area End
}
// Customizable Area Start
const Styles = () =>
  createStyles({
    signUpContainer:{
      width: "100%",
      height: "100%",
    },
    logo: {
      width: "138px",
      height: "36px",
      flexShrink: 0,
    },
    signUpText: {
      fontSize: "48px",
      fontWeight: 700,
      position: "relative",
      zIndex: 99,
      fontStyle: "normal",
      color: "#000"
    },
    Singuptextborder: {
      top: "14px",
      left: "-21px",
      width: "99.167px",
      height: "35px",
      position: "absolute",
    },
    loginText: {
      color: "#000",
      textDecoration: "underline",
      position: "relative",
      zIndex: 99,
      fontSize: "16px",
      paddingLeft: "8px",
      cursor: "pointer"
    },
    loginTextborder: {
      bottom: "1px",
      right: "-2px",
      width: "48px",
      height: "fit-content",
      position: "absolute",
      transform: "scale(-1, -1)",
      zIndex: -1
    },
    signupTextHeader: {
      position: "relative",
    },
    image_Container_Box: {
      background: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%"
    },
    signUp_Main_Container: {
      padding: "72px 20% 32px",
      display: "flex",
      flexDirection: "column",
      gap: "34px"
    },
    signUp_Logo_Container: {
      display: "flex",
      flexDirection: "column",
      gap: "50px"
    },
    shipment_Form_Main_Container: {
      display: "flex",
      flexDirection: "column",
      gap: "12px"
    },
    signUpFormFieldContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    signUpFormLoginButton: {
      textAlign: "center",
      position: "relative",
      width: "100%",
      marginTop: "20px",
    },
    signUPFormFieldLabel: {
      marginBottom: "4px",
      color: "#334155",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    signUPFormFieldLabelForlogintext: {
      marginBottom: "10px",
      position: "relative",
      zIndex: 999,
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    signUPFormFieldLabelError: {
      marginBottom: "4px",
      position: "relative",
      zIndex: 999,
      opacity: 0.5,
      color: "#EE3D3D",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    signUpButton: {
      width: "100%",
      background: "#000",
      border: "none",
      color: "#ffff",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "8px",
      padding: "10px 16px",
      textTransform: "initial",
      "&:hover": {
        background: "Black",
      },
      "& .MuiButton-root":{
        textTransform: "initial"
      }
    },
    signUPFormInputBox: {
      "& .MuiAutocomplete-inputRoot":{
        paddingLeft:"4px !important",
        padding:"unset !important",
      },
      width: "100%",
      background: "transparent",
      border: "none",
      color: "#64748B",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      "& .MuiOutlinedInput-input": {
        padding: "10px 8px",
        fontSize: "14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        border: "none"
      },
      "& label.Mui-focused": {
        border: "none"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#0094A7",
      },
      "& .MuiInputBase-root": {
        marginBottom: "10px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid black",
        },
        "&:hover fieldset": {
          border: "1px solid black",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid black",
        },
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        fontWeight: 700,
        fontSize: "14px",
      },
    },
    signUPFormInputBoxError: {
      background: "transparent",
      width: "100%",
      color: "#64748B",
      border: "none",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 400,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        border: "none"
      },
      "& .MuiOutlinedInput-input": {
        padding: "10px 8px",
        fontSize: "14px",
      },
      "& label.Mui-focused": {
        border: "none"
      },
      "& .MuiInputBase-root": {
        marginBottom: "0px",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#0094A7",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: "1px solid #EE3D3D",
        },
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #EE3D3D",
        },
        "&:hover fieldset": {
          border: "1px solid #EE3D3D",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        width: "fit-content",
        position: "relative",
        color: "#DC2626",
        fontFamily: "sans-serif",
        borderRadius: "6px",
        border: "none",
        fontWeight: 400,
        fontSize: "12px",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        fontWeight: 700,
        fontSize: "14px",
      },
    },
    upborderimg: {
      "width": "119px",
      "height": "37px",
      position:"absolute",
      top: "-15px",
      left: "-50px",
    },
    downborderimg: {
      "width": "119px",
      "height": "37px",
      position:"absolute",
      bottom: "-15px",
      right: "-60px",
      transform:"rotate(90deg)",
    },
    loginButton: {
      width: "322px",
      height: "52px",
      background: "#000",
      border: "none",
      color: "#ffff",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "8px",
      padding: "10px 16px",
      "&:hover": {
        background: "Black",
      },
    },
    signUp_Model_MainContainer: {
      padding: "50px 100px !important",
      display: "flex",
      width: "841px",
      height: "489px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
      flexShrink: 0,
      "@media (min-width:576px) and (max-width:768px)": {
        width: "unset",
        height: "unset",
      },
    },
    imageStyle: {
      width: "117px",
      height: "120px",
    },
    messageText: {
      color: "#000",
      textAlign: "center",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    },
    modalContentText: {
      color: "#000",
      textAlign: "center",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    signUp_ModelContainer: {
      position:"relative",
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100% !important",
        maxHeight: "100% !important"
      },
      "& .MuiDialog-paper": {
        overflowY:"unset"
      }
    }
  });
export default withStyles(Styles)(EmailAccountRegistration);
// Customizable Area End