import React from "react";

// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { slider1} from "./assets";
import Carousel from "react-material-ui-carousel";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const CarouselDiv = styled('div')({
  '& .CarouselItem img': {
    height: '100%'
  }
})
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderThumbnails() {
    return (
      <Box style={webStyle.indicatorIconWrap}>
      {this.props.carousel.map((item: any, index: any) => {
        return (
          <Box
            key={index}
            onClick={() => this.setState({ index: index })}
            style={webStyle.indicatorIcon}
          >
            <Box
              style={{
                flex: 1,
                backgroundImage: `url(${item.attributes.image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                border: `${
                  index === this.state.index
                    ? "3px solid #007aff"
                    : "3px solid #fff"
                }`,
                borderRadius: 100,
              }}
            />
          </Box>
        );
      })}
    </Box>
    );
  }

  prevSlide() {
    const newIndex = this.state.index - 1;
    this.setState({ index: newIndex < 0 ? this.props.carousel.length - 1 : newIndex });
  }

  nextSlide() {
    const newIndex = this.state.index + 1;
    this.setState({ index: newIndex >= this.props.carousel.length ? 0 : newIndex });
  }
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <CarouselDiv>
          <Carousel
            animation="slide"
            swipe={false}
            index={this.state.index}
            indicators={false}
            onChange={this.props.setCurrentCarouselIndex}
          >
            {this.props.carousel.map((item: any, i: any) => {
              return (
                <Box key={i} style={{ ...webStyle.carousel }}>
                  <img
                    style={{ objectFit: "cover", width:"100vw" }}
                    src={item.attributes.image}
                    alt={`slide-${i}`}
                  />
                </Box>
              );
            })}
         </Carousel>
         <Box style={{ ...webStyle.navigationBox, position :"absolute" }}>
          <button onClick={() => this.prevSlide()} data-test-id="prev" style={{...webStyle.navigationButton,borderRight: "1px solid black" }}><ArrowLeft style={{ color: "black" }}/></button>
          <button onClick={() => this.nextSlide()} data-test-id="next" style={webStyle.navigationButton}><ArrowRight style={{ color: "black" }}/></button>
         </Box>
          {this.renderThumbnails}
        </CarouselDiv>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    width:'100vw',
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carousel: {
    flex: 1,
  },
  indicatorIconWrap: {
    display: "none",
    backgroundColor: "white",
    justifyContent: "center",
  },
  nextPrevButtonStyle:{
  bottom: "10px",
  right: "10px",
  background: "#E8B846",
  padding: "5px 10px",
  borderRadius: "5px",
  color: "#fff",
  cursor: "pointer",
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
  navigationBox: {
   right:'1px',
    bottom: "10px",
    zIndex: 1000,
  },
  navigationButton: {
    backgroundColor: "#E8B846",
    color: "#fff",
    padding: "8px 16px",
    border: "none",
    cursor: "pointer",
    width:"6vw",
    borderTopLeftRadius:"6%"
  },

};
// Customizable Area End