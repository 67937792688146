import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  TextField,
  Button,
  Radio,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import { Group } from "./assets";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNameInput = () => {
    return (
      <Box className="searchFormFieldContainer">
        <Typography className="searchFormFieldLabel">
          Name of the product
        </Typography>
        <TextField
          placeholder="Name of the product"
          onChange={this.handleChange}
          className="searchFormInputBox"
          id="outlined-basic"
          value={this.state.search.name}
          data-test-id="inputTestId"
          variant="outlined"
          name="name"
          type="text"
        />
      </Box>
    );
  };

  renderPartNumberInput = () => {
    return (
      <>
        <Box className="searchFormFieldContainer">
          <Typography className="searchFormFieldLabel">Part Number</Typography>
          <TextField
            placeholder="Part Number"
            onChange={this.handleChange}
            className="searchFormInputBox searchFormInputBoxmargin"
            id="outlined-basic"
            value={this.state.search.part}
            data-test-id="inputTestId"
            variant="outlined"
            name="part"
            type="text"
          />
        </Box>
        <Box className="codemainBox">
          <Box className="codemain">
            <Radio
              data-test-id="checkboxAnonymousTestID"
              checked={this.state.search.codetype === "Exact Code"}
              onChange={this.handleChange}
              className="checkboxAddress"
              checkedIcon={<span className={clsx("icon", "checkedIcon")} />}
              value="Exact Code"
              name="codetype"
            />
            <Typography className="codeText">Exact Code</Typography>
          </Box>
          <Box className="codemain">
            <Radio
              data-test-id="checkboxAnonymousTestID"
              checked={this.state.search.codetype === "Partial Code"}
              onChange={this.handleChange}
              className="checkboxAddress"
              checkedIcon={<span className={clsx("icon", "checkedIcon")} />}
              value="Partial Code"
              name="codetype"
            />
            <Typography className="codeText">Partial Code</Typography>
          </Box>
        </Box>
      </>
    );
  };

  renderDescriptionInput = () => {
    return (
      <Box className="searchFormFieldContainer">
        <Typography className="searchFormFieldLabel">
          Description/Technical note
        </Typography>
        <TextField
          placeholder="Description/Technical note"
          onChange={this.handleChange}
          className="searchFormInputBox"
          id="outlined-basic"
          value={this.state.search.description}
          data-test-id="inputTestId"
          variant="outlined"
          name="description"
          type="text"
        />
      </Box>
    );
  };

  renderManufacturerInput = () => {
    return (
      <Box className="searchFormFieldContainer">
        <Typography className="searchFormFieldLabel">Manufacturer</Typography>
        <TextField
          placeholder="Manufacturer"
          onChange={this.handleChange}
          className="searchFormInputBox"
          id="outlined-basic"
          value={this.state.search.manufacturer}
          data-test-id="inputTestId"
          variant="outlined"
          name="manufacturer"
          type="text"
        />
      </Box>
    );
  };

  renderSectorInput = () => {
    return (
      <Box className="searchFormFieldContainer">
        <Typography className="searchFormFieldLabel">Sector</Typography>
        <TextField
          placeholder="Sector"
          onChange={this.handleChange}
          className="searchFormInputBox"
          id="outlined-basic"
          value={this.state.search.sector}
          data-test-id="inputTestId"
          variant="outlined"
          name="sector"
          type="text"
        />
      </Box>
    );
  };

  renderFamilyInput = () => {
    return (
      <Box className="searchFormFieldContainer">
        <Typography className="searchFormFieldLabel">Family</Typography>
        <TextField
          placeholder="Family"
          onChange={this.handleChange}
          className="searchFormInputBox"
          id="outlined-basic"
          value={this.state.search.family}
          data-test-id="inputTestId"
          variant="outlined"
          name="family"
          type="text"
        />
      </Box>
    );
  };

  renderSorting = () => {
    return (
      <>
        <Box className="mainsorting">
          <Typography className="sortingtext">SORTING</Typography>
          <Typography
            data-test-id="clearSortingTestId"
            className="cleartext"
            onClick={this.handleSortingClear}
          >
            clear
          </Typography>
        </Box>
        <Box className="codemainBox codesorting">
          {this.state.radioOptions.map((option, index) => (
            <Box key={index} className="codemain">
              <Radio
                data-test-id={`RadioTestID_${index}`}
                checked={this.state.search.sorting === option.value}
                onChange={this.handleRadioChange}
                className="checkboxAddress"
                checkedIcon={<span className={clsx("icon", "checkedIcon")} />}
                value={option.value}
                name="sorting"
              />
              <Typography className="codeText">{option.label}</Typography>
            </Box>
          ))}
        </Box>
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <MainBox>
        {this.renderNameInput()}
        {this.renderPartNumberInput()}
        {this.renderDescriptionInput()}
        {this.renderManufacturerInput()}
        {this.renderSectorInput()}
        {this.renderFamilyInput()}
        <Box className="searchButtonContainer">
          <Button
            className="clearButton"
            onClick={this.handleClear}
            data-test-id="clearButtonTestId"
          >
            Clear
          </Button>
          <Button
            className="searchButton"
            onClick={this.handleSearchButton}
            data-test-id="searchButtonTestId"
          >
            Search
          </Button>
        </Box>
        {this.renderSorting()}
      </MainBox>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRight: "1px solid rgba(230, 230, 230, 1)",
  padding: "0 32px 0 0",
  gap: "8px",
  "& .codesorting": {
    marginTop: "8px !important",
  },
  "& .codemainBox": {
    display: "flex",
    gap: "6px",
    flexDirection: "column",
    marginBottom: "6px !important",
  },
  "& .codemain": {
    display: "flex",
    gap: "8px",
  },
  "& .codeText": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    alignSelf: "center",
    color: "#0F172A",
  },
  "& .MuiCheckbox-root": {
    width: 20,
    height: 20,
    color: "#00000042",
  },
  "& .checkboxAddress": {
    fontSize: "12px",
  },
  "& .MuiIconButton-root": {
    padding: "0 !important",
    width: "20px",
  },
  "& .icon": {
    borderRadius: 6,
    width: 20,
    height: 20,
    marginLeft: "2px",
    boxShadow: "inset 0 0 0 1px #64748B, inset 0 -1px 0 #64748B",
    backgroundColor: "transparent",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  "& .checkedIcon": {
    backgroundColor: "unset",
    boxShadow: "unset",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      backgroundImage: `url(${Group})`,
      height: 20,
      width: 20,
      content: '""',
      borderRadius: "50%",
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  "& .cleartext": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "left",
    borderBottom: "1px solid black",
    cursor: "pointer",
  },
  "& .sortingtext": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "left",
  },
  "& .mainsorting": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  "& .clearButton": {
    color: "#000",
    border: "1px solid #000",
    fontSize: "12px",
    height: "40px",
    fontFamily: "Montserrat",
    width: "79px",
    lineHeight: "normal",
    textTransform: "initial",
    background: "#fff",
    padding: "10px 16px",
    fontStyle: "normal",
    borderRadius: "8px",
    fontWeight: 400,
    "&:hover": {
      background: "#fff",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .searchButton": {
    height: "40px",
    width: "79px",
    color: "#ffff",
    border: "none",
    background: "#000",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textTransform: "initial",
    fontStyle: "normal",
    lineHeight: "normal",
    fontWeight: 400,
    borderRadius: "8px",
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .searchButtonContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "24px 0",
  },
  "& .searchText": {
    color: "rgba(0,0, 0, 1)",
    cursor: "pointer",
    fontSize: "12px",
  },
  "& .searchFormFieldContainer": {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    gap: "4px",
  },
  "& .searchFormFieldLabel": {
    marginBottom: "4px",
    color: "#334155",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "& .searchFormInputBoxmargin": {
    "& .MuiInputBase-root": {
      marginBottom: "6px !important",
    },
  },
  "& .searchFormInputBox": {
    border: "none",
    background: "transparent",
    width: "100%",
    fontWeight: 400,
    color: "#64748B",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    fontSize: "14px",
    "@media (min-width:320px) and (max-width:800px)": {
      width: "100%",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& .MuiInputBase-root": {
      marginBottom: "16px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #94A3B8",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #94A3B8",
      },
      "& fieldset": {
        border: "1px solid #94A3B8",
        borderRadius: "8px",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
  },
});
// Customizable Area End
