import React from 'react'
// Customizable Area Start
import { Box, Radio, Typography } from "@material-ui/core";

// Customizable Area End
import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";

export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (
      <>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            borderRight: "1px solid rgba(230, 230, 230, 1)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "8px 12px",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "28px",
                textAlign: "left",
              }}
            >
              SORTING
            </Typography>
            <Typography
              onClick={this.clearAllFilter}
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "28px",
                textAlign: "left",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              data-test-id={"clear"}
            >
              clear
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "8px",
              margin: "8px 12px",
              flexDirection: "column",
              borderBottom: "1px solid rgb(230, 230, 230)",
              paddingBottom: "8px",
            }}
          >
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              MANUFACTURER
            </Box>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {this.handleCategories().manufactureToDisplay.length > 0 &&
                this.handleCategories().manufactureToDisplay.map((element) => {
                  return (
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <Radio
                        checked={
                          this.state.search.manufacturer_name.name ===
                          element.attributes.name
                        }
                        onChange={(event) =>
                          this.handleRadioChange(event, element.attributes.id)
                        }
                        value={element.attributes.name}
                        color="default"
                        name="manufacturer_name"
                        inputProps={{
                          "aria-label": element.attributes.name.toUpperCase(),
                        }}
                        style={{ padding: 0 }}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          textAlign: "left",
                          alignSelf: "center",
                        }}
                      >
                        {element.attributes.name}
                      </Typography>
                    </Box>
                  );
                })}
              {this.state.manufaturerArray.length > 5 && (
                <div
                  data-test-id={`manufacturerTestID`}
                  onClick={this.handleViewAllManufacturer}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      textAlign: "left",
                      borderBottom: "1px solid rgba(15, 23, 42, 1)",
                      cursor: "pointer",
                    }}
                    data-test-id={`manufacturerInnerTestID`}
                  >
                    {this.state.showAllManucaturer ? "View Less" : "View All"}
                  </span>
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "8px",
              margin: "8px 12px",
              flexDirection: "column",
              borderBottom: "1px solid rgb(230, 230, 230)",
              paddingBottom: "8px",
            }}
          >
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              CATEGORIES
            </Box>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {this.handleCategories().categoriesToDisplay.length > 0 &&
                this.handleCategories().categoriesToDisplay.map(
                  (element, index) => {
                    return (
                      <Box style={{ display: "flex", gap: "8px" }}>
                        <Radio
                          data-test-id={`RadioTestID_${index}`}
                          checked={
                            this.state.search.category_name.name ===
                            element.attributes.name
                          }
                          onChange={(event) =>
                            this.handleRadioChange(event, element.attributes.id)
                          }
                          value={element.attributes.name}
                          color="default"
                          name="category_name"
                          inputProps={{
                            "aria-label": element.attributes.name.toUpperCase(),
                          }}
                          style={{ padding: 0 }}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left",
                            alignSelf: "center",
                          }}
                        >
                          {element.attributes.name}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              {this.state.categoriesArray.length > 5 && (
                <div
                  data-test-id={`categoryTestID`}
                  onClick={this.handleViewAllCategories}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      textAlign: "left",
                      borderBottom: "1px solid rgba(15, 23, 42, 1)",
                      cursor: "pointer",
                    }}
                    data-test-id={`categoryInnerTestID`}
                  >
                    {this.state.showAllCategories ? "View Less" : "View All"}
                  </span>
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "8px",
              margin: "8px 12px",
              flexDirection: "column",
              borderBottom: "1px solid rgb(230, 230, 230)",
              paddingBottom: "8px",
            }}
          >
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              SUB CATEGORIES
            </Box>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {this.handleCategories().subCategoriesToDisplay.length > 0 &&
                this.handleCategories().subCategoriesToDisplay.map(
                  (element, index) => {
                    return (
                      <Box style={{ display: "flex", gap: "8px" }}>
                        <Radio
                          data-test-id={`RadioTestID_${index}`}
                          checked={
                            this.state.search.subcategory_name.name ===
                            element.attributes.name
                          }
                          onChange={(event) =>
                            this.handleRadioChange(event, element.attributes.id)
                          }
                          value={element.attributes.name}
                          color="default"
                          name="subcategory_name"
                          inputProps={{
                            "aria-label": element.attributes.name.toUpperCase(),
                          }}
                          style={{ padding: 0 }}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left",
                            alignSelf: "center",
                          }}
                        >
                          {element.attributes.name}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              {this.state.subCategoriesArray.length > 5 && (
                <div
                  data-test-id={`subcategoryTestID`}
                  onClick={this.handleViewAllSubCategories}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      textAlign: "left",
                      borderBottom: "1px solid rgba(15, 23, 42, 1)",
                      cursor: "pointer",
                    }}
                    data-test-id={`subcategoryInnerTestID`}
                  >
                    {this.state.showAllSubCategories ? "View Less" : "View All"}
                  </span>
                </div>
              )}
            </Box>
          </Box>   
          <Box
            style={{
              display: "flex",
              gap: "8px",
              margin: "8px 12px",
              flexDirection: "column",
              borderBottom: "1px solid rgb(230, 230, 230)",
              paddingBottom: "8px",
            }}
          >
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              FAMILY
            </Box>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {this.handleCategories().familyToDisplay.length > 0 &&
                this.handleCategories().familyToDisplay.map((element) => {
                  return (
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <Radio
                        checked={
                          this.state.search.family_name.name ===
                          element.attributes.name
                        }
                        onChange={(event) =>
                          this.handleRadioChange(event, element.attributes.id)
                        }
                        value={element.attributes.name}
                        color="default"
                        name="family_name"
                        inputProps={{
                          "aria-label": element.attributes.name.toUpperCase(),
                        }}
                        style={{ padding: 0 }}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          textAlign: "left",
                          alignSelf: "center",
                        }}
                      >
                        {element.attributes.name}
                      </Typography>
                    </Box>
                  );
                })}
              {this.state.familyArray.length > 5 && (
                <div
                  data-test-id={`familyTestID`}
                  onClick={this.handleViewAllFamily}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      textAlign: "left",
                      borderBottom: "1px solid rgba(15, 23, 42, 1)",
                      cursor: "pointer",
                    }}
                    data-test-id={`familyInnerTestID`}
                  >
                    {this.state.showAllFamily ? "View Less" : "View All"}
                  </span>
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "8px",
              margin: "8px 12px",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              SECTOR
            </Box>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {this.handleCategories().sectorToDisplay.length > 0 &&
                this.handleCategories().sectorToDisplay.map((element) => {
                  return (
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <Radio
                        checked={
                          this.state.search.sector_name.name ===
                          element.attributes.name
                        }
                        onChange={(event) =>
                          this.handleRadioChange(event, element.attributes.id)
                        }
                        value={element.attributes.name}
                        color="default"
                        name="sector_name"
                        inputProps={{
                          "aria-label": element.attributes.name.toUpperCase(),
                        }}
                        style={{ padding: 0 }}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          textAlign: "left",
                          alignSelf: "center",
                        }}
                      >
                        {element.attributes.name}
                      </Typography>
                    </Box>
                  );
                })}
              {this.state.sectorArray.length > 5 && (
                <div
                  data-test-id={`sectorTestID`}
                  onClick={this.handleViewAllSector}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      textAlign: "left",
                      borderBottom: "1px solid rgba(15, 23, 42, 1)",
                      cursor: "pointer",
                    }}
                    data-test-id={`sectorInnerTestID`}
                  >
                    {this.state.showAllSector ? "View Less" : "View All"}
                  </span>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
