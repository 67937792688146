import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  Box,
  Typography,
  styled,
  TextField,
  Switch,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CustomisableUserProfilesSettingsController, { Props } from "./CustomisableUserProfilesSettingsController.web";
import { Singuptextborder, backgroundImage } from "../../../blocks/email-account-registration/src/assets";
import { checkmark } from "../../../blocks/forgot-password/src/assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import { Visibility, VisibilityOff } from '@material-ui/icons';
// Customizable Area End

export default class CustomisableUserProfilesSettings extends CustomisableUserProfilesSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderProfileSettingCurrentPasswordInput = () => {
    return (
      <Box className="ProfileSettingFormFieldContainer">
      <Typography className="ProfileSettingFormFieldLabel">Current Password</Typography>
        <TextField
          helperText={this.getSettingCurrentPasswordErrorMessage()}
          placeholder="Password"
          error={this.state.currentPasswordMatchError || this.state.blankCurrentPasswordError}
          onChange={this.handlePasswordInput}
          className={this.state.currentPasswordMatchError || this.state.blankCurrentPasswordError ? "profileSettingFormInputBoxError" : "profileSettingFormInputBox"}
          id="outlined-basic"
          value={this.state.currentPassword}
          data-test-id="currentPassInputTestID"
          variant="outlined"
          name="currentPassword"
          type={this.state.showPasswordCurrentSettings ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordCurrent}
                >
                  {this.state.showPasswordCurrentSettings ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
    </Box>
    )
  }

  renderProfileSettingUserPasswordInput = () => {
    return (
      <Box className="ProfileSettingFormFieldContainer">
        <Typography className="ProfileSettingFormFieldLabel">New Password</Typography>
        <TextField
          placeholder="Password"
          error={this.state.passwordError || this.state.blankPasswordError}
          className={this.state.passwordError || this.state.blankPasswordError ? "profileSettingFormInputBoxError" : "profileSettingFormInputBox"}
          helperText={this.getSettingPasswordErrorMessage()}
          value={this.state.password}
          id="outlined-basic"
          variant="outlined"
          data-test-id="newPassInputTestID"
          onChange={this.handlePasswordInput}
          name="password"
          type={this.state.showPasswordSettings ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordSettings}
                >
                  {this.state.showPasswordSettings ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }

  renderProfileSettingNewPasswordInput = () => {
    return (
      <Box className="ProfileSettingFormFieldContainer">
        <Typography className="ProfileSettingFormFieldLabel">Confirm Password</Typography>
        <TextField
          variant="outlined"
          helperText={this.getSettingPasswordNewErrorMessage()}
          placeholder="Password"
          error={this.state.newPasswordMatchError || this.state.blankNewPasswordError}
          className={this.state.newPasswordMatchError || this.state.blankNewPasswordError ? "profileSettingFormInputBoxError" : "profileSettingFormInputBox"}
          id="outlined-basic"
          value={this.state.newPassword}
          data-test-id="confirmPassInputTestID"
          onChange={this.handlePasswordInput}
          name="newPassword"
          type={this.state.showPasswordConfirmSettings ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordSettingsConfirm}
                >
                  {this.state.showPasswordConfirmSettings ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <div className="mainContainer">
        <HeaderNew navigation={this.props.navigation} id={""}/>
        </div>
        <MainUserProfileContainer container spacing={8}>
          <Grid item xs={12} sm={6} md={5} lg={7} className="mainleftGrid" >
            <Box className="profileTextHeaderMain">
              <Box className="profileTextHeader">
                <Typography className="profileUpText">
                  Settings
                </Typography>
                <img src={Singuptextborder} alt="logintextborder" className="profiletextborder" />
              </Box>
            </Box>
            <Box className="profileMainInputBox">
              <Box className="sideborder">
                <Typography>Email Notifications</Typography>
                <Switch
                  data-test-id="emailCheckInputTestID"
                  checked={this.state.notificationCheck}
                  onChange={this.handleChange}
                  name="checkedA"
                  className="notificationCheck"
                />
              </Box>
              <form data-test-id="formSubmitTestID" autoComplete="off" className="login_Form_Main_Container" onSubmit={this.handleSubmitEditableChanges} >
                <Box className="inputDownMain">
                  <Grid item container >
                    <Grid item lg={12} xs={12} className="girdeset">
                      {this.renderProfileSettingCurrentPasswordInput()}
                      {this.renderProfileSettingUserPasswordInput()}
                      {this.renderProfileSettingNewPasswordInput()}
                    </Grid>
                  </Grid>
                </Box>
                <Box className="savebtnmain">
                  <Button className="profileSaveButton" type="submit">Save</Button>
                </Box>
              </form>
            </Box>
          </Grid>
          <Grid item xs={false} sm={6} md={7} lg={5}  >
            <Box className="image_Container_Box"> </Box>
          </Grid>
          {this.state.profileSettingSuccessModal &&
            <SignUpSuccessModel
              data-test-id="successModalTestID"
              loginBtnClick={() => this.handleNavigateFromProfile("LandingPage")}
              image={checkmark} heading={"Settings has been saved"}
              description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br/>dolor sit amet, consectetur adipiscing elit"}
              showBtn={true}
              btnText={"Go to Home"}
            />
          }
          <SnackBarAlert
            data-test-id="snackBarTestID"
            snackBarProps={this.state.hasSuccessErrorForProfileSettings}
            handleCloseSnack={this.handleProfileSettingCloseSnackbar}
          />
        </MainUserProfileContainer>
        <Footer />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainUserProfileContainer = styled(Grid)({
  width: "100%",
  "& .notificationCheck": {
    width: "54px",
    height: "39px",
    "& .MuiSwitch-thumb": {
      width: "10px",
      height: "10px",
    },
    "& .MuiSwitch-switchBase": {
      padding: "13px !important",
      top: "1px",
      left: "2px",
    },
    "& .MuiSwitch-track": {
      borderRadius:"8px"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#fff",
      top: "1px !important",
      left: "-3px !important",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#000",
      opacity: "1"
    },
  },
  "& .mainContainer": {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
  "& .profileSaveButton": {
    height: "52px",
    width: "241px",
    border: "none",
    color: "#ffff",
    background: "#000",
    fontFamily: "Montserrat",
    fontSize: "16px",
    textTransform: "initial",
    fontStyle: "normal",
    lineHeight: "normal",
    fontWeight: 400,
    borderRadius: "8px",
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .savebtnmain": {
    width: "100%",
    marginTop: "20px",
    justifyContent: "start",
    display: "flex",
  },
  "& .ProfileSettingFormFieldContainer": {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  "& .ProfileSettingFormFieldLabel": {
    marginBottom: "4px",
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "22px",
    opacity: 0.5,
    fontWeight: 400,
  },
  "& .girdeset": {
    paddingRight: "30px",
    "@media (min-width:320px) and (max-width:800px)": {
      paddingRight: "0px"
    },
  },
  "& .profileSettingFormInputBox": {
    border: "none",
    background: "transparent",
    width: "320px",
    color: "#64748B",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    fontWeight: 400,
    fontSize: "14px",
    "@media (min-width:320px) and (max-width:800px)": {
      width: "100%",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& .MuiInputBase-root": {
      marginBottom: "20px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        border: "1px solid black",
        borderRadius: "8px",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
  },
  "& .profileSettingFormInputBoxError": {
    width: "320px",
    border: "none",
    background: "transparent",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    color: "#64748B",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      border: "none",
      width: "fit-content",
      fontSize: "12px",
      fontFamily: "sans-serif",
      fontWeight: 400,
      borderRadius: "6px",
      position: "relative",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .profileTextHeaderMain": {
    padding: "50px 8% 53px",
  },
  "& .inputDownMain": {
    paddingBottom: "10px",
    "@media (min-width:320px) and (max-width:600px)": {
      paddingBottom: "20px",
    }
  },
  "& .profileMainInputBox": {
    padding: "0px 8% 150px",
    borderRight: "1px solid #F2F2F2",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 8% 0px",
    }
  },
  "& .sideborder": {
    gap: "30px",
    display: "flex",
    paddingBottom: "30px",
    alignItems:"center",
    width: "100%",
    justifyContent: "space-between",
    "@media (min-width:320px) and (max-width:1000px)": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    }
  },
  "& .profileTextHeader": {
    position: "relative",
  },
  "& .profileUpText": {
    fontSize: "40px",
    fontWeight: 700,
    zIndex: 99,
    fontStyle: "normal",
    position: "relative",
    color: "#000",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "20px !important",
    }
  },
  "& .profiletextborder": {
    left: "-23px",
    position: "absolute",
    width: "99.167px",
    top: "0px",
    height: "35px",
    "@media (min-width:320px) and (max-width:575px)": {
      top: "2px",
      height: "18px",
      left: "-23px",
      width: "62.167px",
    }
  },
  "& .image_Container_Box": {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    borderRadius: "20px",
    height: "745px",
    margin: "163px 0 0 0",
    background: `url(${backgroundImage})`,
  },
  "& .MuiGrid-item": {
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px"
    }
  },
  "@media (min-width:320px) and (max-width:575px)": {
    margin: "0px !important"
  }
});
// Customizable Area End