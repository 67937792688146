import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
import StorageProvider from "../../../framework/src/StorageProvider";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";
export interface ValidResponseType {
  data: OrderData & InvoiceData;
  meta: {
    message: string
  }
  message: string;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface InvalidResponseTypePost {
  errors: string;
}

export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}
export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
      | (object & {
        attributes?: object & {
          name?: string;
        };
      })
      | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

interface Order {
  status: string | undefined;
  total_items: number | undefined;
  sub_total: number;
  applied_discount: number;
  total_tax: number | undefined;
  total_fees: number | undefined;
  address_id: number;
  promo_code_id: number | null;
  package_and_freight: number;
}

export interface ShoppingCartOrderItemWeb {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    total_price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    total_gst: number;
    other_charges?: number;
    catalogue: {
      data: {
        id: string;
        attributes: {
          name: string;
          stock_qty: number;
          price: number;
          part_number: string;
          images: Array<{ url: string }>; // Define images as an array of objects with the url property
        };
      };
    };
  };
}
export interface OrderAttributes {
  status: string;
  total_items: number;
  sub_total: number;
  applied_discount: number;
  total_tax: number;
  total_fees: number;
  address_id: number;
  promo_code_id: number | null;
  package_and_freight: number;
  order_items: {
    data: ShoppingCartOrderItemWeb[];
  };
}

export interface CustomerAttributes {
  activated: boolean;
  confirm_token: string | null;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  user_type: string;
  type: string;
  gst_number: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  email_notification: boolean;
  full_name: string;
  role_id: string | null;
  photo: string | null;
  role: { id: string | null; name: string | null };
}

export interface AddressAttributes {
  id: number;
  account_id: number;
  address: string;
  name: string;
  flat_no: string | null;
  zip_code: string;
  phone_number: string;
  deleted_at: string | null;
  latitude: number | null;
  longitude: number | null;
  residential: boolean;
  city: string;
  state_code: string | null;
  country_code: string | null;
  state: string;
  country: string;
  address_line_2: string | null;
  address_type: string;
  address_for: string;
  is_default: boolean;
  landmark: string;
  created_at: string;
  updated_at: string;
}

export interface OrderData {
  id: string;
  type: string;
  attributes: OrderAttributes;
  customer: { data: { id: string; type: string; attributes: CustomerAttributes } };
  address: AddressAttributes;
}

interface InvoiceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    invoice_number: string;
    invoice_date: string;
    due_date: string;
    order_id: number;
    order_address: Address;
    order_details: OrderDetails;
    order_items: OrderItem[];
    company_details: {
      data: CompanyDetails;
    };
  };
}

interface Address {
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  locality: string;
  phone_number: string;
}

interface OrderDetails {
  customer_name: string;
  sub_total: number;
  total_tax: number;
  discount_applied: number;
  total_fees: number;
  total_items: number;
  total_freight_and_package: number;
  order_status: string;
}

interface OrderItem {
  order_item_name: string;
  freight_package: number;
  part_number: string;
  hsn: string;
  gst_rate: string;
  quantity: number;
  unit: string;
  price: number;
  total_price: number;
}

interface CompanyDetails {
  id: string;
  type: string;
  attributes: {
    id: number;
    owner_name: string;
    company_name: string;
    gst_number: string;
    pan_card: string;
    address: string;
    email: string;
    full_phone_number: string;
  }
}
// Customizable Area End

interface S {
  // Customizable Area Start
  order_id: number;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: ShoppingCartOrder[];
  orderItems: ShoppingCartOrderItem[];
  isVisible: boolean;
  isRefreshing: boolean;

  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  hasSuccessErrorForCart: HasSuccessErrorType;
  orderPriceDetails: Order
  headerText: number;
  orderData: ShoppingCartOrderItemWeb[];
  couponCode: string;
  orderSubmitDetails: InvoiceData;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId: string = "";
  showOrderApiCallId: string = "";
  createOrderItemApiCallId: string = "";
  deleteOrderItemApiCallId: string = ""
  couponCodeApiCallId: string = "";
  couponCodeRemoveApiCallId: string = "";
  postInvoiceGenerateApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      hasSuccessErrorForCart: {
        isOpen: false,
        isSeverity: "error",
        isMessage: "",
      },
      orderPriceDetails: {
        status: "",
        total_items: 0,
        sub_total: 0,
        applied_discount: 0,
        total_tax: 0,
        total_fees: 0,
        address_id: 0,
        promo_code_id: null,
        package_and_freight: 0
      },
      headerText: 1,
      orderData: [],
      couponCode: "",
      orderSubmitDetails: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          invoice_number: "",
          invoice_date: "",
          due_date: "",
          order_id: 0,
          order_address: {
            name: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            locality: "",
            phone_number: ""
          },
          order_details: {
            customer_name: "",
            sub_total: 0,
            total_tax: 0,
            discount_applied: 0,
            total_fees: 0,
            total_items: 0,
            total_freight_and_package: 0,
            order_status: ""
          },
          order_items: [],
          company_details: {
            data: {
              id: "",
              type: "",
              attributes: {
                id: 0,
                owner_name: "",
                company_name: "",
                gst_number: "",
                pan_card: "",
                address: "",
                email: "",
                full_phone_number: ""
              }
            }
          }
        }
      },
      isLoading: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getOrders()
    const couponCode = await getStorageData("couponCode")
    if (couponCode) {
      this.setState({
        couponCode: couponCode
      })
    }
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseCart(responseJson)) {
        this.apiSuccessCallBacksCart(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseCart(responseJson)) {
        this.apiFailureCallBacksCart(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  isNumberNull(number_value?: number) {
    return (
      number_value === null ||
      Number.isNaN(number_value) ||
      number_value === undefined
    );
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
  };

  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };

  setModal = (item: {
    post: {
      id: number;
      name: string;
      description: string;
      price: number;
      currency: string;
      category_id: number;
      created_at: string;
      updated_at: string;
      account_id: number;
    };
  }) => {
    this.setState({
      id: item.post?.id,
      name: item.post?.name,
      description: item.post?.description,
      price: item.post?.price,
      currency: item.post?.currency,
      category_id: item.post?.category_id,
      created_at: item.post.created_at,
      updated_at: item.post?.updated_at,
      account_id: item.post?.account_id,
      isVisible: !this.state.isVisible,
    });
  };

  getOrders = async () => {
    this.loaderOn()
    let token = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDownload = async () => {
    let token = (await StorageProvider.get("token")) || "";
    let invoiceID = (await StorageProvider.get("invoiceID")) || "";

    const urlIframe = `${configJSONBase.baseURL}/${configJSON.getInvoiceDownloadApiEndPoint}/${invoiceID}`; // URL of the PDF file
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = `${urlIframe}?token=${token}`; // Append token as query parameter if needed
    document.body.appendChild(iframe);
  };

  showOrder = (order_id: S["order_id"]) => {
    this.setState({ isVisible: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint + "/" + `${order_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = async (token: string) => {
    this.loaderOn()
    let tokenApi = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: tokenApi,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteOrderItem = async (
    order_id: string | number,
    order_item_id: string | number
  ) => {
    this.loaderOn()
    let token = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteOrderItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteOrderItemApiEndPoint + order_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  promoCodeApply = async () => {
    this.loaderOn()
    let tokenApi = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: tokenApi,
    };
    const promo_code = {
      name: this.state.couponCode
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.couponCodeApiCallId = requestMessage.messageId;

    const httpBody = {
      promo_code,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponCodeApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  promoCodeRemove = async () => {
    this.loaderOn()
    let tokenApi = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: tokenApi,
    };
    const promo_code = {
      name: this.state.couponCode
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.couponCodeRemoveApiCallId = requestMessage.messageId;

    const httpBody = {
      promo_code,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponCodeRemoveApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  postInvoiceGenerate = async () => {
    this.loaderOn()
    let tokenApi = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: tokenApi,
    };
    const httpBody = {
      order: {
        status: "on_going"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postInvoiceGenerateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postInvoiceGenerateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  setCatalogueId = (catalogue_id?: string) => {
    this.setState({ catalogue_id: Number(catalogue_id) || 0 });
  };

  setQuantity = (quantity?: string) => {
    this.setState({ quantity: Number(quantity) || 0 });
  };

  setTaxable = (taxable?: boolean) => {
    this.setState({ taxable: taxable || false });
  };

  setTaxableValue = (taxable_value?: string) => {
    this.setState({ taxable_value: Number(taxable_value) || 0 });
  };

  setVisible = (isVisible: boolean) => {
    this.setState({ isVisible: isVisible });
  };

  addOrderItem = () => {
    this.setState({ isVisible: false });
    this.navigateToAddShoppingCartOrderItem();
  };

  showOrderItems = (order_id: string | number) => {
    const order_id_num = Number(order_id) || 0;
    this.setState({ order_id: order_id_num });
    this.showOrder(order_id_num);
  };

  handleChangeQuantity = (_event: React.ChangeEvent<{}>, value: string | null, _reason: string, index: number, catalogueId: number | string) => {
    const quantity = value as unknown as string;
    let numericValueString = quantity.match(/\d+/);
    if (numericValueString) {
      let numericValue = parseInt(numericValueString[0]);
      let orderData = this.state.orderData
      orderData[index].attributes.quantity = numericValue
      this.setState({
        orderData: orderData,
        catalogue_id: catalogueId as unknown as number,
        quantity: numericValue
      }, () => {
        this.createOrderItem("");
      })
    }
  }

  isValidResponseCart = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || responseJson.message;
  };

  isInValidResponseCart = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksCart = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.getOrdersApiCallId) {
      this.loaderOff()
      if (responseJson.message === "No items available in the cart") {
        let orderPriceDetailsNoData = {
          status: "",
          total_items: 0,
          sub_total: 0,
          applied_discount: 0,
          total_tax: 0,
          total_fees: 0,
          address_id: 0,
          promo_code_id: null,
          package_and_freight: 0
        }
        this.setState({
          orderData: [],
          orderPriceDetails: orderPriceDetailsNoData,
          couponCode: ""
        })
      }
      let orderPriceDetails = {
        status: responseJson.data.attributes.status,
        total_items: responseJson.data.attributes.total_items,
        sub_total: responseJson.data.attributes.sub_total,
        applied_discount: responseJson.data.attributes.applied_discount,
        total_tax: responseJson.data.attributes.total_tax,
        total_fees: responseJson.data.attributes.total_fees,
        address_id: responseJson.data.attributes.address_id,
        promo_code_id: responseJson.data.attributes.promo_code_id,
        package_and_freight: responseJson.data.attributes.package_and_freight

      }
      this.setState({
        orderData: responseJson.data.attributes.order_items.data,
        orderPriceDetails: orderPriceDetails
      });
    }
    if (apiRequestCallId === this.deleteOrderItemApiCallId) {
      this.loaderOff()
      this.snackBarShowCart(true, "success", responseJson.message);
      removeStorageData("couponCode");
      this.setState({
        couponCode: ""
      })
      this.getOrders();
    }
    if (apiRequestCallId === this.createOrderItemApiCallId) {
      this.loaderOff()
      this.getOrders();
    }
    if (apiRequestCallId === this.couponCodeApiCallId) {
      this.loaderOff()
      this.couponCodeSuccess(responseJson)
    }
    if (apiRequestCallId === this.couponCodeRemoveApiCallId) {
      this.loaderOff()
      removeStorageData("couponCode");
      this.setState({
        couponCode: ""
      })
      this.snackBarShowCart(true, "success", responseJson.meta.message);
      this.getOrders();
    }
    if (apiRequestCallId === this.postInvoiceGenerateApiCallId) {
      this.loaderOff()
      setStorageData("invoiceID", responseJson.data.id);
      removeStorageData("couponCode");
      this.setState({
        couponCode: ""
      })
      this.setState({
        headerText: 4,
        orderSubmitDetails: responseJson.data
      })
    }
  };

  couponCodeSuccess = (responseJson: ValidResponseType) => {
    if (responseJson.meta.message === "Promo Code Applied") {
      this.getOrders();
      setStorageData("couponCode", this.state.couponCode);
      this.snackBarShowCart(true, "success", responseJson.meta.message);
    } else {
      removeStorageData("couponCode");
      this.setState({
        couponCode: ""
      })
      this.snackBarShowCart(true, "error", responseJson.meta.message);
      this.getOrders();
    }
  }

  apiFailureCallBacksCart = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType & InvalidResponseTypePost
  ) => {
    this.loaderOff()
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.handleNavigateFromCart("login");
    }
    if (apiRequestCallId === this.postInvoiceGenerateApiCallId) {
      this.snackBarShowCart(true, "error", responseJson.errors);
    }
  };

  handleNavigateFromCart = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  snackBarShowCart = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForCart: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };

  handleCartCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForCart: {
        isSeverity: "error",
        isOpen: false,
        isMessage: "",
      },
    });
  };

  handleNextPage = (btnClick: number | null) => {
    if (this.state.headerText === 4) {
      return;
    }
    if (this.state.headerText === 3 && btnClick === 0) {
      this.postInvoiceGenerate();
      return;
    }
    if (btnClick) {
      this.setState({
        headerText: btnClick
      })
    } else {
      this.setState({
        headerText: this.state.headerText + 1
      })
    }
  };

  handleCouponInput = (couponCode: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      couponCode: couponCode.target.value.trim()
    });
  };

  generateOptions = (quantity: number) => {
    return Array.from({ length: quantity }, (_item, index) => `${index + 1}`);
  };

  loaderOn = () => {
    this.setState({
      isLoading: true
    })
  }

  loaderOff = () => {
    this.setState({
      isLoading: false
    })
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dayNum = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${dayNum} ${month} ${year}`;
  }

  formatNumberWithLeadingZero(number: number | undefined) {
    return String(number).padStart(2, '0');
  }

  numberWithCommas = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2, // Ensure two decimal places
      maximumFractionDigits: 2 // Ensure two decimal places
    });
  };
  // Customizable Area End
}
