Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'bx_block_shopping_cart/cart_details'
exports.createOrderItemApiEndPoint = 'bx_block_shopping_cart/add_to_cart'
exports.deleteOrderItemApiEndPoint = 'bx_block_shopping_cart/remove_from_cart/'
exports.couponCodeApiEndPoint = 'promo_code/add'
exports.couponCodeRemoveApiEndPoint = 'promo_code/remove'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.postInvoiceGenerateApiEndPoint = 'bx_block_invoicebilling/generate_invoice'
exports.getInvoiceDownloadApiEndPoint = 'bx_block_invoicebilling/download_invoice/'
// Customizable Area End
