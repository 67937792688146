Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getLandingPageApiEndPoint="bx_block_landingpage3/landing_page_items";
exports.getBrandEndPoint="bx_block_catalogue/brands";
exports.getTermsAndConditionsApiEndPoint="terms_and_condition";
exports.serviceRequestEndPoint="service_request";
exports.btnExampleTitle = "CLICK ME";
exports.getUserProfileEndPoint = "account_block/accounts/";
exports.firstNameError = "Please Enter First Name";
exports.lastNameError = "Please Enter Last Name";
exports.emailError = "Please Enter Correct Email";
exports.phoneError = "Please Enter Correct Phone Number";
exports.address1Error = "Please Enter Address Line 1";
exports.address2Error = "Please Enter Address Line 2";
exports.cityError = "Please Enter Your City";
exports.stateError = "Please Enter Your State";
exports.pincodeError = "Please Enter Your Local PinCode";
exports.equipmentTypeError = "Please Select Equipment Type";
exports.productApiContentType = "application/json";
exports.productAPiEndPoint = "bx_block_catalogue/catalogue";
exports.getSearchAPiEndPoint = "bx_block_advanced_search/search";
exports.postAddToWishlistItemApiEndPoint = 'bx_block_categories/add_to_wishlist'
// Customizable Area End
