// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  styled
} from "@material-ui/core";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
export const configJSON = require("../../../framework/src/config");
import CircularProgress from '@material-ui/core/CircularProgress';
import { check, cross, wishlistEmpty } from "./assets"
// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// let artBoardHeightOrg = 667;
// let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
import WishlistController, {
  Props,
} from "./WishlistController";

export default class Wishlistweb extends WishlistController {
  constructor(props: Props) {
    super(props);

  }

  renderWishlist = () => {
    return (
      <>
        {this.state.wishListData.map((wishlistItem, index) => {
          const { name, part_number, images, catalogue_id } = wishlistItem.attributes.catalogues
          return (
            <Grid item xs={12} sm={6} md={3} className="wishlistBoxMain" key={index} data-test-id="wishlistInnerTestID">
              <img src={cross} alt="" className="crossImg" onClick={() => this.deleteWishlistItem(catalogue_id)}
                data-test-id={`cancelIconTestID${index}`}
              />
              <img src={images[0]?.url ? images[0]?.url : check} alt="" className="cartImage" />
              <Box className="wislistCartMain">
                <Typography className="wishlistInnerHeading">
                  {part_number}
                </Typography>
                <Typography className="wishlistInnerHeading wishlistInnerHeadingGrey">
                  {name}
                </Typography>
                <Button
                  data-test-id={`moveToCartTestID${index}`}
                  onClick={() => this.addWishlistToCart(catalogue_id)}
                  className="wislistButton movetoCart"
                >
                  Move To Cart
                </Button>
              </Box>
            </Grid>
          )
        })}
      </>
    )
  }

  renderEmptyWishlist = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} className="emptyMainGrid" data-test-id="noDataBoxTestID">
          <Box className="emptyMainBox">
            <img src={wishlistEmpty} alt="" className="wishlistEmpty" />
            <Typography className="wishlistHeadding emptyHeadigBlod">
              Your wishlist is Empty!
            </Typography>
            <Typography className="emptyText">
              Add items that you like to your wishlist.
            </Typography>
            <Button
              data-test-id="nextBtnTestID"
              onClick={() => { this.handleNavigateFromWishlist("Catalogue") }}
              className="wislistButton"
            >
              Continue Shopping
            </Button>
          </Box>
        </Grid>
      </>
    )
  }

  render() {
    // Merge Engine - render - Start
    return (
      <>
        {this.state.isLoading ?
          <CircularProgressBox >
            <CircularProgress className="loader" />
          </CircularProgressBox>
          :
          <>
            <div className="mainContainer">
              <HeaderNew navigation={this.props.navigation} id={""} />
            </div>
            <WishlistTextHeaderMain>
              {this.state.wishListData.length > 0 && <Typography className="wishlistHeadding">
                My Wishlist: <span className="itemsLength">{this.state.wishListData.length} Items</span>
              </Typography>}
              <MainWIshlistContainer container spacing={3}>
                <>
                  {this.state.wishListData.length !== 0 && this.renderWishlist()}
                  {this.state.wishListData.length === 0 && this.renderEmptyWishlist()}
                </>
              </MainWIshlistContainer>
            </WishlistTextHeaderMain>
            <Footer />
          </>
        }
        <SnackBarAlert
          data-test-id="snackBarTestID"
          snackBarProps={this.state.hasSuccessErrorForWishlist}
          handleCloseSnack={this.handleWishlistCloseSnackbar}
        />
      </>
    );
    // Merge Engine - render - End
  }
}

const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});

const WishlistTextHeaderMain = styled(Box)({
  padding: "50px 4% 53px",
  "@media (min-width:320px) and (max-width:575px)": {
    padding: "0px 4% 0px",
  },
  "& .wishlistBoxMain": {
    borderRadius: "8px 8px 0 0",
    position: "relative",
    width: 'calc(100% / 5)',
    flexBasis: 'calc(100% / 5)',
    maxWidth: 'calc(100% / 5)',
    "@media (min-width:300px) and (max-width:400px)": {
      width: '100%',
      flexBasis: '100%',
      maxWidth: '100%',
    },
    "@media (min-width:400px) and (max-width:575px)": {
      width: '50%',
      flexBasis: '50%',
      maxWidth: '50%',
    },
    "@media (min-width:575px) and (max-width:900px)": {
      width: '33.33%',
      flexBasis: '33.33%',
      maxWidth: '33.33%',
    },
    "@media (min-width:900px) and (max-width:1200px)": {
      width: '25%',
      flexBasis: '25%',
      maxWidth: '25%',
    },
  },
  "& .wislistCartMain": {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  },
  "& .wishlistInnerHeading": {
    fontSize: "20px",
    fontWeight: "600",
    color:"#000000"
  },
  "& .wishlistInnerHeadingGrey": {
    fontSize: "16px",
    color:"#666666 !important",
    "@media (min-width:1279px) and (max-width:1800px)": {
      fontSize: "12px",
    },
    "@media (min-width:960px) and (max-width:1149px)": {
      fontSize: "12px",
    },
    "@media (min-width:600px) and (max-width:959px)": {
      fontSize: "12px",
    },
  },
  "& .cartImage": {
    width: "100%",
    borderRadius: "8px 8px 0 0",
    height: "204px"
  },
  "& .crossImg": {
    width: "20px",
    position: "absolute",
    top: "16px",
    right: "16px",
    borderRadius: "50%",
    background: "grey",
    cursor: "pointer",
  },
  "& .wishlistEmpty": {
    width: "200px",
    height: "200px",
    paddingBottom: "20px",
  },
  "& .emptyMainBox": {
    width: "35%",
  },
  "& .emptyMainGrid": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  "& .emptyText": {
    fontSize: "16px"
  },
  "& .emptyHeadigBlod": {
    fontWeight: "bold !important",
    fontSize: "40px !important"
  },
  "& .movetoCart": {
    width: "100% !important"
  },
  "& .itemsLength": {
    fontWeight: "normal !important",
  },
  "& .wislistButton": {
    color: "#000",
    height: "52px",
    fontFamily: "Montserrat",
    width: "216px",
    border: "1px solid #000",
    textTransform: "initial",
    lineHeight: "normal",
    background: "#fff",
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px",
    fontStyle: "normal",
    fontWeight: 400,
    marginTop: "24px",
    "&:hover": {
      background: "#fff",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .wishlistHeadding": {
    fontSize: "16px",
    position: "relative",
    color: "#000",
    fontStyle: "normal",
    zIndex: 99,
    fontWeight: 700,
    paddingBottom: "20px",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
});

const MainWIshlistContainer = styled(Grid)({
  textTransform: "initial",
  height: "100%",
});
// Customizable Area End
