import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
export interface ValidResponseType {
  data: object;
  message: string;
}

export interface InvalidResponseType {
  message: string;
}

export interface GetUserProfileResponseType {
  message: string;
  data: {
    attributes: {
        email_notification: boolean;
    }
  }
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  blankPasswordError: boolean;
  passwordError: boolean;
  password: string;
  newPassword: string;
  blankNewPasswordError: boolean;
  newPasswordMatchError: boolean;
  resetPasswordSuccessModel: boolean;
  resetPasswordToken: string;
  hasSuccessErrorForProfileSettings: HasSuccessErrorType;
  currentPassword: string;
  blankCurrentPasswordError: boolean;
  currentPasswordMatchError: boolean;
  notificationCheck: boolean;
  profileSettingSuccessModal: boolean;
  showPasswordSettings: boolean;
  showPasswordCurrentSettings: boolean;
  showPasswordConfirmSettings: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  putUserProfileSettingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      blankPasswordError: false,
      passwordError: false,
      password: "",
      newPassword: "",
      blankNewPasswordError: false,
      newPasswordMatchError: false,
      resetPasswordSuccessModel: false,
      resetPasswordToken: "",
      hasSuccessErrorForProfileSettings: {
        isOpen: false,
        isSeverity: "error",
        isMessage: ""
      },
      currentPassword: "",
      blankCurrentPasswordError: false,
      currentPasswordMatchError: false,
      notificationCheck: true,
      profileSettingSuccessModal: false,
      showPasswordSettings: false,
      showPasswordCurrentSettings: false,
      showPasswordConfirmSettings: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseProfileSetting(responseJson)) {
        this.apiSuccessCallBacksSetting(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseProfileSetting(responseJson)) {
        this.apiFailureCallBacksSetting(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let emailNotificationCheck = await StorageProvider.get("emailNotificationCheck")
    this.setState({
      notificationCheck:emailNotificationCheck
    })
  }

  isValidResponseProfileSetting = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponseProfileSetting = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.message;
  };

  apiSuccessCallBacksSetting = (apiRequestCallId: string, responseJson: GetUserProfileResponseType) => {
    if (apiRequestCallId === this.putUserProfileSettingApiCallId) {
      StorageProvider.set("emailNotificationCheck",responseJson.data.attributes.email_notification)
      this.setState({
        profileSettingSuccessModal: true
      })
    }

  };

  apiFailureCallBacksSetting = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.putUserProfileSettingApiCallId) {
      this.snackBarErrorApiProfileSetting(responseJson)
    }
  };

  handleProfileSettingCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForProfileSettings: {
        isSeverity: "error",
        isOpen: false,
        isMessage: ""
      }
    });
  };

  snackBarErrorApiProfileSetting = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessErrorForProfileSettings: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    });
  };

  handleNavigateFromProfile = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  getSettingCurrentPasswordErrorMessage() {
    const {currentPasswordMatchError, blankCurrentPasswordError } = this.state;

    if (blankCurrentPasswordError) {
      return "Please enter a current password.";
    } else if (currentPasswordMatchError) {
      return "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character (!@#$&*?<>',[]}{=-)(^%`~+.:;_).";
    }
    
    return null;
  }

  getSettingPasswordErrorMessage() {
    const { blankPasswordError, passwordError } = this.state;

    if (blankPasswordError) {
      return "Please enter a new password.";
    } else if (passwordError) {
      return "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character (!@#$&*?<>',[]}{=-)(^%`~+.:;_).";
    }
    
    return null;
  }

  getSettingPasswordNewErrorMessage() {
    const {newPasswordMatchError, blankNewPasswordError } = this.state;

    if (blankNewPasswordError) {
      return "Please enter a confirm password.";
    }else if (newPasswordMatchError) {
      return "New password and confirmation password do not match."
    }

    return null;
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ notificationCheck: event.target.checked });
  };

  handleClickShowPasswordCurrent = () => {
    this.setState({
      showPasswordCurrentSettings: !this.state.showPasswordCurrentSettings
    })
  };

  handleClickShowPasswordSettings = () => {
    this.setState({
      showPasswordSettings: !this.state.showPasswordSettings
    })
  };

  handleClickShowPasswordSettingsConfirm = () => {
    this.setState({
      showPasswordConfirmSettings: !this.state.showPasswordConfirmSettings
    })
  };

  handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const isValidPassword = passwordRegex.test(value);
    let updates = {};
  
    if (name === 'currentPassword') {
      // Handle current password input
      updates = { 
        currentPassword: value,
        currentPasswordMatchError: !isValidPassword && value !== "",
        blankCurrentPasswordError: value === "",
      };
    } else if (name === 'password') {
      // Handle new password input
      updates = {
        password: value,
        passwordError: !isValidPassword && value !== "",
        blankPasswordError: value === "",
      };
    } else if (name === 'newPassword') {
      // Handle new password confirmation input
      updates = {
        newPassword: value,
        newPasswordMatchError: this.state.password !== value && value !== "",
        blankNewPasswordError: value === "",
      };
    }
    this.setState(updates);
  };
  
  apiCallProfileSettings = async (valueData: {
    method?: string,
    endPoint?: string,
    body?: {},
    type?: string,
    contentType?: string,
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    let token = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSubmitEditableChanges = async (preventEvent: { preventDefault: () => void }) => {
    preventEvent.preventDefault()
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const errorsProfileSetting = {
      blankNewPasswordError: !this.state.newPassword,
      blankPasswordError: !this.state.password,
      blankCurrentPasswordError: !this.state.currentPassword,
      newPasswordMatchError: !passwordRegex.test(this.state.newPassword),
      passwordError: !passwordRegex.test(this.state.password),
      currentPasswordMatchError: !passwordRegex.test(this.state.currentPassword),
    };
    
    this.setState(errorsProfileSetting);
    const hasProfileSettingErrors = Object.values(errorsProfileSetting).some(error => error);
    if (!hasProfileSettingErrors) {
      const formData = {
        data: {
          attributes: {
          current_password: this.state.currentPassword,
          new_password: this.state.newPassword,
          confirm_password: this.state.password,
          email_notification: this.state.notificationCheck
          }
        }
      }
      this.putUserProfileSettingApiCallId = await this.apiCallProfileSettings({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpPutMethod,
        endPoint: configJSON.putUserProfileSettingEndPoint,
        body: formData,
      });
    }

  };
  // Customizable Area End
}