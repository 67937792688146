import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  styled,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Singuptextborder, backgroundImage, logo } from "../../email-account-registration/src/assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { checkmark } from "./assets";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import { Visibility, VisibilityOff } from '@material-ui/icons';
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderResetUserPasswordInput = () => {
    return (
      <Box className="resetFormFieldContainer">
        <Typography className="resetFormFieldLabel">Enter New Password*</Typography>
        <TextField
          error={this.state.passwordError || this.state.blankPasswordError}
          helperText={this.getLoginPasswordErrorMessage()}
          placeholder="Password"
          className={this.state.passwordError || this.state.blankPasswordError ? "resetFormInputBoxError" : "resetFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.password}
          onChange={this.handleResetPasswordInput}
          data-test-id="newPasswordInputTestID"
          type={this.state.showPasswordConfirmReset ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordResetInput}
                >
                  {this.state.showPasswordConfirmReset ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }

  renderResetUserNewPasswordInput = () => {
    return (
      <Box className="resetFormFieldContainer">
        <Typography className="resetFormFieldLabel">Re-enter Password*</Typography>
        <TextField
          error={this.state.newPasswordMatchError || this.state.blankNewPasswordError}
          helperText={this.getLoginPasswordNewErrorMessage()}
          placeholder="Password"
          className={this.state.newPasswordMatchError || this.state.blankNewPasswordError ? "resetFormInputBoxError" : "resetFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.newPassword}
          onChange={this.handleResetNewPasswordInput}
          data-test-id="confrimPasswordInputTestID"
          type={this.state.showPasswordReset ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordReset}
                >
                  {this.state.showPasswordReset ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainResetContainer container>
        <Grid item xs={false} sm={6} md={7} lg={7} className="image_Container_Box" >
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box className="reset_Main_Container">
            <Box className="reset_Logo_Container">
              <Box>
                <img src={logo} alt="logo" className="logo" />
              </Box>
              <Box className="resetTextHeader">
                <Typography className="resetUpText">
                 Reset Password
                </Typography>
                <img src={Singuptextborder} alt="logintextborder" className="resettextborder" />
              </Box>
            </Box>
            <form data-test-id="formSubmitTestID" autoComplete="off" className="reset_form_Main_Container" onSubmit={this.handleReset}>
              {this.renderResetUserPasswordInput()}
              {this.renderResetUserNewPasswordInput()}
              <Box className="btnMainContainerReset" >
                <Button className="resetButtonForgot" type="submit">Reset Password</Button>
              </Box>
            </form>
          </Box>
          {this.state.resetPasswordSuccessModel &&
            <SignUpSuccessModel
              data-test-id="successModalTestID"
              loginBtnClick={() => this.handleNavigateFromReset("login")}
              image={checkmark} heading={"Password Changed Successfully!"}
              description={"You have successfully confirmed your new password. Please, use your new password when logging in."}
              showBtn={true}
            />
          }
          <SnackBarAlert
            data-test-id="snackBarTestID"
            snackBarProps={this.state.hasSuccessErrorForReset}
            handleCloseSnack={this.handleResetCloseSnackbar}
          />
        </Grid>
      </MainResetContainer>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainResetContainer = styled(Grid)({
  width: "100%",
  height: "100%",
  "& .MuiButton-root": {
    textTransform: "initial"
  },
  "& .resetButtonForgot": {
    width: "100%",
    background: "#000",
    border: "none",
    height: "52px",
    color: "#ffff",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "normal",
    padding: "10px 16px",
    borderRadius: "8px",
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    "&:hover": {
      background: "Black",
    },
  },
  "& .btnMainContainerReset": {
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    flexDirection: "column",
    display: "inline-flex",
    justifyContent: "center",
    gap: "24px",
    alignItems: "center",
    height: "52px",
    width: "320px",
  },
  "& .MuiCheckbox-root": {
    width: 20,
    height: 20,
  },
  "& .MuiIconButton-root": {
    padding: "0 !important"
  },
  "& .resetFormInputBox": {
    border: "none",
    color: "#64748B",
    fontWeight: 400,
    background: "transparent",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontSize: "14px",
    height: "44px",
    lineHeight: "24px",
    width: "320px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInputBase-root": {
      marginBottom: "10px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid black",
      },
    },
  },
  "& .resetFormInputBoxError": {
    width: "320px",
    border: "none",
    color: "#64748B",
    fontSize: "14px",
    background: "transparent",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "24px",
    "@media (min-width:320px) and (max-width:820px)": {
      width: "100%",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      width: "fit-content",
      fontFamily: "sans-serif",
      color: "#DC2626",
      border: "none",
      borderRadius: "6px",
      fontSize: "12px",
      fontWeight: 400,
      position: "relative",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #EE3D3D",
      },
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .resetFormFieldContainer": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  },
  "& .reset_Main_Container": {
    flexDirection: "column",
    padding: "180px 20% 180px ",
    gap: "34px",
    display: "flex",
  },
  "& .resetFormFieldLabel": {
    fontSize: "12px",
    color: "#334155",
    fontWeight: 400,
    fontStyle: "normal",
    marginBottom: "4px",
    lineHeight: "22px",
  },
  "& .reset_form_Main_Container": {
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  "& .reset_Logo_Container": {
    display: "flex",
    gap: "54px",
    flexDirection: "column",
  },
  "& .image_Container_Box": {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    background: `url(${backgroundImage})`,
  },
  "& .logo": {
    height: "36px",
    width: "138px",
    flexShrink: 0,
  },
  "& .resetTextHeader": {
    position: "relative",
  },
  "& .resetUpText": {
    fontSize: "48px",
    color: "#000",
    position: "relative",
    zIndex: 99,
    fontWeight: 700,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:820px)": {
      fontSize: "22px",
    },
  },
  "@media(max-width:1300px)": {
    "& .reset_Main_Container": { padding: "8%", }
  },
  "& .resettextborder": {
    position: "absolute",
    top: "10px",
    width: "99.167px",
    left: "-21px",
    height: "35px",
    "@media (min-width:320px) and (max-width:820px)": {
      width: "50px",
      top: "5px",
      position: "absolute",
      left: "-12px",
      height: "20px",
    },
  },
});
// Customizable Area End