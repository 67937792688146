// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';

export const configJSON = require("./config");

const navigation = require("react-navigation");

export interface ValidResponseType {
  data: WishlistItem[];
  meta: {
    message: string;
  };
  message: string;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface InvalidResponseTypePost {
  errors: string;
}

interface Catalogue {
  id: number;
  catalogue_id: number;
  sub_category_id: number;
  brand_id: number | null;
  name: string;
  sku: string | null;
  description: string;
  manufacture_date: string | null;
  length: number;
  breadth: number;
  height: number;
  availability: string;
  stock_qty: number;
  weight: number | null;
  price: string;
  recommended: number | null;
  on_sale: boolean | null;
  sale_price: string | null;
  discount_price: string | null;
  created_at: string;
  updated_at: string;
  block_qty: number | null;
  manufacturer_id: number;
  family_id: number;
  sector_id: number;
  part_number: string;
  materials: string;
  features: string;
  benefits: string;
  warranty_information: string;
  gst: string;
  hsn: string;
  unit: string;
  package_freight_charges: string;
  images: Image[];
}

interface Image {
  id: number;
  url: string;
}

interface WishlistItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    catalogues: Catalogue;
  };
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

export interface Props {
  navigation: typeof navigation;

}

interface S {

  wishlistArray: [];
  selectedItem: string;
  token: string;
  wishlistAllArray: [];
  hasSuccessErrorForWishlist: HasSuccessErrorType;
  wishListData: WishlistItem[];
  isLoading: boolean;
}

interface SS {

}

export default class WishlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  wishlistApiCallId: string = "";
  deleteWishlistApiCallId: string = ""
  moveToCartApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      wishlistArray: [],
      selectedItem: "",
      wishlistAllArray: [],
      token: '',
      hasSuccessErrorForWishlist: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      wishListData: [],
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    let value: string = await StorageProvider.get("token");
    this.setState({
      token: value
    })
    this.getWishlist();
  }

  receive = async (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseWishlist(responseJson)) {
        this.apiSuccessCallBacksWishlist(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseWishlist(responseJson)) {
        this.apiFailureCallBacksWishlist(apiRequestCallId, responseJson);
      }
    }
  };

  isValidResponseWishlist = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.data || responseJson.message);
  };

  isInValidResponseWishlist = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksWishlist = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.wishlistApiCallId) {
      this.loaderOff();
      if (responseJson.message === "Your wishlist is empty!") {
        this.setState({
          wishListData: []
        });
      } else {
        this.setState({
          wishListData: responseJson.data
        });
      }
    }
    if (apiRequestCallId === this.deleteWishlistApiCallId) {
      this.loaderOff();
      this.snackBarShowWishlist(true, "success", responseJson.message);
      this.getWishlist();
    }
    if (apiRequestCallId === this.moveToCartApiCallId) {
      this.loaderOff();
      this.handleNavigateFromWishlist("Cart");
    }
  };

  apiFailureCallBacksWishlist = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType & InvalidResponseTypePost
  ) => {
    this.loaderOff();
    if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
      this.errorsInvalidToken(responseJson)
    } else {
      if (apiRequestCallId === this.wishlistApiCallId) {
        this.snackBarShowWishlist(true, "error", responseJson.errors);
        this.setState({
          wishListData: []
        });
      }
      if (apiRequestCallId === this.deleteWishlistApiCallId) {
        this.snackBarShowWishlist(true, "error", responseJson.errors);
      }
      if (apiRequestCallId === this.moveToCartApiCallId) {
        this.snackBarShowWishlist(true, "error", responseJson.errors);
      }
    }
  };

  errorsInvalidToken = (responseJson: InvalidResponseType) => {
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.handleNavigateFromWishlist("login");
    }
  }

  getWishlist = () => {
    this.loaderOn()
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWishlistApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteWishlistItem = async (
    wishlistId: string | number,
  ) => {
    this.loaderOn()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      catalogue_id: wishlistId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteWishlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteWIshlistItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addWishlistToCart = async (
    wishlistId: string | number,
  ) => {
    this.loaderOn()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      order_items: {
        catalogue_id: wishlistId
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAddToCartItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateFromWishlist = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  loaderOn = () => {
    this.setState({
      isLoading: true
    })
  }

  loaderOff = () => {
    this.setState({
      isLoading: false
    })
  }

  snackBarShowWishlist = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForWishlist: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };

  handleWishlistCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForWishlist: {
        isSeverity: "error",
        isOpen: false,
        isMessage: "",
      },
    });
  };

}
// Customizable Area End
