import React from "react";
// Customizable Area Start
import {
    Box,
  Typography,
} from "@material-ui/core";


import HeaderNew from "../../landingpage/src/HeaderNew.web";
import NewsController, { Props,NewsInterface } from "./NewsController";
import { backArrow } from "./assets";

// Customizable Area End
export default class News extends NewsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderNew navigation={this.props.navigation} id={""}/>
          {this.state.openNews?
          <>
            <Box className="sidebar-box" style={{maxWidth:"276px", width: '100%',display:"flex",flexDirection:"column",gap:"11px",paddingLeft:"38.5px",marginTop:"30px"}}>
              <Box style={{display:"flex",margin:"2px 0px",gap:"8px"}}>
                <img style={{cursor:"pointer"}} src={backArrow} data-test-id="TestGoBack" onClick={this.goBack} />
                <Typography className="sidebar-text" style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                }}>
                  <span style={{color:"rgba(128, 128, 128, 1)"}}>Back to Home /</span><span style={{color:"rgba(0,0, 0, 1)"}}> News</span>
                </Typography>
              </Box>
            </Box>
            <Box style={{width:"1056",height:350,maxWidth:1056,minHeight:350,marginInline:"auto",marginTop:42}}>
                <img style={{objectFit:"fill",width:"100%",height:"100%",borderRadius:16}} src={this.state.particularNewsContent.image} alt="noImage" />
            </Box>
            <Box style={{display:"flex",flexDirection:"column",gap:24,padding:"48px 40px 70px 71px"}}>
                <Typography style={{
                  fontSize: '32px',
                  fontWeight: 500,
                  lineHeight: '22px',
                  textAlign: 'left'
                }}>{this.state.particularNewsContent.title}</Typography>
                <Typography style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  textAlign: 'left'
                  }}>{this.state.particularNewsContent.description}</Typography>
            </Box>                                      
          </>:
          <Box style={{padding:"30px 48px 35px 40px",display:"flex",flexDirection:"column",gap:29}}>
            {this.state.news1.map((item:NewsInterface)=>{              
              return(
                <>
                  <Box style={{display:"flex",gap:35,alignItems:"center"}}>
                      <Box style={{display:"flex",flexDirection:"column",gap:44,flex:1}}>
                          <Typography style={{
                            fontSize: '32px',
                            fontWeight: 700,
                            textAlign: 'left'
                          }}>{item.attributes.title}</Typography>
                          <div style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            textAlign: 'left'
                          }} dangerouslySetInnerHTML={{ __html: item.attributes.description }}/>
                      </Box>
                      <Box style={{maxWidth:662,flex:1,width:"100%"}}>
                        <img style={{borderRadius:16,width:"100%"}} src={item.attributes.image} alt=""  />
                      </Box>
                  </Box>
                </>
              )
            })}
            <Box style={{display:"flex",gap:32,flexDirection:"column",maxWidth:860}}>
                    <Box>
                      <Typography style={{
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '22px',
                        textAlign: 'left'
                      }}>Recent News</Typography>
                    </Box>
            {this.state.NewsContent.map((item:NewsInterface)=>{
              return(
                <>
                  <Box style={{display:"flex",gap:30}}>
                    <Box style={{maxWidth:164,maxHeight:164,width:"100%"}}>
                      <img style={{borderRadius:8,objectFit:"fill",width:"100%",aspectRatio:"1/1"}} src={item.attributes.image} alt="" />
                    </Box>
                    <Box style={{marginBlock:"21.5px",display:"flex",flexDirection:"column"}}>
                      <Typography style={{
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '22px',
                        textAlign: 'left'
                      }}>{item.attributes.title}</Typography>
                      <Typography style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        textAlign: 'left',
                        marginTop:25
                        }}>{item.attributes.subtitle}
                      </Typography>
                      <Typography style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        textAlign: 'left'
                        }}>{item.attributes.description.slice(0,150)}...
                         
                      <span 
                          data-test-id="readMore"
                          onClick={()=>this.openNews(item.attributes.id)} style={{
                          fontWeight:600,color:"#E8B846",textDecoration:"underline",cursor:"pointer"
                          }}>Read More</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              )
            })}
            </Box>
          </Box>
          }
          
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
  const styles = {
    
  };
// Customizable Area End