import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  styled,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Singuptextborder, backgroundImage, logo } from "../../email-account-registration/src/assets";
import clsx from 'clsx';
import { Group } from "./assets";
import SignUpAsModelWeb from "../../../components/src/SignUpAsModel.web";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import { Visibility, VisibilityOff } from '@material-ui/icons';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  renderLoginUserEmailInput = () => {
    return (
      <Box className="loginFormFieldContainer">
        <Typography className="loginFormFieldLabel">Email Id*</Typography>
        <TextField
          error={this.state.emailError || this.state.blankEmailError} helperText={
            this.state.emailError || this.state.blankEmailError
              ? this.state.emailErrorMsg
              : ""
          }
          placeholder="Email"
          className={this.state.emailError || this.state.blankEmailError ? "loginFormInputBoxError" : "loginFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleLoginUserEmailInput}
          data-test-id="emailInputTestID"
        />
      </Box>
    )
  }

  renderLoginUserPasswordInput = () => {
    return (
      <Box className="loginFormFieldContainer">
        <Typography className="loginFormFieldLabel">Password*</Typography>
        <TextField
          error={this.state.passwordError || this.state.blankPasswordError}
          helperText={this.getLoginPasswordErrorMessage()}
          placeholder="Password"
          className={this.state.passwordError || this.state.blankPasswordError ? "loginFormInputBoxError" : "loginFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.password}
          onChange={this.handleLoginPasswordInput}
          data-test-id="passwordInputTestID"
          type={this.state.showPasswordLogin ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="passwordInputEyeIconTestID"
                  onClick={this.handleClickShowPasswordLogin}
                >
                  {this.state.showPasswordLogin ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <MainLoginContainer container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box className="signUp_Main_Container">
            <Box className="login_Logo_Container">
              <Box>
                <img src={logo} alt="logo" className="logo" />
              </Box>
              <Box className="loginTextHeader">
                <Typography className="loginUpText">
                  Login
                </Typography>
                <img src={Singuptextborder} alt="logintextborder" className="logintextborder" />
              </Box>
            </Box>
            <form data-test-id="formSubmitTestID" autoComplete="off" className="login_Form_Main_Container" onSubmit={this.handleLogin}>
              {this.renderLoginUserEmailInput()}
              {this.renderLoginUserPasswordInput()}
            <Box className="remember_main" >
              <Box className="checkboxMain" >
                <Checkbox
                  checked={this.state.loginCheckbox}
                  onChange={this.handleCheckbox}
                  className="checkboxLogin"
                  checkedIcon={<span className={clsx("icon", "checkedIcon")} />}
                />
                <Typography className="rememberText">Remember Me</Typography>
              </Box>
              <Typography className="forgotText" onClick={() => this.handleNavigateFromLogin("ForgotPasswordWeb")} >Forgot Password</Typography>
            </Box>
            <Box className="btnMainContainer" >
              <Button className="loginButton" type="submit">Login</Button>
              <Typography className="dashLines" >------------------   or   ------------------</Typography>
              <Button onClick={() => this.handleNavigateFromLogin("LandingPage")} className="GuestButton" data-test-id="guestTestID">Enter as a Guest</Button>
            </Box>
            <Box className="loginFormSignupButton">
              <Typography className="loginFormFieldLabelForlogintext">Don’t have an account? <span data-test-id="signUpTestID" onClick={() => this.handleClickOnSingup()} className="singupText">Sign Up
                <img src={Singuptextborder} alt="Singuptextborder" className="signupTextborder" />
              </span></Typography>
            </Box>
            </form>
          </Box>
          {this.state.singupAsModal &&
            <SignUpAsModelWeb
              data-test-id="signUpAsModelTestID"
              newBtnClick={() => this.clickOnUserSelectBtn("")}
              dealerBtnClick={() => this.clickOnUserSelectBtn("dealer")}
              handleCloseModal={this.handleCloseModal}
            />
          }
          <SnackBarAlert
            data-test-id="snackBarTestID"
            snackBarProps={this.state.hasSuccessErrorForLogin}
            handleCloseSnack={this.handleLoginCloseSnackbar}
          />
        </Grid>
        <Grid item xs={false} sm={6} md={7} lg={7} className="image_Container_Box" >
        </Grid>
      </MainLoginContainer>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainLoginContainer = styled(Grid)({
  width: "100%",
  height: "100%",
  "& .MuiButton-root":{
    textTransform: "initial"
  },
  "& .singupText": {
    color: "#000",
    textDecoration: "underline",
    position: "relative",
    zIndex: 99,
    fontSize: "16px",
    paddingLeft: "8px",
    cursor: "pointer"
  },
  "& .signupTextborder": {
    bottom: "1px",
    left: "8px",
    width: "48px",
    height: "22px",
    position: "absolute",
    transform: "scale(-1, -1)",
    zIndex: -1
  },
  "& .loginFormFieldLabelForlogintext": {
    position: "relative",
    zIndex: 999,
    color: "#0F172A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .loginFormSignupButton": {
    textAlign: "center",
    position: "relative",
    width: "100%",
    marginTop: "120px",
  },
  "& .loginButton": {
    width: "100%",
    height:"52px",
    background: "#000",
    border: "none",
    color: "#ffff",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
  },
  "& .GuestButton": {
    width: "100%",
    height:"52px",
    background: "#fff",
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    border: "1px solid var(--Basic-Black, #000)",
    padding: "10px 16px",
    "&:hover": {
      background: "#fff",
    },
  },
  "& .dashLines": {
    color: " #4E4E4E",
    textAlign: "center",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "@media (min-width:320px) and (max-width:575px)": {
      fontSize: "12px !important",
    }
  },
  "& .btnMainContainer": {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
  },
  "& .forgotText": {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    textDecorationLine: "underline",
    cursor:"pointer",
  },
  "& .icon": {
    borderRadius: 6,
    width: 20,
    height: 20,
    marginLeft: "2px",
    boxShadow: 'inset 0 0 0 1px #64748B, inset 0 -1px 0 #64748B',
    backgroundColor: 'transparent',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'transparent',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  "& .checkedIcon": {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:`url(${Group})`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  "& .MuiCheckbox-root":{
    width: 20,
    height: 20,
  },
  "& .MuiIconButton-root":{
    padding: "0 !important"
  },
  "& .checkboxMain":{
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    gap: "8px"
  },
  "& .checkboxLogin":{
    "& .MuiIconButton-root":{
      padding: "0 !important"
    },
  },
  "& .remember_main":{
    display: "flex",
    alignItems: "center",
    gap: "117px",
    justifyContent: "space-between",
    "@media (min-width:320px) and (max-width:778px)": {
      gap: "unset",
    }
  },
  "& .rememberText": {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHHeight: "24px",
  },
  "& .loginFormInputBox": {
    width: "100%",
    background: "transparent",
    border: "none",
    color: "#64748B",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "10px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid black",
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
    },
  },
  "& .loginFormInputBoxError": {
    color: "#64748B",
    width: "100%",
    border: "none",
    background: "transparent",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "24px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "sans-serif",
      width: "fit-content",
      color: "#DC2626",
      border: "none",
      borderRadius: "6px",
      position: "relative",
      fontSize: "12px",
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #EE3D3D",
      },
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .loginFormFieldContainer": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .loginFormFieldLabel": {
    marginBottom: "4px",
    fontSize: "12px",
    color: "#334155",
    fontStyle: "normal",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .signUp_Main_Container": {
    padding: "80px 20% 40px ",
    display: "flex",
    flexDirection: "column",
    gap: "34px"
  },
  "& .login_Form_Main_Container": {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  "& .image_Container_Box":{
    background: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  "& .login_Logo_Container": {
    display: "flex",
    flexDirection: "column",
    gap: "54px"
  },
  "& .logo": {
    width: "138px",
    height: "36px",
    flexShrink: 0,
  },
  "& .loginTextHeader": {
    position: "relative",
  },
  "& .loginUpText": {
    fontSize: "48px",
    fontWeight: 700,
    position: "relative",
    zIndex: 99,
    fontStyle: "normal",
    color: "#000"
  },
  "& .logintextborder": {
    top: "17px",
    left: "-21px",
    width: "99.167px",
    height: "35px",
    position: "absolute",
  },
  "@media(max-width:1300px)": {
    "& .signUp_Main_Container": { padding: "8%", }
  },
});
// Customizable Area End