import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  Box,
  Typography,
  styled,
  TextField,
  Dialog,
  Radio,
  Checkbox,
} from "@material-ui/core";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import ProfileAddressController, { Props } from "./ProfileAddressController";
import { AddImageIcon } from "./assets";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from '@material-ui/icons/Edit';
import Rating from "@material-ui/lab/Rating";
import { Group } from "../../email-account-login/src/assets";
import clsx from "clsx";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

export default class ProfileAddress extends ProfileAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderNameInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">Name*</Typography>
        <TextField
          name="name"
          data-test-id="nameInputTestID"
          error={this.state.isErrorName}
          helperText={
            this.state.isErrorName ? <span>Please enter your name.</span> : ""
          }
          placeholder="Name"
          className={
            this.state.isErrorName
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.nameAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderContactInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">
          Contact Number*
        </Typography>
        <TextField
          name="contactNumber"
          error={
            this.state.contactNumberAddressError ||
            this.state.contactNumberAddressBlankError
          }
          helperText={this.getErrorMessage("contactNumber")}
          placeholder="Contact Number"
          className={
            this.state.contactNumberAddressError ||
              this.state.contactNumberAddressBlankError
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.contactNumberAddress}
          onChange={this.handleNumberInput}
          data-test-id="numberInputTestID"
        />
      </Box>
    );
  };

  renderPincodeInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">Pincode*</Typography>
        <TextField
          name="pincode"
          error={
            this.state.pincodeAddressError ||
            this.state.pincodeAddressBlankError
          }
          helperText={this.getErrorMessage("pincode")}
          placeholder="Pincode"
          className={
            this.state.pincodeAddressError ||
              this.state.pincodeAddressBlankError
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.pincodeAddress}
          onChange={this.handleNumberInput}
          data-test-id="pincodeInputTestID"
        />
      </Box>
    );
  };

  renderStateInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">State*</Typography>
        <TextField
          name="state"
          data-test-id="stateInputTestID"
          error={this.state.isErrorState}
          helperText={
            this.state.isErrorState ? <span>Please enter your state.</span> : ""
          }
          placeholder="State"
          className={
            this.state.isErrorState
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.stateAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderAddressInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">Address*</Typography>
        <TextField
          name="address"
          data-test-id="addressInputTestID"
          error={this.state.isErrorAddress}
          helperText={
            this.state.isErrorAddress ? (
              <span>Please enter your address.</span>
            ) : (
              ""
            )
          }
          placeholder="Address"
          className={
            this.state.isErrorAddress
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.addressAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderLocalityInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">Locality*</Typography>
        <TextField
          name="locality"
          data-test-id="localityInputTestID"
          error={this.state.isErrorLocality}
          helperText={
            this.state.isErrorLocality ? (
              <span>Please enter your locality.</span>
            ) : (
              ""
            )
          }
          placeholder="Locality"
          className={
            this.state.isErrorLocality
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.localityAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderCityInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">City*</Typography>
        <TextField
          name="city"
          data-test-id="cityInputTestID"
          error={this.state.isErrorCity}
          helperText={
            this.state.isErrorCity ? <span>Please enter your city.</span> : ""
          }
          placeholder="City"
          className={
            this.state.isErrorCity
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.cityAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderCountryInput = () => {
    return (
      <Box className="ProfileAddressFormFieldContainer">
        <Typography className="addressFormFieldLabel">Country*</Typography>
        <TextField
          name="country"
          data-test-id="countryInputTestID"
          error={this.state.isErrorCountry}
          helperText={
            this.state.isErrorCountry ? (
              <span>Please enter your country.</span>
            ) : (
              ""
            )
          }
          placeholder="Country"
          className={
            this.state.isErrorCountry
              ? "ProfileAddressFormInputBoxError"
              : "ProfileAddressFormInputBox"
          }
          id="outlined-basic"
          variant="outlined"
          value={this.state.countryAddress}
          onChange={this.handleChangeInput}
        />
      </Box>
    );
  };

  renderDialogForNewAddress = () => {
    return (
      <DilogBoxMain
        aria-labelledby="customized-dialog-title"
        open={this.state.newAddressModal}
        data-test-id="dialogBoxTestID"
      >
         {this.state.isLoading &&
          <CircularProgressBox >
            <CircularProgress className="loader" />
          </CircularProgressBox>}
        <Box className="dilogBoxMain">
          <Typography className="addressHeadding" data-test-id="innerModalTestID">Add New Address</Typography>
          <hr className="hrLine" />
          <form
            data-test-id="formSubmitTestID"
            autoComplete="off"
            className="login_Form_Main_Container"
            onSubmit={this.handleSubmitChanges}
          >
            <Grid item container>
              <Grid item lg={6} xs={12} className="girdeset">
                {this.renderNameInput()}
                {this.renderContactInput()}
                {this.renderPincodeInput()}
                {this.renderStateInput()}
                {this.renderAddressInput()}
                {this.renderLocalityInput()}
                {this.renderCityInput()}
                {this.renderCountryInput()}
                <Box className="checkboxMain">
                  <Checkbox
                    data-test-id="checkboxAddressTestID"
                    checked={this.state.defaultCheck}
                    onChange={this.handleCheckbox}
                    className="checkboxAddress"
                    checkedIcon={
                      <span className={clsx("icon", "checkedIcon")} />
                    }
                  />
                  <Typography className="defaultText">
                    Make it as my default address
                  </Typography>
                </Box>
                <Box className="savebtnmain">
                  <Button
                    className="addressCancelButton"
                    onClick={this.handleClickNewAddress}
                  >
                    Cancel
                  </Button>
                  <Button className="addressSaveButton" type="submit" disabled={this.state.isFormSubmit}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DilogBoxMain>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        {this.props.isOrderDetails ? (
          ""
        ) : (
          <div className="mainContainer">
            <HeaderNew navigation={this.props.navigation} id={""} />
          </div>
        )}
        <MainAddressProfileContainer container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={this.props.isOrderDetails ? 12 : 8}
            className="mainleftGrid"
          >
            <Box className={this.props.isOrderDetails ? "profileTextHeaderMainForCart" : "profileTextHeaderMain"}>
              <Box className={this.props.isOrderDetails ? "addressInnerRightLine addressInnerRightLineCart" : "addressInnerRightLine"}>
                <Box className={this.props.isOrderDetails ? "headdingMain headdingMainCart" : "headdingMain"}>
                  <Typography className="addressHeadding">
                    {this.props.isOrderDetails ? "My Address" : "Saved Address"}
                  </Typography>
                  <Box
                    className="newAddressButton"
                    onClick={this.handleClickNewAddress}
                    data-test-id="newAddressBtnTestID"
                  >
                    <img
                      src={AddImageIcon}
                      alt="changeImageicon"
                      className="changeImageicon"
                    />
                    New Address
                  </Box>
                </Box>
                <Box className={this.props.isOrderDetails ? "addresMainBox addresMainBoxCart" : "addresMainBox"}>
                  {this.state.addressData.length > 0 ? (
                    this.state.addressData.map((addressItem, index) => {
                      return (
                        <>
                          <Box
                            data-test-id="addressInnerBoxTestID"
                            className={this.props.isOrderDetails ? "addressInnerBox addressInnerBoxCart" : "addressInnerBox"}
                          >
                            <Radio
                              data-test-id={`radioTestID${index}`}
                              checked={addressItem.attributes.is_default}
                              onChange={() => {
                                this.handleRadionChange(
                                  addressItem.attributes.id
                                );
                              }}
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              className={this.props.isOrderDetails ? "radiobtn radiobtnCart" : "radiobtn"}
                              disabled={addressItem.attributes.is_default}
                            />
                            <Box className="nameHeader">
                              <Typography className="nameText">
                                {addressItem.attributes.name}
                                <Rating
                                  value={
                                    addressItem.attributes.is_default ? 1 : 0
                                  }
                                  name="size-medium"
                                  defaultValue={2}
                                  max={1}
                                  className="ratingStar"
                                />
                                <EditIcon className="editIcon" fontSize="small"
                                 data-test-id={`editIconTestID${index}`}
                                  onClick={() => {
                                    this.handleClickEditAddress(
                                      addressItem
                                    );
                                  }}
                                />
                              </Typography>
                              <Typography className="addressText">
                                {" "}
                                {addressItem.attributes.address}
                              </Typography>
                              <Typography className="addressType">
                                Address Type:{" "}
                                {addressItem.attributes.address_type} (Delivery:
                                9AM-9PM)
                              </Typography>
                            </Box>
                            {this.props.isOrderDetails ? (
                              ""
                            ) : (
                                <HighlightOffIcon
                                  data-test-id={`cancelIconTestID${index}`}
                                  className="cancelIcon"
                                  onClick={() => {
                                    this.handleDeleteIcon(
                                      addressItem.attributes.id
                                    );
                                  }}
                                />
                            )}
                          </Box>
                          {!this.props.isOrderDetails &&
                            <hr
                              className={
                                index !== this.state.addressData.length - 1
                                  ? "hrLine"
                                  : ""
                              }
                            />
                          }
                        </>
                      );
                    })
                  ) : (
                    <>
                      <Typography>No Address</Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          {this.renderDialogForNewAddress()}
          <SnackBarAlert
            data-test-id="snackBarTestID"
            snackBarProps={this.state.hasSuccessErrorForAddress}
            handleCloseSnack={this.handleAddressCloseSnackbar}
          />
        </MainAddressProfileContainer>
        {this.props.isOrderDetails ? "" : <Footer />}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 999999999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});

const DilogBoxMain = styled(Dialog)({
  width: "100%",
  height: "100%",
  "& .MuiCheckbox-root": {
    width: 20,
    height: 20,
    color: "#E9B848",
  },
  "& .addressSaveButton": {
    width: "150px",
    height: "52px",
    color: "#ffff",
    border: "none",
    fontFamily: "Montserrat",
    background: "#000",
    textTransform: "initial",
    fontSize: "16px",
    lineHeight: "normal",
    fontStyle: "normal",
    borderRadius: "8px",
    fontWeight: 400,
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .addressCancelButton": {
    height: "52px",
    width: "150px",
    background: "#fff",
    color: "#000",
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontSize: "16px",
    textTransform: "initial",
    lineHeight: "normal",
    border: "1px solid #000",
    fontWeight: 400,
    borderRadius: "8px",
    padding: "10px 16px",
    "&:hover": {
      background: "#fff",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .savebtnmain": {
    width: "fit-content",
    marginTop: "20px",
    justifyContent: "space-between",
    display: "flex",
    gap: "20px",
  },
  "& .defaultText": {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHHeight: "24px",
  },
  textTransform: "initial",
  "& .MuiIconButton-root": {
    padding: "0 !important",
  },
  "& .icon": {
    borderRadius: 6,
    width: 20,
    height: 20,
    marginLeft: "2px",
    boxShadow: "inset 0 0 0 1px #64748B, inset 0 -1px 0 #64748B",
    backgroundColor: "transparent",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  "& .checkedIcon": {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      backgroundImage: `url(${Group})`,
      height: 20,
      width: 20,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  "& .checkboxMain": {
    display: "flex",
    alignItems: "center",
    width: "320px",
    gap: "8px",
  },
  "& .checkboxAddress": {
    fontSize: "12px",
  },
  "& .dilogBoxMain": {
    padding: "20px",
  },
  "& .ProfileAddressFormFieldContainer": {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .addressFormFieldLabel": {
    color: "#000",
    marginBottom: "4px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    opacity: 0.5,
  },
  "& .ProfileAddressFormInputBox": {
    color: "#64748B",
    background: "transparent",
    width: "320px",
    fontSize: "14px",
    border: "none",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "@media (min-width:320px) and (max-width:800px)": {
      width: "100%",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& label.Mui-focused": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-root": {
      marginBottom: "20px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid black",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
  },
  "& .ProfileAddressFormInputBoxError": {
    fontWeight: 400,
    border: "none",
    width: "320px",
    background: "transparent",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    color: "#64748B",
    fontStyle: "normal",
    fontSize: "14px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 8px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      border: "none",
      color: "#DC2626",
      width: "fit-content",
      fontFamily: "sans-serif",
      fontSize: "12px",
      position: "relative",
      fontWeight: 400,
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        border: "1px solid #EE3D3D",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
    },
  },
});
const MainAddressProfileContainer = styled(Grid)({
  "& .MuiRadio-root": {
    color: "#000",
  },
  "& .radiobtn": {
    padding: "0px !important",
    margin: "4px 12px auto",
    "& .MuiIconButton-root": {
      padding: "0px !important",
    },
  },
  "& .radiobtnCart": {
    margin: "4px 32px auto",
  },
  "& .addressInnerBox": {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  "& .addressInnerBoxCart": {
    boxShadow: "0px 0px 8px -5px rgb(0, 0, 0)",
    borderRadius: "20px",
    padding: "12px 0px",
  },
  "& .addresMainBox": {
    boxShadow: "0px 0px 9px -4px rgb(0, 0, 0)",
    borderRadius: "20px",
    margin: "0 80px 0 0",
    padding: "12px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
    "@media (min-width:320px) and (max-width:575px)": {
      margin: "0 0px 0 0",
    },
  },
  "& .addresMainBoxCart": {
    boxShadow: "none",
    margin: "0 0 0 0",
    padding: "0",
  },
  "& .addressInnerRightLine": {
    width: "100%",
    borderRight: "1px solid #F2F2F2",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 0% 0px",
      borderRight: "none",
    },
  },
  "& .addressInnerRightLineCart": {
    width: "100%",
    borderRight: "none",
  },
  "& .nameHeader": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  "& .editIcon": {
    cursor: "pointer",
    margin: "0 0 0 8px"
  },
  "& .cancelIcon": {
    position: "absolute",
    right: "12px",
    top: "12px",
    cursor: "pointer",
    "@media (min-width:320px) and (max-width:575px)": {
      right: "0",
      top: "0",
      fontSize: "16px",
    },
  },
  "& .addressType": {
    fontSize: "12px",
    color: "#000",
    zIndex: 99,
    fontWeight: 700,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all",
    },
  },
  "& .addressText": {
    fontSize: "12px",
    color: "#000",
    zIndex: 99,
    fontWeight: 700,
    opacity: 0.5,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all",
    },
  },
  "& .hrLine": {
    width: "95%",
    color: "#F2F2F2",
  },
  "& .ratingStar": {
    margin: "0 0 0 12px",
    "@media (min-width:320px) and (max-width:800px)": {
      margin: "0 0 0 0px",
      fontSize: "12px",
    },
  },
  "& .nameText": {
    fontSize: "20px",
    position: "relative",
    color: "#000",
    zIndex: 99,
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "12px !important",
    },
  },
  "& .addressHeadding": {
    fontSize: "40px",
    position: "relative",
    color: "#000",
    zIndex: 99,
    fontWeight: 500,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px",
    },
  },
  "& .mainContainer": {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
  "& .headdingMain": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 80px 28px 0",
    "@media (min-width:320px) and (max-width:575px)": {
      margin: "0 0px 28px 0",
      flexDirection: "column",
    },
  },
  "& .headdingMainCart": {
    margin: "0 0px 28px 0",
  },
  "& .profileTextHeaderMain": {
    padding: "50px 8% 53px",
  },
  "& .profileTextHeaderMainForCart": {
    padding: "0 0 0 0",
  },
  "& .changeImageicon": {
    display: "flex",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  "& .newAddressButton": {
    background: "#FFF !important",
    color: "#000 !important",
    border: "1px solid #000",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "8px",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "initial",
    padding: "8px 15px",
    display: "flex",
    cursor: "pointer",
    "@media (min-width:320px) and (max-width:1000px)": {
      justifyContent: "center",
      width: "fit-content",
    },
  },
});
// Customizable Area End
