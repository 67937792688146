import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  Box,
  Typography,
  styled,
  Dialog,
  TextareaAutosize,
  Checkbox
} from "@material-ui/core";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
export const configJSON = require("../../../framework/src/config");
import CircularProgress from '@material-ui/core/CircularProgress';
import { wishlistEmpty } from "../../wishlist/src/assets";
import OrderSummaryController, { Props } from "./OrderSummaryController";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import Rating from "@material-ui/lab/Rating";
import { Group } from "../../email-account-login/src/assets";
import clsx from "clsx";
import { Singuptextborder, checkmark, error } from "./assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
// Customizable Area End

export default class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmptyOrders = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} className="emptyMainGrid" data-test-id="noDataBoxTestID">
          <Box className="emptyMainBox">
            <img src={wishlistEmpty} alt="" className="wishlistEmpty" />
            <Typography className="ordersHeadding emptyHeadigBlod">
              Your Orders is Empty!
            </Typography>
            <Typography className="emptyText">
              Add items that you like to your Orders.
            </Typography>
            <Button
              data-test-id="nextBtnTestID"
              onClick={() => { this.handleNavigateFromOrders("Catalogue") }}
              className="ordersButton"
            >
              Continue Shopping
            </Button>
          </Box>
        </Grid>
      </>
    )
  }

  renderOrderHistory = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={3} data-test-id="ordersInnerTestID">
          <Typography className="ordersHeadding">
            Order History
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} data-test-id="">
          {this.state.ordersData.map((orderItem, index) => {
            const { invoice_date, invoice_number, isShowAll, id } = orderItem.attributes
            const { total_fees } = orderItem.attributes.order_details
            return (
              <Box className="orderboxmain" key={index}>
                <Box className="innerordermain">
                  <Box className="speratorbox">
                    <Box className="orderdatebox">
                      <Typography className="orderplacedTExt">
                        Order Placed
                      </Typography>
                      <Typography className="orderplacedTExt orderplacedDownTExt">
                        {this.formatDate(invoice_date)}
                      </Typography>
                    </Box>
                    <Box className="orderdatebox orderTotal">
                      <Typography className="orderplacedTExt">
                        Total
                      </Typography>
                      <Typography className="orderplacedTExt orderplacedDownTExt">
                        ₹{this.numberWithCommas(total_fees)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="orderdatebox">
                    <Typography className="orderplacedTExt">
                      Invoice no.
                    </Typography>
                    <Typography className="orderplacedTExt orderplacedDownTExt">
                      {invoice_number}
                    </Typography>
                  </Box>
                </Box>
                <hr className="hrLine" />
                <Box className="innerordermain forcolumn">
                  {isShowAll ?
                    (
                      orderItem.attributes.order_items.map((item, innerIndex) => {
                        const { images, part_number, order_item_name, description, catalogue_id } = item
                        return (
                          <Box
                            className="orderCartInnerBox"
                          >
                            <div className="imageHead">
                              <img src={images[0].url} alt="" className="imageCart" />
                            </div>
                            <Box className="sepratTwoMainBox">
                              <Box className="nameHeaderCart">
                                <Typography className="orderNumberTextCart">
                                  {part_number}
                                </Typography>
                                <Typography className="modelCartText">
                                  {order_item_name}
                                </Typography>
                                <Typography className="cartPartNUmberType">
                                  {description}
                                </Typography>
                              </Box>
                              <Button
                                data-test-id={`orderDetailsBtnTestID${index}`}
                                onClick={() => this.giveFeedback(catalogue_id)}
                                className="ordersButton"
                              >
                                Give feedback
                              </Button>
                            </Box>
                          </Box>
                        )
                      })
                    )
                    :
                    (
                      <>
                        <Box
                          className="orderCartInnerBox"
                        >
                          <div className="imageHead">
                            <img src={orderItem.attributes.order_items[0].images[0].url} alt="" className="imageCart" />
                          </div>
                          <Box className="sepratTwoMainBox">
                            <Box className="nameHeaderCart">
                              <Typography className="orderNumberTextCart">
                                {orderItem.attributes.order_items[0].part_number}
                              </Typography>
                              <Typography className="modelCartText">
                                {orderItem.attributes.order_items[0].order_item_name}
                              </Typography>
                              <Typography className="cartPartNUmberType">
                                {orderItem.attributes.order_items[0].description}
                              </Typography>
                            </Box>
                            <Button
                              onClick={() => this.giveFeedback(orderItem.attributes.order_items[0].catalogue_id)}
                              className="ordersButton"
                              data-test-id={`orderDetailsBtnTestID${index}`}
                            >
                              Give feedback
                            </Button>
                          </Box>
                        </Box>
                        {orderItem.attributes.order_items.length > 1 &&
                          <Typography className="moreBtn" onClick={() => this.moreItem(orderItem.attributes.id)} >
                            +{orderItem.attributes.order_items.length - 1} more <ArrowDropDownIcon />
                          </Typography>
                        }
                      </>
                    )
                  }
                </Box>
              </Box>
            )
          })}
        </Grid>
      </>
    )
  }

  renderFeedbakModal = () => {
    return (
      <DilogBoxMain
        aria-labelledby="customized-dialog-title"
        open={this.state.isFeedbackOpen}
        data-test-id="dialogBoxTestID"
        onClose={this.handleCloseModalFeedback}
        className={"signUp_ModelContainer"}
      >
        <Box className="dilogBoxMain">
          <img src={Singuptextborder} alt="Singuptextborder" className={"upborderimg"} />
          <img src={Singuptextborder} alt="Singuptextborder" className={"downborderimg"} />
          <Box className="headingHead">
            <Typography  data-test-id="feedbackBoxTestID" className="ordersFeedbackHeadding">Feedback</Typography>
            <CloseIcon
              className="cancelIcon"
              onClick={this.handleCloseModalFeedback}
            />
          </Box>
          <form
            data-test-id="formSubmitTestID"
            autoComplete="off"
            className="login_Form_Main_Container"
            onSubmit={this.handleSubmit}
          >
            <Box className="downInnerBox">

              <Typography className="titletext">Please rate your experience with the product</Typography>
              <Rating
                name="size-large"
                value={this.state.ratingValue}
                size="large"
                onChange={this.onRatingchange}
                className="rating"
                data-test-id="ratingInputTestID"
              />
              {this.state.ratingBlankError
                ? <span className="textAreaBox">Please enter choose your rating.</span>
                : ""}
              <TextareaAutosize
                maxRows={15}
                aria-label="maximum height"
                placeholder="Write your feedback here"
                value={this.state.message}
                className={this.state.messageBlankError ? "textAreasError" : "textAreas"}
                onChange={this.handleFeedbackInput}
                data-test-id="messageInputTestID"
              />
              {this.state.messageBlankError
                ? <span className="textAreaBox">Please enter your feedback.</span>
                : ""}
              <Box className="checkboxMain">
                <Checkbox
                  data-test-id="checkboxAnonymousTestID"
                  checked={this.state.anonyCheck}
                  onChange={this.handleCheckbox}
                  className="checkboxAddress"
                  checkedIcon={
                    <span className={clsx("icon", "checkedIcon")} />
                  }
                />
                <Typography className="defaultText">
                  Anonymous
                </Typography>
              </Box>
              <Button className="SendButton" type="submit">
                Send feedback
              </Button>
            </Box>
          </form>
        </Box>
      </DilogBoxMain>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading ?
          <CircularProgressBox >
            <CircularProgress className="loader" />
          </CircularProgressBox>
          :
          <>
            <div className="mainContainer">
              <HeaderNew navigation={this.props.navigation} id={""} />
            </div>
            <OrdersTextHeaderMain>
              <MainOrdersContainer container spacing={3}>
                {this.state.ordersData.length === 0 && this.renderEmptyOrders()}
                {this.state.ordersData.length !== 0 && this.renderOrderHistory()}
                {this.renderFeedbakModal()}
              </MainOrdersContainer>
            </OrdersTextHeaderMain>
            <Footer />
          </>
        }
        <SnackBarAlert
          data-test-id="snackBarTestID"
          snackBarProps={this.state.hasSuccessErrorForOrders}
          handleCloseSnack={this.handleOrdersCloseSnackbar}
        />
        {this.state.isFeedbackSuccess &&
          <SignUpSuccessModel
            data-test-id="successModalTestID"
            loginBtnClick={() => this.handleNavigateFromOrders("LandingPage")}
            image={checkmark} heading={"Feedback Sent!"}
            description={"You have successfully submitted your feedback"}
            showBtn={true}
            btnText={"Home"}
          />
        }
        {this.state.isFeedbackError &&
          <SignUpSuccessModel
            data-test-id="successModalTestID"
            loginBtnClick={() => this.handleNavigateFromOrders("LandingPage")}
            image={error} heading={"There was an error"}
            description={"An unexpected error has occurred. Please try again after sometime."}
            showBtn={true}
            btnText={"Home"}
          />
        }
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const DilogBoxMain = styled(Dialog)({
  width: "100%",
  height: "100%",
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "100% !important",
    maxHeight: "100% !important"
  },
  "& .MuiDialog-paper": {
    overflowY: "unset"
  },
  " .signUp_ModelContainer": {
    position: "relative",
  },
  "& .upborderimg": {
    "width": "119px",
    "height": "37px",
    position: "absolute",
    top: "-5px",
    left: "-78px",
    "@media(min-width:320px) and (max-width:768px)": {
      display: "none"
    }
  },
  "& .downborderimg": {
    "width": "119px",
    "height": "37px",
    position: "absolute",
    bottom: "-15px",
    right: "-52px",
    transform: "rotate(90deg)",
    "@media(min-width:320px) and (max-width:768px)": {
      display: "none"
    }
  },
  "& .SendButton": {
    width: "100%",
    height: "52px",
    border: "none",
    color: "#ffff",
    fontFamily: "Montserrat",
    background: "#000",
    textTransform: "initial",
    fontSize: "16px",
    lineHeight: "normal",
    fontStyle: "normal",
    borderRadius: "8px",
    fontWeight: 400,
    padding: "10px 16px",
    marginTop: "28px",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .MuiCheckbox-root": {
    width: 20,
    height: 20,
    color: "#00000042",
  },
  "& .defaultText": {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHHeight: "24px",
    opacity: "0.5",
  },
  "& .MuiIconButton-root": {
    padding: "0 !important",
  },
  "& .icon": {
    borderRadius: 6,
    width: 20,
    height: 20,
    marginLeft: "2px",
    boxShadow: "inset 0 0 0 1px #64748B, inset 0 -1px 0 #64748B",
    backgroundColor: "transparent",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "transparent",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  "& .checkedIcon": {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      backgroundImage: `url(${Group})`,
      height: 20,
      width: 20,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  "& .checkboxMain": {
    display: "flex",
    width: "100%",
    gap: "8px",
    marginTop: "12px",
  },
  "& .checkboxAddress": {
    fontSize: "12px",
  },
  "& .textAreasError": {
    border: "1px solid #EE3D3D",
    borderRadius: "8px",
    width: "100%",
    padding: "10px 8px",
    height: "82px !important",
    marginTop: "32px",
  },
  "& .textAreas": {
    border: "1px solid #00000080",
    borderRadius: "8px",
    width: "100%",
    height: "82px !important",
    padding: "10px 8px",
    marginTop: "32px",
  },
  "& .textAreaBox": {
    color: "#DC2626",
    border: "none",
    width: "100%",
    fontSize: "12px",
    fontFamily: "sans-serif",
    fontWeight: 400,
    borderRadius: "6px",
    position: "relative",
  },
  "& .dilogBoxMain": {
    padding: "34px",
    width: "fit-content"
  },
  "& .cancelIcon": {
    cursor: "pointer",
  },
  "& .rating": {
    fontSize: "42px",
    marginTop: "12px"
  },
  "& .downInnerBox": {
    padding: "38px",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
  },
  "& .headingHead": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .titletext": {
    fontSize: "16px",
    color: "#000",
    fontWeight: 600,
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px",
    },
  },
  "& .ordersFeedbackHeadding": {
    fontSize: "22px",
    position: "relative",
    color: "#0F172A",
    zIndex: 99,
    fontWeight: 600,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px",
    },
  },
});
const OrdersTextHeaderMain = styled(Box)({
  padding: "50px 4% 53px",
  "@media (min-width:320px) and (max-width:575px)": {
    padding: "0px 4% 0px",
  },
  "& .moreBtn": {
    cursor: "pointer",
    display: "flex"
  },
  "& .cartPartNUmberType": {
    fontWeight: 700,
    zIndex: 99,
    fontStyle: "normal",
    color: "#000",
    opacity: 0.5,
    fontSize: "12px",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .modelCartText": {
    fontSize: "18px",
    color: "#000",
    zIndex: 99,
    fontWeight: 700,
    opacity: 0.5,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .orderNumberTextCart": {
    fontSize: "20px",
    position: "relative",
    color: "#000",
    zIndex: 99,
    fontWeight: 700,
    alignItems: "center",
    display: "flex",
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "12px !important",
    },
  },
  "& .nameHeaderCart": {
    gap: "10px",
    flexDirection: "column",
    display: "flex",
  },
  "& .sepratTwoMainBox": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .imageCart": {
    height: "100px",
    width: "100%",
    borderRadius: "8px",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .imageHead": {
    width: "118px",
  },
  "& .forcolumn": {
    flexDirection: "column",
    gap: "12px",
  },
  "& .orderCartInnerBox": {
    width: "100%",
    display: "flex",
    gap: "12px",
  },
  "& .hrLine": {
    width: "100%",
    color: "#F2F2F2",
  },
  "& .speratorbox": {
    display: "flex",
    justifyContent: "space-between",
    width: "35%",
    "@media (min-width:320px) and (max-width:575px)": {
      justifyContent: "unset",
      width: "unset",
      gap: "12px"
    },
  },
  "& .orderplacedTExt": {
    fontSize: "14px",
    color: "#00000094",
    fontWeight: "700",
  },
  "& .orderplacedDownTExt": {
    color: "#000 !important",
  },
  "& .orderdatebox": {
    display: "flex",
    flexDirection: "column"
  },
  "& .orderTotal": {
    width: "30%"
  },
  "& .innerordermain": {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px"
  },
  "& .orderboxmain": {
    background: "#fff",
    width: "100%",
    boxShadow: "0px 0px 7px -4px rgb(0, 0, 0)",
    marginBottom: "44px",
    borderRadius: "8px",
  },
  "& .ordersButton": {
    color: "#000",
    border: "1px solid #000",
    height: "38px",
    fontFamily: "Montserrat",
    width: "188px",
    lineHeight: "normal",
    textTransform: "initial",
    fontSize: "16px",
    background: "#fff",
    padding: "10px 16px",
    borderRadius: "8px",
    fontWeight: 400,
    fontStyle: "normal",
    "&:hover": {
      background: "#fff",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .emptyMainBox": {
    width: "35%",
  },
  "& .wishlistEmpty": {
    paddingBottom: "20px",
    width: "200px",
    height: "200px",
  },
  "& .ordersHeadding": {
    fontSize: "24px",
    position: "relative",
    color: "#000",
    fontStyle: "normal",
    zIndex: 99,
    fontWeight: 700,
    paddingBottom: "20px",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
  "& .emptyMainGrid": {
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  "& .emptyText": {
    fontSize: "16px"
  },
  "& .emptyHeadigBlod": {
    fontSize: "40px !important",
    fontWeight: "bold !important",
  },
});

const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});

const MainOrdersContainer = styled(Grid)({
  textTransform: "initial",
  height: "100%",
});
// Customizable Area End