import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";

export interface ValidResponseType {
  data: {
    attributes: {
      email: string;
    };
  };
  meta: {
    token: string;
  };
}

export interface ErrorPayloadType {
  key: string;
  email: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>
}

export interface HasSuccessErrorTypeForWishlist {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  gstNumber: string;
  gstNumberError: boolean;
  userType: string;
  emailErrorMsg: string;
  passwordErrorMsg: string;
  blankEmailError: boolean;
  blankPasswordError: boolean;
  blankGstError: boolean;
  gstErrorMsg: string;
  firstNameError: boolean;
  contact: string;
  emailError: boolean;
  lastNameError: boolean;
  contactError: boolean;
  passwordError: boolean;
  hasSuccessErrorForSignup: HasSuccessErrorTypeForWishlist;
  SignUpSuccessModel: boolean;
  contactBlankError: boolean;
  showPassword:boolean;
  gstSelect: string | null;
  isDropdownOpen: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  postSignUpApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      gstNumber: "",
      gstNumberError: false,
      phone: "",
      userType: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      blankEmailError: false,
      blankPasswordError: false,
      blankGstError: false,
      gstErrorMsg: "",
      firstNameError: false,
      contact: "",
      emailError: false,
      lastNameError: false,
      contactError: false,
      passwordError: false,
      hasSuccessErrorForSignup: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      SignUpSuccessModel: false,
      contactBlankError: false,
      showPassword: false,
      gstSelect: "Registered user",
      isDropdownOpen: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        return;
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userType = await StorageProvider.get("UserType");
    this.setState({
      userType: userType
    });
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  apiCall = async (valueData: {
    contentType?: string,
    method?: string,
    endPoint?: string,
    body?: {},
    type?: string,
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    let header = {
      "Content-Type": contentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.postSignUpApiCallId) {
      this.snackBarErrorApi(responseJson);
    }
  }

  apiSuccessCallBacks = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.postSignUpApiCallId) {
      this.setState({
        SignUpSuccessModel:true
      })
    }
  }

  handleNavigate = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  handleFirstNameInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nameRegex = /^[A-Za-z]+$/;
    const trimmedValue = nameEvent.target.value;
    if (nameRegex.test(trimmedValue) || trimmedValue.trim() === "") {
      this.setState({
        firstName: nameEvent.target.value
      });
    }
    if (nameEvent?.target.value === "") {
      this.setState({
        firstNameError: true
      });
    } else {
      this.setState({
        firstNameError: false
      });
    }
  };
  handleLasttNameInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nameRegex = /^[A-Za-z]+$/;
    const trimmedValue = nameEvent.target.value;
    if (nameRegex.test(trimmedValue) || trimmedValue.trim() === "") {
      this.setState({
        lastName: nameEvent.target.value
      });
    }
    if (nameEvent?.target.value === "") {
      this.setState({
        lastNameError: true
      });
    } else {
      this.setState({
        lastNameError: false
      });
    }
  };

  handleUserEmailInput = (emailEvent: React.ChangeEvent<HTMLInputElement>) => {
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const isValidEmail = emailReg.test(emailEvent.target.value)
    this.setState({
      email: emailEvent.target.value
    })
    if (emailEvent.target.value === "") {
      this.setState({
        emailError: false,
        blankEmailError: true,
        emailErrorMsg: configJSON.emailErrorMsg
      });
    } else if (isValidEmail === false) {
      this.setState({
        emailError: true,
        blankEmailError: false,
        emailErrorMsg: configJSON.emailInvalidErrorMsg
      });
    } else {
      this.setState({
        emailError: false,
        blankEmailError: false,
        emailErrorMsg: ""
      });
    }
  };

  handleClickShowPasswordSignup = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  };

  handlePasswordInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;
    const isValidPassword = passwordRegex.test(nameEvent.target.value)
    this.setState({
      password: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        passwordError: false,
        blankPasswordError: true,
        passwordErrorMsg: configJSON.passwordErrorMsg
      });
    } else if (isValidPassword === false) {
      this.setState({
        passwordError: true,
        blankPasswordError: false,
        passwordErrorMsg: configJSON.passwordInvalidErrorMsg
      });
    }
    else {
      this.setState({
        passwordError: false,
        blankPasswordError: false,
        passwordErrorMsg: ""
      });
    }
  };

  getPasswordErrorMessage() {
    const { blankPasswordError, passwordError } = this.state;

    if (blankPasswordError) {
      return "Please enter your password.";
    } else if (passwordError) {
      return "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character (!@#$&*?<>',[]}{=-)(^%`~+.:;_).";
    }
    
    return null;
  }

  getContactErrorMessage() {
    const { contactError, contactBlankError } = this.state;

    if (contactBlankError) {
      return "Please enter your contact number.";
    } else if (contactError) {
      return "Please enter a valid contact number.";
    }
    
    return null;
  }

  handleContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumberRegex = /^\d{0,10}$/;
    const inputValue = nameEvent.target.value.trim();
    if (phoneNumberRegex.test(inputValue) || inputValue === "") {
      this.setState({
        contact: nameEvent.target.value
      }, () => {
        if (nameEvent?.target.value === "") {
          this.setState({
            contactBlankError: true,
            contactError: false,
          });
        } else if (this.state.contact.length !== 10) {
          this.setState({
            contactError: true,
            contactBlankError: false,
          });
        }
        else {
          this.setState({
            contactError: false,
            contactBlankError: false
          });
        }
      });
    }
  };

  handleGstSelectInput = (
    _event: React.ChangeEvent<{}>, 
    value: string | null,
    _reason: string
  ) => {
    this.setState({
      gstSelect: value,
      gstNumberError: false,
      blankGstError: false,
      gstErrorMsg: ""
    });
  }; 
  
  onDropdownOpen = () => {
    this.setState({ isDropdownOpen: true });
  };

  onDropdownClose = () => {
    this.setState({ isDropdownOpen: false });
  };

  handleGstNumInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const gstRegex = /^[A-Za-z0-9]{15}$/;
    const isValidGst = gstRegex.test(nameEvent.target.value)
    this.setState({
      gstNumber: nameEvent.target.value
    });

    if (nameEvent.target.value === "") {
      this.setState({
        gstNumberError: false,
        blankGstError: this.state.userType === 'dealer' || this.state.gstSelect === "Registered user",
        gstErrorMsg: this.state.userType === 'dealer' || this.state.gstSelect && configJSON.gstErrorMsg
      });
    } else if (isValidGst === false) {
      this.setState({
        gstNumberError: true,
        blankGstError: false,
        gstErrorMsg: configJSON.gstInvalidErrorMsg
      });
    }
    else {
      this.setState({
        gstNumberError: false,
        blankGstError: false,
        gstErrorMsg: ""
      });
    }
  };

  handleSignUp = async (preventEvent: { preventDefault: () => void; }) => {
    preventEvent.preventDefault();
    const gstRegex = /^[A-Za-z0-9]{15}$/;
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_])[A-Za-z\d!@#$&*?<>',\[\]\}\{=\-)(^%`~+.:;_]{8,}$/;

    const errors = {
      firstNameError: !this.state.firstName,
      lastNameError: !this.state.lastName,
      blankEmailError: !this.state.email,
      contactBlankError: !this.state.contact,
      blankPasswordError: !this.state.password,
      blankGstError: (this.state.userType === 'dealer' || this.state.gstSelect === "Registered user") && !this.state.gstNumber,
      gstNumberError: (this.state.userType === 'dealer' || this.state.gstSelect === "Registered user") && !gstRegex.test(this.state.gstNumber),
      emailError: !emailReg.test(this.state.email),
      passwordError: !passwordRegex.test(this.state.password),
      contactError: this.state.contact.length !== 10
    };
    if (!gstRegex.test(this.state.gstNumber)) {
      this.setState({
        gstErrorMsg: configJSON.gstInvalidErrorMsg,
      });
    }
    if (!emailReg.test(this.state.email)) {
      this.setState({
        emailErrorMsg: configJSON.emailInvalidErrorMsg,
      });
    }
    if (!passwordRegex.test(this.state.password)) {
      this.setState({
        passwordErrorMsg: configJSON.passwordInvalidErrorMsg,
      });
    }
    if (!this.state.email) {
      this.setState({
        emailErrorMsg: configJSON.emailErrorMsg,
      });
    }
    if (!this.state.password) {
      this.setState({
        passwordErrorMsg: configJSON.passwordErrorMsg,
      });
    }
    if (!this.state.gstNumber) {
      this.setState({
        gstErrorMsg: configJSON.gstErrorMsg,
      });
    }

    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      let body = {
        data: {
          type: "email_account",
          attributes: {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            full_phone_number: `+91${this.state.contact}`,
            gst_number: this.state.gstNumber,
            user_type: this.state.userType === "dealer" ? "dealer" : "customer"
          }
        }
      }
      this.postSignUpApiCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeAddDetail,
        endPoint: configJSON.postSignUpAPIEndPoint,
        body: body
      });
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForSignup: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };

  snackBarErrorApi = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessErrorForSignup: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.errors[0].email
      }
    });
  };
  // Customizable Area End
}
