import React from "react";
// Customizable Area Start
import { Box, Chip, Grid, Typography, styled } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import ClearIcon from '@material-ui/icons/Clear';
import Categoriessubcategories from "../../categoriessubcategories/src/Categoriessubcategories.web";
import { backArrow, grid4Button2, decrementButton, gridButton, heartIcon, incrementButton } from "./assets";
import { Category } from "./CatalogueController";
import MenuIcon from '@material-ui/icons/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";

const CardGrid = styled(Grid)({
  '@media(max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    '& .subHeadingText': {
      height: 'unset !important',
    },
  },
  '& .innerCardBox': {
    padding:"24px 20px 32px 20px",
    '& .subHeadingText': {
      textTransform: 'uppercase',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    '@media(min-width: 960px) and (max-width: 1199px)': {
      padding: '20px 10px',
      '& .headingText': {
        fontSize: '16px !important'
      },
      '& .subHeadingText': {
        fontSize: '14px !important',
        lineHeight: 'normal !important'
      }
    }
  }
})

const ParentBox = styled(Box)({
  '@media(max-width: 1439px)': {
    gap: '50px !important',
    '& .innerCard': {
      gap: '20px !important'
    }
  },
  '@media(max-width: 1260px)': {
    gap: '24px !important',
    '& .innerCard': {
      gap: '10px !important'
    }
  },
  '@media(max-width: 1153px)': {
    gap: '24px !important',
    '& .innerCard': {
      gap: '24px !important',
      justifyContent: 'flex-start !important',
    },
  },
  '@media(min-width: 960px) and (max-width: 1199px)': {
    '& .sidebar-box': {
      maxWidth: '230px !important'
    },
  },
  '@media (max-width: 960px)': {
    '& .sidebar-text': {
      display: 'none'
    },
    '& .sidebar-box': {
      left: 0,
      position: 'absolute',
      top: -30,
      background: 'white',
      zIndex: 99999999999,
      paddingTop: 20
    },
    '& .sidebar-head-text': {
      display: 'flex !important'
    },
    '& .right-box': {
      paddingLeft: 40
    },
    '& .innerCard': {
      justifyContent: 'center !important',
    },
    '& .heading-text': {
      justifyContent: 'space-between !important'
    },
    '& .menu-icon': {
      display: 'inline-block !important',
    }
  },
  '@media (max-width: 600px)': {
    '& .right-box': {
      paddingLeft: 16,
      paddingRight: '16px !important',
    },
    '& .heading-text': {
      flexDirection: 'column',
    },
  }
})
const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { wishlistEmpty } from "../../wishlist/src/assets";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEmptySearchData = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} className="emptyMainGrid" data-test-id="noDataBoxTestID">
          <Box className="emptyMainBox">
            <img src={wishlistEmpty} alt="" className="wishlistEmpty" />
            <Typography className="ordersHeadding emptyHeadigBlod" data-test-id="noDataTextTestID">
              No data found!
            </Typography>
          </Box>
        </Grid>
      </>
    )
  }

  renderSearchData = () => {
    return (
      <>
        {this.state.searchData.length > 0 && this.state.searchData.map((item: Category, index) => this.getWebList(item, index))}
        {this.state.isNoDataSearch && this.renderEmptySearchData()}
      </>
    )
  }

  renderMainData = () => {
    return (
      <>
        {this.state.arrayHolder1.length > 0 && this.state.arrayHolder1.map((item: Category, index) => this.getWebList(item, index))}
        {this.state.isNoDataCat && this.renderEmptySearchData()}
      </>
    )
  }

  renderPagination = () => {
    return (
      <>
        {
          this.state.productCount > 0 &&
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "158.5px", marginTop: 63, maxHeight: 40 }}>
            <img className={this.state.pageNo === 1 ? "decreaseBUtton":""} data-test-id="decreaseTestId" src={decrementButton} onClick={this.decreasePageNumber} style={{ cursor: "pointer" }} />
            <Typography data-test-id="increasePageTestId" style={{ fontSize: "14px", fontWeight: 500, textAlign: "center", lineHeight: 20 }}>Page {this.state.pageNo} of {this.countPage()}</Typography>
            <img className={this.state.pageNo === this.countPage() ? "decreaseBUtton":""}data-test-id="increaseTestId" src={incrementButton} onClick={this.increasePageNumber} style={{ cursor: "pointer" }} />
          </Box>
        }
      </>
    )
  }

  getWebList(item: Category, index: number) {
    let value = item.attributes;
    const imageUrl = value.images && value.images.length > 0 ? value.images[0].url : '';
    return (
      <CardGrid item md={this.state.grid4 ? 4 : 3} sm={4} xs={12}>

        <Box className="mainFavIconBox" key={`product-item-${item.id}`} >
          <Box style={{
            borderRadius: "16px 16px 0px 0px", overflow: "hidden", position: "relative", background: 'radial-gradient(81.54% 81.54% at 50% 50.1%, #F7F8FB 0%, rgba(230, 231, 234, 0.801847) 100%)'
          }}>
            <img width="100%" height="204px" src={imageUrl} alt="No Image" style={{cursor: "pointer" }}
            onClick={() => this.goToProductDescription(item.id)}
            data-test-id={`productTestID${index}`}
            />
            <img src={heartIcon}
              className="favBox"
            onClick={() => this.addToWishlistCat(item.id)}
            data-test-id={`wistlistTestID${index}`}
            />
          </Box>
          <Box>
            <Box className="innerCardBox" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography className="headingText" style={{ fontSize: "20px", fontWeight: 600, lineHeight: "25px" }}>{value.part_number}</Typography>
              <Typography className="subHeadingText" style={{ fontSize: "16px", fontWeight: 300, color: "rgba(102, 102, 102, 1)", lineHeight: "24px" }}>
                {value.name.length>15
                ? value.name.slice(0, 15)+"....."
                : value.name
                }
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: 400, color: "rgba(127, 127, 127, 1)", lineHeight: "24px" }}>
                {value.description.length>25
                ? value.description.slice(0, 25)+"....."
                : value.description
                }
              </Typography>
            </Box>
            <Typography style={{ paddingLeft: "20px", fontSize: "20px", fontWeight: 700, lineHeight: "25px" }}>₹ {value.discount_price == null ? value.price : value.discount_price} <del style={{ fontSize: "14px", fontWeight: 400, color: "rgba(188, 188, 188, 1)", lineHeight: "17.5px" }}>{value.discount_price == null ? "" : `₹ ${value.price}`}</del></Typography>
          </Box>
        </Box>
      </CardGrid>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>

        {this.state.isLoading &&
          <CircularProgressBox >
            <CircularProgress className="loader" />
          </CircularProgressBox>
        }
        <MainBox data-test-id="catalogue">
          <div><HeaderNew navigation={this.props.navigation} id={""} /></div>
          <ParentBox style={{ display: "flex", gap: "124px" }}>
            <Box className="sidebar-box" style={{ 
              left:this.state.isToggle?0:-312,
              maxWidth: "276px", width: '100%', minHeight: "100vh", display: "flex", flexDirection: "column", gap: "11px", paddingLeft: "38.5px", marginTop: "30px" }}>
              <Box style={{ display: "flex", margin: "2px 0px", gap: "8px" }}>
                <img src={backArrow} data-test-id="TestGoBack" onClick={this.goBack} />
                <Typography className="sidebar-text" style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left'
                }}>
                  <span style={{ color: "rgba(128, 128, 128, 1)" }}>Back to Home /</span><span style={{ color: "rgba(0,0, 0, 1)" }}> Catalogue</span>
                </Typography>
              </Box>
              {this.state.isSearchBox ?
                <Box className="mainBox">
                  <AdvancedSearch
                    data-test-id="searchComponentTestId"
                    searchButton={this.handleSearchButton}
                    navigation={this.props.navigation} id={""}
                    searchParamsData={this.state.searchParams}
                    isParamsonUpdated={this.state.isParamsupdated}
                  />
                </Box> :
                <Categoriessubcategories navigation={undefined} id={""}
                  pushSelectedData={this.pushSelectedDataRadio}
                  selectedSubCat={this.state.apiSelectedSubCat}
                  handleClear={this.handleClear}
                  data-test-id="Categoriessubcategories"
                  isParamsonUpdated={this.state.isParamsupdated}
                />
              }
            </Box>
            <Box style={{ width: "100%", height: "100%", marginTop: "17px" }}>
              <Box className="right-box" style={{ display: "flex", flexDirection: "column", gap: "41px", paddingRight: 40 }}>
                <Box className="heading-text" style={{ display: "flex", justifyContent: "end" }}>
                  <Box className="sidebar-head-text" style={{ display: "none", margin: "2px 0px", gap: "8px", alignItems: 'center', width: '100%' }}>
                    <img src={backArrow} style={{ width: 24 }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      textAlign: 'left'
                    }}>
                      <span style={{ color: "rgba(128, 128, 128, 1)" }}>Back to Home /</span><span style={{ color: "rgba(0,0, 0, 1)" }}> Catalogue</span>
                    </Typography>
                  </Box>
                  <Box className="searchtexthead">
                    <Typography style={{ width: '100%', textAlign: 'end',minWidth:'max-content'}}>Search Result - {this.state.productCount}</Typography>
                    {this.state.isSearchBox ?
                      <span className="searchText" onClick={this.advancedSearchCancel} data-test-id="cancelSearchTestId" >Cancel Advanced Search</span>
                      :
                      <span className="searchText" onClick={this.advancedSearchBtn} data-test-id="advancedSearchTestId">Advanced Search</span>
                    }
                  </Box>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", gap: "29px", maxWidth: 962, width: '100%' }}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      {Object.entries(this.state.selectedSubCat).map((data, index) =>
                        <Chip
                          key={index}
                          data-test-id={`deleteChipTestID_${index}`}
                          label={this.checkLabel(data[1])}

                          variant="outlined"
                          deleteIcon={<ClearIcon />}
                          onDelete={this.state.isSearchBox ? () => this.handleChipDelete(data) : () => this.handleChipDeleteCat(data)}
                        />
                      )}
                    </Box>
                    <Box style={{ display: 'flex', gap: 15, alignItems: 'center', cursor: "pointer" }}>

                      <MenuIcon onClick={this.handleMenu} className='menu-icon' style={{ display: 'none' }} />
                      <img data-test-id="changeGridTest" src={this.state.grid4 ? gridButton : grid4Button2} onClick={this.changeGrid} />
                    </Box>
                  </Box >
                  <Box className="innerCard" style={{ display: 'flex', flexWrap: 'wrap', gap: 90, justifyContent: 'space-between' }}>
                    <Grid container spacing={3}>
                      {this.state.isSearchBox ?
                        this.renderSearchData()
                        :
                        this.renderMainData()
                      }
                    </Grid>
                  </Box>
                </Box>
              </Box>
              {this.renderPagination()}
            </Box>
          </ParentBox>
          <div><Footer /></div>
        </MainBox>
        <SnackBarAlert
          data-test-id="snackBarTestID"
          snackBarProps={this.state.hasSuccessErrorForCat}
          handleCloseSnack={()=> this.snackBarShowCat(false, "error", "")}
        />
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  padding: 2,
  "& .mainFavIconBox": {
    zIndex:1,
    position: "relative",
    display: "flex", 
    flexDirection: "column", 
    maxWidth: "254px", 
  },
  "& .favBox": {
    zIndex:9999,
    position: "absolute", 
    right: "12px", 
    bottom: "12px", 
    width:"24px",
    height:"20px",
    cursor: "pointer"
  },
  "& .mainBox": {
    marginTop:"38px"
  },
  "& .decreaseBUtton": {
    cursor:"not-allowed !important",
    pointerEvents:"none"
  },
  "& .emptyHeadigBlod": {
    fontWeight: "bold !important",
    fontSize: "40px !important",
  },
  "& .ordersHeadding": {
    fontSize: "24px",
    color: "#000",
    position: "relative",
    zIndex: 99,
    fontStyle: "normal",
    paddingBottom: "20px",
    fontWeight: 700,
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
  "& .emptyMainGrid": {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  "& .emptyMainBox": {
    width: "35%",
  },
  "& .wishlistEmpty": {
    height: "200px",
    paddingBottom: "20px",
    width: "200px",
  },
  "& .searchText": {
    color: "#fff",
    cursor: "pointer",
    fontSize: "12px",
    background:"#000",
    textAlign:'center',
    borderRadius:"8px",
    padding:"10px",
  },
  "& .searchtexthead": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }
});
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
};
// Customizable Area End
