
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_catalogue/catalogue";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.postAddToCartItemApiEndPoint = 'bx_block_shopping_cart/add_to_cart'
exports.postAddToWishlistItemApiEndPoint = 'bx_block_categories/add_to_wishlist'
exports.getFeedbackItemApiEndPoint = 'bx_block_catalogue/reviews?catalogue_id='
// Customizable Area End
