import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Typography,
  styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";

import { Singuptextborder, backgroundImage } from "../../../blocks/email-account-registration/src/assets";
import { checkmark } from "../../../blocks/forgot-password/src/assets";
import { trash, edit, AddImageIcon } from "./assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

// Customizable Area Start
  renderUserProfileFirstNameInput = () => {
    return (
      <Box className="ProfileFormFieldContainer">
        <Typography className="ProfileFormFieldLabel">First Name</Typography>
        <TextField
          error={this.state.firstNameError}
          helperText={
            this.state.firstNameError
              ? <span >Please enter your first name.</span>
              : ""
          }
          placeholder="First Name"
          className={this.state.firstNameError ? "profileFormInputBoxError" : "profileFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.firstName}
          onChange={this.handleProgileFirstNameInput}
          data-test-id="firstNameInputTestID"
        />
      </Box>
    )
  }

  renderUserProfileLastNameInput = () => {
    return (
      <Box className="ProfileFormFieldContainer">
        <Typography className="ProfileFormFieldLabel">Last Name</Typography>
        <TextField
          error={this.state.lastNameError}
          helperText={
            this.state.lastNameError
              ? <span>Please enter your last name.</span>
              : ""
          }
          placeholder="Last Name"
          className={this.state.lastNameError ? "profileFormInputBoxError" : "profileFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.lastNameProfie}
          onChange={this.handleProfileLasttNameInput}
          data-test-id="lastNameInputTestID"
        />
      </Box>
    )
  }

  renderUserProfileEmailInput = () => {
    return (
      <Box className="ProfileFormFieldContainer">
        <Typography className="ProfileFormFieldLabel">Email</Typography>
        <TextField
          error={this.state.emailError || this.state.blankEmailError} helperText={
            this.state.emailError || this.state.blankEmailError
              ? this.state.emailErrorMsg
              : ""
          }
          placeholder="Email"
          className={this.state.emailError || this.state.blankEmailError ? "profileFormInputBoxError" : "profileFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleProfileUserEmailInput}
          data-test-id="emailInputTestID"
          disabled
        />
      </Box>
    )
  }

  renderUseContactInput = () => {
    return (
      <Box className="ProfileFormFieldContainer">
        <Typography className="ProfileFormFieldLabel">Contact Number</Typography>
        <TextField
          error={this.state.contactError || this.state.contactBlank }
          helperText={this.getProfileContactErrorMessage()}
          placeholder="Contact Number"
          className={this.state.contactError || this.state.contactBlank ? "profileFormInputBoxError" : "profileFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.contact}
          onChange={this.handleUserProfileContactInput}
          data-test-id="numberInputTestID"
        />
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <div className="mainContainer">
        <HeaderNew navigation={this.props.navigation} id={""}/>
        </div>
        <MainUserProfileContainer container spacing={8}>
          <Grid item xs={12} sm={6} md={5} lg={7} className="mainleftGrid" >
            <Box className="profileTextHeaderMain">
              <Box className="profileTextHeader">
                <Typography className="profileUpText">
                  User Profile
                </Typography>
                <img src={Singuptextborder} alt="logintextborder" className="profiletextborder" />
              </Box>
            </Box>
            <Box className="profileMainInputBox">
              <Box className="sideborder">
                <img src={this.imageShowSrc()} alt="" className="profileImage"
                  data-test-id="profilePictureShowTestId"
                />
                <Box className="uploadbtnMainclass">
                  <label
                    htmlFor="contained-button-file"
                    className="ChangeProfileLabel"
                  >
                    <Box className="changeImage" ><img src={edit} alt="changeImageicon" className="changeImageicon" />Change Image</Box>
                    <input
                      data-test-id="profilePictureTestId"
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      className="UploadInputProfilePicture"
                      onChange={this.handleUploadProfilePicture}
                    />
                  </label>
                  {this.state.userProfilePicture || this.state.profilePictureForApi ?
                    <Button className="changeImage deleteImage" data-test-id="deleteProfilePicTestId" onClick={() => this.deleteProfilePic()} ><img src={trash} alt="changeImageicon" className="changeImageicon" />Delete Image</Button>
                    :
                    <label
                      htmlFor="contained-button-file"
                      className="ChangeProfileLabel"
                    >
                      <Box className="changeImage deleteImage" ><img src={AddImageIcon} alt="changeImageicon" className="changeImageicon" />Add Image</Box>
                      <input
                        data-test-id="profilePictureTestId"
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        className="UploadInputProfilePicture"
                        onChange={this.handleUploadProfilePicture}
                      />
                    </label>
                  }
                </Box>
              </Box>
              {this.state.properImageMessaeg
                  ? <span className="textAreaBox">Only JPEG, JPG, and PNG files are allowed.</span>
                  : ""}
              <form data-test-id="formSubmitTestID" autoComplete="off" className="login_Form_Main_Container" onSubmit={this.handleSubmitEditableChanges} >
                <Box className="inputDownMain">
                  <Grid item container >
                    <Typography className="PIText">Personal Information</Typography>
                    <Grid item lg={6} xs={12} className="girdeset">
                      {this.renderUserProfileFirstNameInput()}
                      {this.renderUserProfileEmailInput()}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      {this.renderUserProfileLastNameInput()}
                      {this.renderUseContactInput()}
                    </Grid>
                  </Grid>
                </Box>
                <Box className="savebtnmain">
                  <Button className="profileSaveButton" type="submit">Save Changes</Button>
                </Box>
              </form>
            </Box>
          </Grid>
          <Grid item xs={false} sm={6} md={7} lg={5}  >
            <Box className="image_Container_Box"> </Box>
          </Grid>
          {this.state.profileSuccessModel &&
            <SignUpSuccessModel
              data-test-id="successModalTestID"
              loginBtnClick={() => this.handleNavigateFromProfile("LandingPage")}
              image={checkmark} heading={"Your Profile have been saved"}
              description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br/>dolor sit amet, consectetur adipiscing elit"}
              showBtn={true}
              btnText={"Go to Home"}
            />
          }
        </MainUserProfileContainer>
        <Footer />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainUserProfileContainer = styled(Grid)({
  width: "100%",
  "& .mainContainer": {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },
  "& .profileSaveButton": {
    width: "241px",
    height:"52px",
    background: "#000",
    border: "none",
    color: "#ffff",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "initial",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .savebtnmain":{
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
  "& .ProfileFormFieldContainer": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .ProfileFormFieldLabel": {
    marginBottom: "4px",
    fontSize: "12px",
    color: "#000",
    fontStyle: "normal",
    lineHeight: "22px",
    fontWeight: 400,
    opacity: 0.5,
  },
  "& .girdeset":{
    paddingRight: "30px",
    "@media (min-width:320px) and (max-width:800px)": {
      paddingRight: "0px"
    },
  },
  "& .PIText":{
    width: "100%",
    color: "#000",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    padding: "30px 0px",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "18px !important",
      width: "100%",
    },
  },
  "& .textAreaBox":{
    color: "#DC2626",
    border: "none",
    width: "fit-content",
    fontSize: "12px",
    fontFamily: "sans-serif",
    fontWeight: 400,
    borderRadius: "6px",
    position: "relative",
  },
  "& .profileFormInputBox": {
    background: "transparent",
    border: "none",
    width: "100%",
    fontStyle: "normal",
    color: "#64748B",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: 400,
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "12px !important",
      width: "100%",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 8px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid black",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
  },
  "& .profileFormInputBoxError": {
    border: "none",
    width: "100%",
    background: "transparent",
    fontFamily: "Montserrat",
    color: "#64748B",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 8px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      width: "fit-content",
      color: "#DC2626",
      border: "none",
      fontSize: "12px",
      fontFamily: "sans-serif",
      borderRadius: "6px",
      position: "relative",
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .profileTextHeaderMain": {
    padding: "50px 8% 53px",
  },
  "& .inputDownMain": {
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "70px",
    "@media (min-width:320px) and (max-width:600px)": {
      paddingBottom: "20px",
     }
  },
  "& .UploadInputProfilePicture": {
    display: "none"
  },
  "& .ChangeProfileLabel": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .changeImageicon": {
    display: "flex",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px"
  },
  "& .deleteImage": {
    background: "#FFF !important",
    color: "#000 !important",
    border: "1px solid #000",
    width: "100%"
  },
  "& .changeImage": {
    borderRadius: "8px",
    background: "#000",
    color: "#FFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "initial",
    padding: "8px 15px",
    display: "flex",
    "@media (min-width:320px) and (max-width:1000px)": {
     justifyContent: "center",
     width: "100%"
    }
  },
  "& .uploadbtnMainclass": {
    height: "150px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  "& .profileImage": {
    width: "150px",
    height: "150px",
    flexShrink: 0,
    borderRadius: "75px",
  },
  "& .profileMainInputBox": {
    padding: "0px 8% 150px",
    borderRight: "1px solid #F2F2F2",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 8% 0px",
     }
  },
  "& .sideborder": {
    display: "flex",
    gap: "30px",
    paddingBottom: "30px",
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
    "@media (min-width:320px) and (max-width:1000px)": {
      display: "flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"
    }
  },
  "& .profileTextHeader": {
    position: "relative",
  },
  "& .profileUpText": {
    fontWeight: 700,
    fontSize: "40px",
    zIndex: 99,
    position: "relative",
    color: "#000",
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "20px !important",
    }
  },
  "& .profiletextborder": {
    top: "0px",
    left: "-23px",
    width: "99.167px",
    height: "35px",
    position: "absolute",
    "@media (min-width:320px) and (max-width:575px)": {
      top: "2px",
      left: "-23px",
      width: "62.167px",
      height: "18px",
    }
  },
  "& .image_Container_Box": {
    background: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "745px",
    margin: "163px 0 0 0",
    borderRadius: "20px",
  },
  "& .MuiGrid-item":{
    "@media (min-width:320px) and (max-width:575px)": {
      padding:"0px"
    }
  },
    "@media (min-width:320px) and (max-width:575px)": {
      margin:"0px !important"
    }
});
// Customizable Area End
