import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    navigation: {navigate:()=>{}, getParam:()=>{}, goBack:()=>{} };
    id: string;
    // Customizable Area End
}
  // Customizable Area Start
  

  // Customizable Area End

interface S {
  // Customizable Area Start
  aboutUsText:string;
  // Customizable Area End
}
interface SS {
    // Customizable Area Start 
    id: string|number;
    // Customizable Area End
}
export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  aboutUsID:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      , getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      aboutUsText:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson =
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) 
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && responseJson.data) {
        if(apiRequestCallId===this.aboutUsID){
            this.setState({aboutUsText:responseJson.data.attributes.description})
        }
      }
    
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
   this.getAboutData()
  }
  navigateToLandingPage(route: string) {
    const naviigateto = new Message(getName(MessageEnum.NavigationMessage));
    naviigateto.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    naviigateto.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(naviigateto);
  }
  
  getAboutData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.aboutUsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutUsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
