import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface PopularItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface PopularItemsResponse {
  data: PopularItem[];
}

interface CategoryAttributes {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
  image: string;
}
interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface CategoriesResponse {
  data: CategoryData[];
}


interface Product {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}
interface ProductData {
  data: Product[];
}

interface PartnerData {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface PartnersResponse {
  data: PartnerData[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popular_items: PopularItem[];
  categories: CategoryData[];
  products: Product[];
  partners: PartnerData[];
  loginToken: string;
  modalOpen: boolean;
  Carousels:any;
  carouselItem:any[];
  currentCarouselIndex:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      // LandingPage: {
      popular_items: [],
      categories: [],
      products: [],
      partners: [],
      loginToken: "",
      modalOpen: false,
      Carousels:[],
      carouselItem:[],
      currentCarouselIndex:0
      // },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson =
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ||
        {};
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(responseJson?.carousels){
        this.setState({carouselItem:responseJson?.carousels?.data})
      }
      if (apiRequestCallId === this.apiGetLandingPageCallId) {
        this.setState({ popular_items: responseJson.popular_items.data })
        this.setState({ categories: responseJson.categories.data })
        this.setState({ products: responseJson.products.data })
        this.setState({ partners: responseJson.partners.data })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    window.scroll(0,0);
    this.handleLandingPageAPI();
    this.setState({ loginToken: await getStorageData('token') });
    const Services = await getStorageData("Services");
    if (Services) {
      this.handleTitleClick(Services);
    }
  }

  async componentWillUnmount() {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
    removeStorageData("Services");
  }

  apiGetLandingPageCallId: string = "";

  handleLandingPageAPI = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {};
    this.apiGetLandingPageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleTitleClick = (title: string) => {
    if (title.toLowerCase() == "services") {
      this.setState({ modalOpen: true })
    }
    if (title.toLowerCase() == "spares") {
      this.handleNavigateFromLanding("BrandListing")
    }
    if (title.toLowerCase() == "retail") {
      this.handleNavigateFromLanding("Catalogue")
    }
    this.scrollToTop()
  }

  seriveceReq = () => {
    this.setState({ modalOpen: true })
  }
  handleNavigateFromLanding = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  closeModal = () => {
    this.setState({ modalOpen: false })
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  setCurrentCarouselIndex  = (index:number , prevIndex:number)=>{
    this.setState({currentCarouselIndex:index})

  }

  // Customizable Area End
}
