import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { City, Country, ICity, ICountry, IState, State } from "country-state-city";
import{getStorageData} from "../../../../packages/framework/src/Utilities";
export interface ValidResponseType {
  data: object;
  message: string;
}

export interface InvalidResponseType {
  errors: {
    token:string
  };
}

export interface InvalidResponseTypeError {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  firstNameContact:string;
  firstNameErrorContact: boolean;
  lastNameErrorContact: boolean;
  lastNameContact:string;
  addressContact: string;
  addressErrorContact :boolean;
  companyNameContact: string;
  companyNameErrorContact :boolean;
  postalCode: string;
  postalCodeBlankError :boolean;
  town: string;
  country: string;
  selectedCountryCode: string,
  countryBlankError :boolean;
  state: string;
  stateBlankError: boolean;
  city: string;
  cityBlankError: boolean;
  region: string;
  job: string;
  jobBlankError :boolean;
  website: string;
  websiteBlankError :boolean;
  message: string;
  MessageNumberBlankError :boolean;
  contactEmail:string,
  contactEmailError: boolean,
  contactBlankEmailError: boolean,
  contactEmailErrorMsg: string,
  contactNumber:string;
  contactNumberBlankError: boolean;
  contactNumberError: boolean;
  contactSuccessModel:boolean;
  hasSuccessErrorForContact: HasSuccessErrorType;
  countryList: ICountry[];
  stateList: IState[];
  cityList: ICity[];
  autoPopulateFirstName:string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  postContactApiCallId: string | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      firstNameContact:"",
      firstNameErrorContact: false,
      lastNameErrorContact: false,
      lastNameContact:"",
      addressContact: "",
      addressErrorContact: false,
      companyNameContact: "",
      companyNameErrorContact: false,
      postalCode: "",
      postalCodeBlankError: false,
      town: "",
      country: "",
      selectedCountryCode: '',
      countryBlankError: false,
      state:"",
      stateBlankError:false,
      city:"",
      cityBlankError: false,
      region: "",
      job: "",
      jobBlankError: false,
      website: "",
      websiteBlankError: false,
      message: "",
      MessageNumberBlankError: false,
      contactEmail:"",
      contactEmailError: false,
      contactBlankEmailError: false,
      contactEmailErrorMsg: "",
      contactNumber:"",
      contactNumberBlankError: false,
      contactNumberError: false,
      contactSuccessModel: false,
      autoPopulateFirstName:[],
      hasSuccessErrorForContact:{
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      countryList: [],
      stateList:[],
      cityList:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    let countryList = Country.getAllCountries()
    this.setState({
      countryList: countryList
    })
    
    const existingData = await getStorageData('userData');
    let parseData = []; 

    if (existingData) {
        parseData = JSON.parse(existingData);
        this.setState({firstNameContact:parseData.first_name,lastNameContact:parseData.last_name,contactEmail:parseData.email,contactNumber:parseData.phone_number})
     }

    if (Array.isArray(parseData)) {
      this.setState({ autoPopulateFirstName: [...parseData] });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseContact(responseJson)) {
        this.apiSuccessCallBacksContact(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseContact(responseJson)) {
        this.apiFailureCallBacksContact(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  handleCountry = (
    event:  any,
    newValue: ICountry | null
  ) => {
    if(newValue){
      let stateList = State.getStatesOfCountry(newValue.isoCode)
      this.setState({ 
        country : newValue.name, 
        stateList: stateList, 
        cityList: [],
        selectedCountryCode: newValue.isoCode,
        state: '',
        city: '',
        countryBlankError: false,
      })
    } else{
      this.setState({
        country: '',
        stateList: [],
        cityList: [],
        selectedCountryCode: '',
        state: '',
        city: '',
        countryBlankError: false,
      })
    }
  };

  handleCountryClose = () => {
    if(!this.state.country){
      this.setState({ 
        country: ''
      })
    }
  }

  handleState = (
    event: any,
    newValue: IState | null
  ) => {
    if(newValue){
      let cityList = City.getCitiesOfState(this.state.selectedCountryCode, newValue.isoCode)
      this.setState({ 
        state : newValue.name, 
        cityList: cityList,
        city: '',
        stateBlankError: false,
      })
    } else{
      this.setState({
        state: '',
        cityList: [],
        city: '',
        stateBlankError: false,
      })
    }
  }

  handleStateClose = () => {
    if(!this.state.state){
      this.setState({ 
        state: ''
      })
    }
  }

  handleCity = (
    event:any,
    newValue: ICity | null,
  ) => {
    if(newValue){
      this.setState({ 
        city: newValue.name,
        cityBlankError: false,
      })
    } else{
      this.setState({
        city: '',
        cityBlankError: false,
      })
    }
  }

  handleCityClose = () => {
    if(!this.state.city){
      this.setState({ 
        city: ''
      })
    }
  }

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  // -----

  handleAddressContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      addressContact: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        addressErrorContact: true
      });
    } else {
      this.setState({
        addressErrorContact: false
      });
    }
  };

  handleCompanyContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      companyNameContact: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        companyNameErrorContact: true
      });
    } else {
      this.setState({
        companyNameErrorContact: false
      });
    }
  };

  handlePostalCodeContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumberRegex = /^\d{0,10}$/;
    const inputValue = nameEvent.target.value.trim();
    if (phoneNumberRegex.test(inputValue) || inputValue === "") {
      this.setState({
        postalCode: nameEvent.target.value
      }, () => {
        if (nameEvent?.target.value === "") {
          this.setState({
            postalCodeBlankError: true,
          });
        }
        else {
          this.setState({
            postalCodeBlankError: false,
          });
        }
      });
    }
  };


  handleCountryContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      country: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        countryBlankError: true
      });
    } else {
      this.setState({
        countryBlankError: false
      });
    }
  };



  handleJobContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      job: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        jobBlankError: true
      });
    } else {
      this.setState({
        jobBlankError: false
      });
    }
  };

  handleMessageContactInput = (nameEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      message: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        MessageNumberBlankError: true
      });
    } else {
      this.setState({
        MessageNumberBlankError: false
      });
    }
  };

  handleWebsiteContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      website: nameEvent.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        websiteBlankError: true
      });
    } else {
      this.setState({
        websiteBlankError: false
      });
    }
  };

  handleFirstNameContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nameRegex = /^[A-Za-z]+$/;
    const trimmedValue = nameEvent.target.value;
    if (nameRegex.test(trimmedValue) || trimmedValue.trim() === "") {
      this.setState({
        firstNameContact: nameEvent.target.value
      });
    }
    if (nameEvent?.target.value === "") {
      this.setState({
        firstNameErrorContact: true
      });
    } else {
      this.setState({
        firstNameErrorContact: false
      });
    }
  };

  handleLastNameContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nameRegex = /^[A-Za-z]+$/;
    const trimmedValue = nameEvent.target.value;
    if (nameRegex.test(trimmedValue) || trimmedValue.trim() === "") {
      this.setState({
        lastNameContact: nameEvent.target.value
      });
    }
    if (nameEvent?.target.value === "") {
      this.setState({
        lastNameErrorContact: true
      });
    } else {
      this.setState({
        lastNameErrorContact: false
      });
    }
  };
  
  handleUserEmailContactInput = (emailEvent: React.ChangeEvent<HTMLInputElement>) => {
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const isValidEmail = emailReg.test(emailEvent.target.value)
    this.setState({
      contactEmail: emailEvent.target.value
    })
    if (emailEvent.target.value === "") {
      this.setState({
        contactEmailError: false,
        contactBlankEmailError: true,
        contactEmailErrorMsg: configJSON.emailErrorMsg
      });
    } else if (isValidEmail === false) {
      this.setState({
        contactEmailError: true,
        contactBlankEmailError: false,
        contactEmailErrorMsg: configJSON.emailInvalidErrorMsg
      });
    } else {
      this.setState({
        contactEmailError: false,
        contactBlankEmailError: false,
        contactEmailErrorMsg: ""
      });
    }
  };

  getContactNumberErrorMessage() {
    const { contactNumberError, contactNumberBlankError } = this.state;

    if (contactNumberBlankError) {
      return "Please enter your contact number.";
    } else if (contactNumberError) {
      return "Please enter a valid contact number.";
    }
    
    return null;
  }

  handleContactInput = (nameEvent: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumberRegex = /^\d{0,10}$/;
    const inputValue = nameEvent.target.value.trim();
    if (phoneNumberRegex.test(inputValue) || inputValue === "") {
      this.setState({
        contactNumber: nameEvent.target.value
      }, () => {
        if (nameEvent?.target.value === "") {
          this.setState({
            contactNumberBlankError: true,
            contactNumberError: false,
          });
        } else if (this.state.contactNumber.length !== 10) {
          this.setState({
            contactNumberError: true,
            contactNumberBlankError: false,
          });
        }
        else {
          this.setState({
            contactNumberError: false,
            contactNumberBlankError: false
          });
        }
      });
    }
  };

  apiCallContact = async (valueData: {
    endPoint?: string,
    method?: string,
    body?: {},
    contentType?: string,
    type?: string,
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    let token = (await StorageProvider.get("token")) || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    let requestMessageContact = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageContact.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageContact.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageContact.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageContact.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageContact.id, requestMessageContact);
    return requestMessageContact.messageId;
  };

  isValidResponseContact = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponseContact = (responseJson: InvalidResponseType & InvalidResponseTypeError) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacksContact = (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponseTypeError) => {
    if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
      this.errorsInvalidToken(responseJson)
    }
  }

  errorsInvalidToken = (responseJson: InvalidResponseTypeError) => {
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.snackBarShowContact(true, "error", "Please login to continue");
    }
  }

  apiSuccessCallBacksContact = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.postContactApiCallId) {
      this.setState({
       contactSuccessModel:true
      })
    }
  }

  handleNavigateFromContact = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  handleContact = async (preventEvent: { preventDefault: () => void; }) => {    
    preventEvent.preventDefault();
    const emailReg = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,}$/;
    const errors = {
      firstNameErrorContact: !this.state.firstNameContact,
      lastNameErrorContact: !this.state.lastNameContact,
      addressErrorContact: !this.state.addressContact,
      jobBlankError: !this.state.job,
      websiteBlankError: !this.state.website,
      countryBlankError: !this.state.country,
      stateBlankError: !this.state.state,
      cityBlankError: !this.state.city,
      MessageNumberBlankError: !this.state.message,
      postalCodeBlankError: !this.state.postalCode,
      companyNameErrorContact: !this.state.companyNameContact,
      contactBlankEmailError: !this.state.contactEmail,
      contactEmailError: !emailReg.test(this.state.contactEmail),
      contactNumberBlankError: !this.state.contactNumber,
      contactNumberError: this.state.contactNumber.length !== 10
    };
     
    if (!emailReg.test(this.state.contactEmail)) {
      this.setState({
        contactEmailErrorMsg: configJSON.emailInvalidErrorMsg,
      });
    }
    if (!this.state.contactEmail) {
      this.setState({
        contactEmailErrorMsg: configJSON.emailErrorMsg,
      });
    }

    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      let body = {
        data: {
          first_name: this.state.firstNameContact,
          last_name: this.state.lastNameContact,
          email: this.state.contactEmail,
          phone_number: `91${this.state.contactNumber}`,
          description: this.state.message,
          company_name: this.state.companyNameContact,
          address: this.state.addressContact,
          postal_code: this.state.postalCode,
          town: this.state.state,
          country: this.state.country,
          region: this.state.city,
          job_title: this.state.job,
          website: this.state.website
        }
      }
      this.postContactApiCallId = await this.apiCallContact({
        contentType: configJSON.contactUsApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.postContactAPIEndPoint,
        body: body
      });
    }
  };

  snackBarShowContact = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForContact: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };
  // Customizable Area End
}
