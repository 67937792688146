import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  styled,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Singuptextborder, backgroundImage } from "../../../blocks/email-account-registration/src/assets";
import { checkmark } from "../../../blocks/forgot-password/src/assets";
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
const options = ['Select', 'Option 2'];
import Autocomplete from "@material-ui/lab/Autocomplete";
// Customizable Area End

import ContactusController, { Props } from "./ContactusController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  renderAddressInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Address*</Typography>
        <TextField
          error={this.state.addressErrorContact}
          helperText={
            this.state.addressErrorContact
              ? <span>Please enter your address.</span>
              : ""
          }          
          placeholder="Address"
          className={this.state.addressErrorContact ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.addressContact}
          onChange={this.handleAddressContactInput}
          data-test-id="addressInputTestID"
        />
      </Box>
    )
  }

  renderContactCompanyNameInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Company Name*</Typography>
        <TextField
          data-test-id="companyNameInputTestID"
          error={this.state.companyNameErrorContact}
          helperText={
            this.state.companyNameErrorContact
              ? <span>Please enter your company name.</span>
              : ""
          }          
          placeholder="Company Name"
          className={this.state.companyNameErrorContact ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.companyNameContact}
          onChange={this.handleCompanyContactInput}
        />
      </Box>
    )
  }

  renderCountryInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Country*</Typography>
        <Autocomplete 
          value={this.state.countryList.find(country => country.name === this.state.country) || null}
          placeholder="Country"
          id="outlined-basic"
          options={this.state.countryList}
          getOptionLabel={(option)=> option.name}
          onChange={this.handleCountry}
          renderInput={(params) => <TextField {...params} label="" placeholder="Country" variant="outlined" />}
          onInputChange={this.handleCountryClose}
          className={this.state.countryBlankError ? "contactFormInputBoxError errorDropdown" : "contactFormInputBox dropdown"}
          data-test-id='countryInputTestID'
        />
        {this.state.countryBlankError && ( <span className="errorText">Please enter your country</span>)}
      </Box>
    )
  }

  renderStateInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">State*</Typography>
        <Autocomplete
         value={this.state.stateList.find(state => state.name === this.state.state) || null} 
         placeholder="State"
         id="outlined-basic"
         options={this.state.stateList}
         getOptionLabel={(option)=> option.name}
         onChange={this.handleState}
         renderInput={(params) => <TextField {...params} label="" placeholder="State" variant="outlined" />}
         onInputChange={this.handleStateClose}
         className={this.state.stateBlankError ? "contactFormInputBoxError errorDropdown" : "contactFormInputBox dropdown"}
         data-test-id='townInputTestID'
        />
        {this.state.stateBlankError &&( <span className="errorText">Please enter your state</span>)}
      </Box>
    )
  }

  renderCityInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">City*</Typography>
        <Autocomplete
         value={this.state.cityList.find(city => city.name === this.state.city) || null} 
         placeholder="City"
         id="outlined-basic"
         options={this.state.cityList}
         getOptionLabel={(option)=> option.name}
         onChange={this.handleCity}
         renderInput={(params) => <TextField {...params} label="" placeholder="City" variant="outlined" />}
         onInputChange={this.handleCityClose}
         className={this.state.cityBlankError ? "contactFormInputBoxError errorDropdown" : "contactFormInputBox dropdown"}
         data-test-id='regionInputTestID'
        />
        {this.state.cityBlankError && ( <span className="errorText">Please enter your city</span>)}
      </Box>
    )
  }

  renderPincodeInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Pincode*</Typography>
        <TextField
          error={this.state.postalCodeBlankError}
          helperText={
            this.state.postalCodeBlankError
              ? <span>Please enter your pincode.</span>
              : ""
          }          
          placeholder="Pincode"
          className={this.state.postalCodeBlankError ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.postalCode}
          onChange={this.handlePostalCodeContactInput}
          data-test-id="postalCodeInputTestID"
        />
      </Box>
    )
  }

  renderJobTitleInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Job Title*</Typography>
        <TextField
          error={this.state.jobBlankError}
          helperText={
            this.state.jobBlankError
              ? <span>Please enter your job title.</span>
              : ""
          }          
          placeholder="Job Title"
          className={this.state.jobBlankError ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.job}
          onChange={this.handleJobContactInput}
          data-test-id="jobInputTestID"
        />
      </Box>
    )
  }

  renderWebsiteInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Website*</Typography>
        <TextField
          error={this.state.websiteBlankError}
          helperText={
            this.state.websiteBlankError
              ? <span>Please enter your website.</span>
              : ""
          }          
          placeholder="Website"
          className={this.state.websiteBlankError ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.website}
          onChange={this.handleWebsiteContactInput}
          data-test-id="websiteInputTestID"
        />
      </Box>
    )
  }

  renderFirstNameInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">First Name*</Typography>
        <TextField
          error={this.state.firstNameErrorContact}
          helperText={
            this.state.firstNameErrorContact
              ? <span>Please enter your first name.</span>
              : ""
          }          
          placeholder="First Name"
          className={this.state.firstNameErrorContact ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.firstNameContact}
          onChange={this.handleFirstNameContactInput}
          data-test-id="firstnameInputTestID"
        />
      </Box>
    )
  }
  

  renderLastNameInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Last Name*</Typography>
        <TextField
          variant="outlined"
          helperText={
            this.state.lastNameErrorContact
              ? <span>Please enter your last name.</span>
              : ""
          }  
          placeholder="Last Name"
          error={this.state.lastNameErrorContact}
          className={this.state.lastNameErrorContact ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          value={this.state.lastNameContact}
          data-test-id="lastnameInputTestID"
          type='text'
          onChange={this.handleLastNameContactInput}
          name="Address"
        />
      </Box>
    )
  }

  renderEmailInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Email*</Typography>
        <TextField
          error={this.state.contactEmailError || this.state.contactBlankEmailError} helperText={
            this.state.contactEmailError || this.state.contactBlankEmailError
              ? <span >{this.state.contactEmailErrorMsg}</span>
              : ""
          }
          placeholder="Email"
          className={this.state.contactEmailError || this.state.contactBlankEmailError ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.contactEmail}
          onChange={this.handleUserEmailContactInput}
          data-test-id="emailInputTestID"
        />
      </Box>
    )
  }
  
  renderContactInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Contact Number*</Typography>
        <TextField
          error={this.state.contactNumberError || this.state.contactNumberBlankError}
          helperText={this.getContactNumberErrorMessage()}
          placeholder="Contact Number"
          className={this.state.contactNumberError || this.state.contactNumberBlankError ? "contactFormInputBoxError" : "contactFormInputBox"}
          id="outlined-basic"
          variant="outlined"
          value={this.state.contactNumber}
          onChange={this.handleContactInput}
          data-test-id="numberInputTestID"
        />
      </Box>
    )
  }

  renderMessageInput = () => {
    return (
      <Box className="contactFormFieldContainer">
        <Typography className="contactFormFieldLabel">Message*</Typography>
        <TextareaAutosize
          maxRows={2}
          aria-label="maximum height"
          placeholder="Message"
          value={this.state.message}
          className={this.state.MessageNumberBlankError ? "textAreasError" : "textAreas"}
          onChange={this.handleMessageContactInput}
          data-test-id="messageInputTestID"

        />
        { this.state.MessageNumberBlankError
              ? <span className="textAreaBox">Please enter your message.</span>
              : ""}
      </Box>
    )
  }

  // Customizable Area End
  render() {
    return (
      <>
      {/* Customizable Area Start */}
        <>
          <div className="mainContainer">
          <HeaderNew navigation={this.props.navigation} id={""}/>
          </div>
          <MainContactContainer container spacing={8}>
            <Grid item xs={12} sm={6} md={5} lg={7} className="mainleftGrid" >
              <Box className="profileTextHeaderMain">
                <Box className="profileTextHeader">
                  <Typography className="profileUpText">
                    Contact Form
                  </Typography>
                  <img src={Singuptextborder} alt="logintextborder" className="profiletextborder" />
                </Box>
              </Box>
              <Box className="profileMainInputBox">
                <Box className="sideborder">
              
                </Box>
                <form data-test-id="formSubmitTestID" autoComplete="off" className="login_Form_Main_Container" onSubmit={this.handleContact} >
                  <Box className="inputDownMain">
                    <Grid item container spacing={4} >
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderContactCompanyNameInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderAddressInput()}
                      </Grid>
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderCountryInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderStateInput()}
                      </Grid>
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderCityInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderPincodeInput()}
                      </Grid>
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderJobTitleInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderWebsiteInput()}
                      </Grid>
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderFirstNameInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderLastNameInput()}
                      </Grid>
                      <Grid item lg={6} xs={12} className="girdeset">
                        {this.renderEmailInput()}
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        {this.renderContactInput()}
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        {this.renderMessageInput()}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="savebtnmain">
                    <Button className="profileSaveButton" type="submit">Confirm & Send Form</Button>
                  </Box>
                </form>
              </Box>
            </Grid>
            <Grid item xs={false} sm={6} md={7} lg={5}  >
              <Box className="image_Container_Box"> </Box>
            </Grid>
            {this.state.contactSuccessModel &&
              <SignUpSuccessModel
                data-test-id="successModalTestID"
                loginBtnClick={() => this.handleNavigateFromContact("LandingPage")}
                image={checkmark} heading={"Your message has been successfully sent!"}
                description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br/>dolor sit amet, consectetur adipiscing elit"}
                showBtn={true}
                btnText={"Home"}
              />
            }
          </MainContactContainer>
          <Footer />
          <SnackBarAlert
          data-test-id="snackBarTestID"
          snackBarProps={this.state.hasSuccessErrorForContact}
          handleCloseSnack={()=> this.snackBarShowContact(false, "error", "")}
        />
        </>
      {/* Customizable End Start */}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const MainContactContainer = styled(Grid)({
  width: "100%",
  "& .mainContainer": {
    justifyContent: "center",
    padding: 2,
    alignItems: "center",
  },
  "& .profileSaveButton": {
    width: "241px",
    height: "52px",
    border: "none",
    background: "#000",
    color: "#ffff",
    fontFamily: "Montserrat",
    textTransform: "initial",
    fontSize: "16px",
    lineHeight: "normal",
    fontStyle: "normal",
    fontWeight: 400,
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
    borderRadius: "8px",
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .savebtnmain": {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  "& .contactFormFieldContainer": {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .contactFormFieldLabel": {
    color: "#000",
    marginBottom: "4px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    opacity: 0.5,
  },
  "& .dropdown":{
    "& .MuiAutocomplete-inputRoot":{  
      padding: '0px'
    }
  },
  "& .errorDropdown":{
    "& .MuiAutocomplete-inputRoot":{  
      padding: '0px'
    }
  },
  "& .girdeset": {
    "@media (min-width:320px) and (max-width:800px)": {
      paddingRight: "0px"
    },
  },
  "& .contactSelect":{
    "& .MuiInputBase-root": {
      height:"36.5px",
      alignContent:"center",
      display:"flex",
    }
  },
  "& .contactSelectError":{
    "& .MuiInputBase-root": {
      height:"36.5px",
      alignContent:"center",
      display:"flex",
    }
  },
  "& .textAreas":{
    border: "1px solid black",
    borderRadius: "8px",
    width:"97%",
    padding: "10px 8px",
  },
  "& .textAreasError":{
    border: "1px solid #EE3D3D",
    borderRadius: "8px",
    width:"97%",
    padding: "10px 8px",
  },
  "& .errorText":{
    border: "none",
    color: "#DC2626",
    width: "fit-content",
    fontFamily: "sans-serif",
    fontSize: "12px",
    position: "relative",
    fontWeight: 400,
    borderRadius: "6px",
    marginTop:'3px',
  },
  "& .textAreaBox":{
    color: "#DC2626",
    border: "none",
    width: "fit-content",
    fontSize: "12px",
    fontFamily: "sans-serif",
    fontWeight: 400,
    borderRadius: "6px",
    position: "relative",
  },
  "& .contactFormInputBox": {
    background: "transparent",
    width: "100%",
    color: "#64748B",
    border: "none",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Montserrat",
    lineHeight: "24px",
    fontWeight: 400,
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "@media (min-width:320px) and (max-width:800px)": {
      width: "100%",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderColor: "black",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-root": {
      marginBottom: "20px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid black",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
  },
  "& .contactFormInputBoxError": {
    border: "none",
    background: "transparent",
    width: "100%",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    color: "#64748B",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 8px",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0px !important",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontSize: "14px",
      fontWeight: 700,
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      border: "none",
      color: "#DC2626",
      width: "fit-content",
      fontFamily: "sans-serif",
      fontSize: "12px",
      position: "relative",
      fontWeight: 400,
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid #EE3D3D",
      },
      "& fieldset": {
        border: "1px solid #EE3D3D",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        border: "1px solid #EE3D3D",
      },
    },
  },
  "& .inputDownMain": {
    paddingBottom: "10px",
    "@media (min-width:320px) and (max-width:600px)": {
      paddingBottom: "20px",
    }
  },
  "& .profileTextHeaderMain": {
    padding: "50px 8% 53px",
  },
  "& .profileMainInputBox": {
    borderRight: "1px solid #F2F2F2",
    padding: "0px 8% 150px",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 8% 0px",
    }
  },
  "& .sideborder": {
    display: "flex",
    gap: "30px",
    paddingBottom: "30px",
    justifyContent: "space-between",
    alignItems:"center",
    width: "100%",
    "@media (min-width:320px) and (max-width:1000px)": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    }
  },
  "& .profileTextHeader": {
    position: "relative",
  },
  "& .profileUpText": {
    fontWeight: 700,
    fontSize: "40px",
    zIndex: 99,
    color: "#000",
    fontStyle: "normal",
    position: "relative",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "20px !important",
    }
  },
  "& .profiletextborder": {
    position: "absolute",
    left: "-23px",
    width: "99.167px",
    height: "35px",
    top: "0px",
    "@media (min-width:320px) and (max-width:575px)": {
      height: "18px",
      top: "2px",
      width: "62.167px",
      left: "-23px",
    }
  },
  "& .image_Container_Box": {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "745px",
    borderRadius: "20px",
    background: `url(${backgroundImage})`,
    margin: "163px 0 0 0",
  },
  "& .MuiGrid-item": {
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px"
    }
  },
  "@media (min-width:320px) and (max-width:575px)": {
    margin: "0px !important"
  }
});
// Customizable Area End
