import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";

import { createTheme, styled } from "@material-ui/core/styles";
import { closeIcon, error, handsImage, rectangleBottom, rectangleTop } from "./assets";
import {EquipmentType} from './RequestManagementController';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SignUpSuccessModel from '../../../components/src/SignUpSuccessModel.web';

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const OverlayDiv = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0, 
  right: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#00000050',
  backdropFilter: 'blur(2px)',
  zIndex:1111
})
const ModalDiv = styled('div')({
  maxWidth: '70%',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '50px 100px',
  height: '75vh',
  margin: 'auto',
  position: 'absolute',
  top: '50%',
  zIndex: 9999999,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& select': {
    width: '100%',
    background: 'white',
    border: '1px solid black',
    borderRadius: '6px',
    padding: '10px 8px',
    fontSize: 14,
  },
  '& select:invalid': {
    color: 'gray'
  }
})
const CustomInputsBox = styled(Box)({
  "& .customInputField": {
    "& .MuiAutocomplete-inputRoot":{
      paddingLeft:"4px !important",
      padding:"unset !important",
    },
    width: "100%",
    background: "transparent",
    border: "none",
    color: "#64748B",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      // marginBottom: "10px",
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid black",
        borderRadius: "8px",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
    border:"1px solid black",
    outline: 'none'
  },
  "& .MuiOutlinedInput-multiline":{
    border:"1px solid black"
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    outline: 'none'
  }
})
const ErrorTag = styled(Typography)({
  fontSize: 12,
  fontWeight:400,
  color: 'red',
});


// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderForm() {
    return (
      <>
        <Box style={{ display: "flex", flexDirection: "column", gap: 33, height: 'inherit', overflow: 'auto', marginRight: -100, paddingRight: 100 }}>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography style={{ fontSize: 40, fontWeight: 700, lineHeight: "50.2px", color: "#000" }}>Service Request</Typography>
            <img data-test-id="crossTestID" style={{ cursor: "pointer" }} src={closeIcon} alt="close Icon" width={24} height={24} onClick={this.closeModal} />
          </Box>
          <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <Box style={{ display: "flex", gap: 30, justifyContent: "space-between" }}>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>First Name*</Typography>
                <TextField
                  error={false}
                  placeholder="First Name"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.firstName}
                  onChange={this.handleFirstName}
                  data-test-id="emailInputTestID1"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                  required
                />
                {this.state.firstNameError !== "" && (
                  <ErrorTag>{this.state.firstNameError}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Last Name*</Typography>
                <TextField
                  error={false}
                  placeholder="Last Name"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.lastName}
                  onChange={this.handleLastName}
                  data-test-id="emailInputTestID"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.lastNameError !== "" && (
                  <ErrorTag>{this.state.lastNameError}</ErrorTag>
                )}
              </CustomInputsBox>
            </Box>
            <Box style={{ display: "flex", gap: 30, justifyContent: "space-between" }}>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Enter Email*</Typography>
                <TextField
                  error={false}
                  placeholder="Enter Email"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleEmail}
                  data-test-id="emailInputTestID3"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.emailError !== "" && (
                  <ErrorTag>{this.state.emailError}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Your Phone*</Typography>
                <TextField
                  error={false}
                  placeholder="Your Phone"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.phone}
                  onChange={this.handlePhone}
                  data-test-id="emailInputTestID4"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.phoneError !== "" && (
                  <ErrorTag>{this.state.phoneError}</ErrorTag>
                )}
              </CustomInputsBox>
            </Box>
            <Box style={{ display: "flex", gap: 30, justifyContent: "space-between" }}>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Address 1 (Housing No, Flat No)</Typography>
                <TextField
                  error={false}
                  placeholder="Address Line 1"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.address1}
                  onChange={this.handleAddress1Ch}
                  data-test-id="emailInputTestID5"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.address1Error !== "" && (
                  <ErrorTag>{this.state.address1Error}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Address 2 (Area)</Typography>
                <TextField
                  error={false}
                  placeholder="Address Line 2"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.address2}
                  onChange={this.handleAddress2Ch}
                  data-test-id="emailInputTestID6"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.address2Error !== "" && (
                  <ErrorTag>{this.state.address2Error}</ErrorTag>
                )}
              </CustomInputsBox>
            </Box>
            <Box style={{ display: "flex", gap: 15, justifyContent: "space-between" }}>
              <CustomInputsBox style={{ minWidth: "32%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>State</Typography>
                <Autocomplete
                  data-test-id="emailInputTestID7"
                  id="controllable-states-demo"
                  options={this.state.stateList}
                  getOptionLabel={(option) => option.name}
                  onChange={this.handleState}
                  className="customInputField"
                  renderInput={(params) => <TextField {...params} label="" placeholder="State" variant="outlined" />}
                  placeholder="State"
                  onInputChange= {this.handleStateClose}
                />
                {this.state.stateError !== "" && (
                  <ErrorTag>{this.state.stateError}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "32%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>City</Typography>
                <Autocomplete
                  data-test-id="emailInputTestID8"
                  id="controllable-states-demo"
                  options={this.state.cityList}
                  getOptionLabel={(option) => option.name}
                  onChange={this.handleCity}
                  className="customInputField"
                  renderInput={(params) => <TextField {...params} label="" placeholder="City" variant="outlined" />}
                  placeholder="City"
                  onInputChange= {this.handleCityClose}
                />
                {this.state.cityError !== "" && (
                  <ErrorTag>{this.state.cityError}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "32%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Pincode</Typography>
                <TextField
                  error={false}
                  placeholder="Pincode"
                  id="outlined-basic"
                  variant="outlined"
                  value={this.state.pincode}
                  onChange={this.handlePincode}
                  data-test-id="emailInputTestID9"
                  style={{ minWidth: "100%" }}
                  className="customInputField"
                />
                {this.state.pincodeError !== "" && (
                  <ErrorTag>{this.state.pincodeError}</ErrorTag>
                )}
              </CustomInputsBox>
            </Box>
            <Box style={{ display: "flex", gap: 30, justifyContent: "space-between" }}>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Types of Equipments</Typography>
                <Autocomplete
                  data-test-id="selectEquipmentID"
                  id="controllable-states-demo"
                  options={this.state.equipmentType}
                  getOptionLabel={(option) => option.name}
                  onChange={this.selectEquipmentID}
                  className="customInputField"
                  renderInput={(params) => <TextField {...params} label="" placeholder="Equipments" variant="outlined" />}
                  placeholder="Equipments"
                  onInputChange={this.selectEquipmentClose}
                />
                {this.state.equipmentTypeError !== "" && (
                  <ErrorTag>{this.state.equipmentTypeError}</ErrorTag>
                )}
              </CustomInputsBox>
              <CustomInputsBox style={{ minWidth: "48%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400, opacity: "50%" }}>Description to Problem</Typography>
                <TextField
                  multiline
                  variant="outlined"
                  inputProps={{ style: { resize: "none" } }}
                  value={this.state.problemDescription}
                  onChange={this.handleDescriptionCh}
                  data-test-id="emailInputTestID10"
                />
                {this.state.problemDescriptionError !== "" && (
                  <ErrorTag>{this.state.problemDescriptionError}</ErrorTag>
                )}
              </CustomInputsBox>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button data-test-id="checkValidation" onClick={this.handleCheckValidation} style={{ minHeight: "52px", minWidth: "129px", background: "black", color: "white", textTransform: "initial", borderRadius: "5px", fontSize: 16, fontWeight: 400, lineHeight: "20.50px", textAlign: "center" }}>Submit</Button>
          </Box>
        </Box>
      </>
    )
  }

  rendeMainSection() {
    return (
      <>
      <OverlayDiv />
        <ModalDiv>
          <img src={rectangleTop} alt="" style={{ position: "absolute", top: "-20px", left: "-50px" }} />
          {this.state.loginToken !== "" && this.state.loginToken !== null ?
            !this.state.serviceFormSubmitted && this.renderForm()
            :
            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 30, margin: "50px 100px" }}>
              <img src={error} />
              <IconButton style={{position:"absolute",top:10,right:10}} onClick={this.closeModal} >
                <img src={closeIcon} data-test-id="crossTestID" alt=""style={{width:'24px' ,height:'24px'}} />
              </IconButton>
              <Typography style={{ fontSize: 40, fontWeight: 700, lineHeight: "50.2px", textAlign: "center" }} >Error request!</Typography>
              <Typography style={{ fontSize: 20, fontWeight: 400, lineHeight: "32px", textAlign: "center" }}>Please login to continue</Typography>
              <Button
                data-test-id="loginPage"
                onClick={() => this.navigateToLandingPage("login")} style={{ minHeight: "56px", minWidth: "300px", background: "black", color: "white", textTransform: "initial", borderRadius: "5px", fontSize: 16, fontWeight: 400, lineHeight: "20.50px", textAlign: "center" }}>Login</Button>
            </Box>
          }

          <img src={rectangleBottom} alt="" style={{ position: "absolute", right: "-20px", bottom: "-50px" }} />
        </ModalDiv>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        {!this.state.serviceFormSubmitted && this.rendeMainSection()}
        {this.state.serviceFormSubmitted && (
          <SignUpSuccessModel
          data-test-id="successModalTestID"
          loginBtnClick={this.closeModal}
          image={handsImage} heading={"Service request raised successfully"}
          description={"Admin will review and get in <br/>touch within 4 - 12 hours!"}
          showBtn={true}
          btnText={"Home"}
        />
        )}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 20,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
};
// Customizable Area End
