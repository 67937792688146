import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";   

export interface ValidResponseType {
  data: object;
  message: string;
}

export interface InvalidResponseType {
  message: string;
}

type Data = {
  [key: string]: string;
};

type ConvertedData = {
  title: string;
  description: string;
}[];

type TermsAndConditionsAttributes = {
  title_1: string;
  description_1: string;
  title_2: string;
  description_2: string;
  title_3: string;
  description_3: string;
  title_4: string;
  description_4: string;
  created_at: string; 
  updated_at: string; 
};

type TermsAndConditionsData = {
  id: string;
  type: "terms_and_condition";
  attributes: TermsAndConditionsAttributes;
};

type TermsAndConditionsResponse = {
  data: TermsAndConditionsData;
  meta: {
    message: string;
  };
};

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  termsAndConditions: ConvertedData
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermAndConditionApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
        termsAndConditions:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseTermAndCondition(responseJson)) {
        this.apiSuccessCallBacksTermsAndCondition(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseTermAndCondition(responseJson)) {
        this.apiFailureCallBacksTermsAndCondition(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getTermAndContionsDataShow()
  }

  apiCallTermsAndConditions = async (valueData: {
    method?: string,
    endPoint?: string,
    body?: {},
    type?: string,
    contentType?: string,
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    let token = (await StorageProvider.get("token")) || "";
    let header = {
      "Content-Type": contentType,
      token
    };
    let requestMessageTermsAndConditions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageTermsAndConditions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
      requestMessageTermsAndConditions.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body &&
        requestMessageTermsAndConditions.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
    requestMessageTermsAndConditions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageTermsAndConditions.id, requestMessageTermsAndConditions);
    return requestMessageTermsAndConditions.messageId;
  };

  isValidResponseTermAndCondition = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponseTermAndCondition = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.message;
  };

  apiSuccessCallBacksTermsAndCondition = (apiRequestCallId: string, responseJson: TermsAndConditionsResponse) => {
    const array = this.convertDataToArrayOfObjects(responseJson.data.attributes);
    if (apiRequestCallId === this.getTermAndConditionApiCallId) {
      this.setState({
        termsAndConditions: array
      })
    }

  };

  apiFailureCallBacksTermsAndCondition = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getTermAndConditionApiCallId) {
      this.setState({
        termsAndConditions: []
      })
    }
  };

 convertDataToArrayOfObjects = (data: Data): ConvertedData => {
    const keys = Object.keys(data);
    const result: ConvertedData = [];

    keys.forEach((key) => {
      if (key.startsWith('title')) {
        const index = key.split('_')[1];
        const title = data[`title_${index}`];
        const description = data[`description_${index}`];

        result.push({ title, description });
      }
    });

    return result;
  };
  getTermAndContionsDataShow = async () => {
    this.getTermAndConditionApiCallId = await this.apiCallTermsAndConditions({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTermsAndConditionsApiEndPoint,
      type: ""
    });
  };
  
  // Customizable Area End
}
