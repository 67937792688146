import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { DummyProfile } from "../../customisableuserprofiles/src/assets";
export const configJSONBase = require("../../../framework/src/config");
import StorageProvider from "../../../framework/src/StorageProvider";
export interface GetUserProfileResponseType {
  message: string;
  data: {
    attributes: {
      full_name: string;
      phone_number: string;
      photo: string;
    }
  }
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleService?: (() => void);
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userProfileHover: boolean;
  logout: boolean;
  token: string;
  full_name: string,
  contact: string,
  userProfilePicture: string,
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class HeaderNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDataApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      , getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      userProfileHover: false,
      logout: false,
      token: "",
      full_name: "",
      contact: "",
      userProfilePicture: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getProfileDataApiCallId) {
        if (responseJson.data) {
          this.getProfileDataSuccessCallBack(responseJson);
        } else if (responseJson.errors) {
          this.apiFailureCallBacksHeader(apiRequestCallId, responseJson)
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getProfileDataSuccessCallBack = (resp: GetUserProfileResponseType) => { 
    StorageProvider.set("userData",JSON.stringify(resp.data.attributes))
    this.setState({
      full_name: resp.data.attributes.full_name,
      contact: resp.data.attributes.phone_number,
      userProfilePicture: resp.data.attributes.photo
    })
  }

  apiFailureCallBacksHeader = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType
  ) => {
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.navigateToLandingPage("login");
    }
  };

  userProfileHover = async () => {
    this.setState({
      userProfileHover: true
    })
  }
  async componentDidMount() {

    const token = await getStorageData("token");
    this.setState({ token: token })
    this.getProfileData()
  }

  userProfileHoverFalse = async () => {
    this.setState({
      userProfileHover: false
    })
  }

  handleLogout = async () => {
    removeStorageData("token")
    this.setState({
      logout: true
    }, () => this.navigateToLandingPage("login"))
  }

  navigateToLandingPage(route: string) {
    const naviigateto = new Message(getName(MessageEnum.NavigationMessage));
    naviigateto.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    naviigateto.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(naviigateto);
  }

  handleServiceReq = () => {
    this.navigateToLandingPage("LandingPage");
    setStorageData("Services", "Services");
    if (this.props.handleService) {
      this.props.handleService();
    }
  }

  imageShowSrc = () => {
    const { userProfilePicture } = this.state;
    if (userProfilePicture) {
      return configJSONBase.baseURL + userProfilePicture
    } else {
      return DummyProfile;
    }
  }

  getProfileData = async () => {
    let userId = (await StorageProvider.get("userId")) || "";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileEndPoint + userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleActive=(currentPathname:string,pathName:string)=>{
    if(currentPathname===pathName){
      return 900
    }
    return 400
  }
  // Customizable Area End
}
