import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core";
import {
  background,
  rectangle,
} from "./assets";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";

const CategoryDiv = styled('div')({
  '@media(max-width: 600px)': {
    '& .category-head-text': {
      fontSize: '22px !important'
    },
    '& .gradient-box': {
      bottom: '70px !important'
    }
  }
})
const MainDiv = styled('div')({
  '@media(max-width: 992px)': {
    '& .category-card': {
      width: '80vw !important'
    },
    '& .carousel-inner-content': {
      flexDirection: 'column',
      left: '20% !important',
      width: '100% !important',
      '& h2': {
        width: '100% !important',
        margin: '0px !important'
      }
    },
    '& .rectangle-img': {
      display: 'none'
    },
    '& .carousel-description': {
      width: '80vw !important',
      marginBottom: '20px !important'
    },
  },
  '@media(max-width: 600px)': {
    '& .category-card': {
      width: '90vw !important'
    },
    '& button[data-test-id="prev"], button[data-test-id="next"]': {
      width: 'unset !important'
    },
    '& .popular-item': {
      marginLeft: '0px !important'
    },
    '& .popular-sub-item': {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      width: 'unset !important',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .second-div': {
      marginLeft: '0px !important',
      marginBottom: '0px !important'
    },
    '& .carousel-container': {
      height: '400px !important'
    }
  },
})
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import RequestManagement from "../../requestmanagement/src/RequestManagement.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start

  renderCarousel = () => {
    const carouselItem = this.state.carouselItem[this.state.currentCarouselIndex]
    return(
     <div className="carousel-container" style={{...styles.carouselContainer,  position: 'relative'}}>
       <div style={{...styles.carousel, position: 'relative'}}>
        <CarouselDisplay navigation={undefined} id={""} carousel={this.state.carouselItem} setCurrentCarouselIndex={this.setCurrentCarouselIndex}   />
        <div style={{...styles.carouselContent, position: 'absolute', textAlign: 'center',}}>
          <div className="carousel-inner-content" style={{...styles.carouselInnerContent, position: 'absolute'}}>
           <div style={{...styles.contentLeft, textAlign: 'left',}}>
               <h2 style={{...styles.carouselHeading, fontWeight: 'lighter',}}>QED FoodTech</h2>
           </div>
           <div className="rectangle-img">
          <img src={rectangle} alt= "rectangle"/>
           </div>
           <div className="second-div" style={{...styles.contentRight,  textAlign: 'left',}}>
             <p className="carousel-description" style={styles.carouselDescription}>
              {carouselItem?.attributes?.carousel_description}</p>
                    <button
                   style={styles.carouselButton}
                   data-test-id="shopNow"
           >Shop Now</button>
           </div>
       </div>
    </div>
  </div>
 </div>
)
   }
   

  //Popular Items card
  renderCard = (item: any) => {
    return (
      <div
        key={item.id}
        style={styles.cardContainer}
      >
        <img src={item.attributes.image} alt={item.attributes.title} style={styles.cardImage} />
        <div style={styles.cardDetails}>
          <p style={styles.cardTitle}>{item.attributes.title}</p>
          <button
            style={{...styles.readMoreButton,paddingInline:0}}
          >
            <span style={styles.readMoreButtonText}>Read More</span>
          </button>
        </div>
      </div>
    );
  };

  renderCards = () => {
    return this.state.popular_items.map((item: any) =>
      this.renderCard(item)
    );
  };
  //Product Cards
  renderProducts = () => {
    return this.state.products.map((item: any) => (
      <div
        key={item.id}
        style={{ ...styles.productContainer, position: "relative" }}
        data-test-id="productdiv"
      >
        <img src={item.attributes.image} alt={item.title} style={{ objectFit: "cover" }} />
      </div>
    ));
  };

  //Partner Cards
  renderPartner = () => {
    return this.state.partners.map((item: any) => (
      <div
        key={item.id}
    >
        <img src={item.attributes.image} />
      </div>
    ));
  }

  //Category Card

  renderCategory = () => {
    return this.state.categories.map((item: any, index:number) => {
      
  return (
    <CategoryDiv key={item.id} style={{...styles.categoryContainer, position:"relative"}}>
      <div
        key={item.id}
      >
        <div className="gradient-box" style={{...styles.gradientOverlay, position:"absolute"}}></div>
        <img  data-test-id="imgTitle" style = {{width:"23vw"}} src={item.attributes.image} alt={item.title} onClick={()=> this.handleTitleClick(item.attributes.title)
} />
        <div style={{fontFamily:"Mulish", justifyContent:"center"}}>
          <p data-test-id={`categoryTestID${index}`} className="category-head-text" style={{textAlign:"center", fontSize:"32px", fontWeight:"lighter",cursor:"pointer"}} onClick={()=>this.handleTitleClick(item.attributes.title)}>{item.attributes.title}</p>
        </div>
      </div>
    </CategoryDiv>
  );
});
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        
        <MainDiv style={styles.mainContainer}>
          {this.state.modalOpen?
          <RequestManagement navigation={this.props.navigation} id={""} closeModal={this.closeModal}/>
          :""
          }
        
        <div><HeaderNew handleService={this.seriveceReq} navigation={this.props.navigation} id={""}/></div>
        <div>
            {this.renderCarousel()}
        </div>
        <div>
          <div style={styles.landingPageView}>
            <div style={{marginTop: "5vh" }}>
              <div style={{...styles.heading, fontWeight:"lighter",textAlign:"center",}}>Categories</div>
              <div className="category-card" style={styles.categoryCardsContainer}>{this.renderCategory()}</div>
            </div>
            <div style={{ ...styles.productBody, position: "relative" }}>
              <div style={{ ...styles.overlay, position: "absolute"}} />
              <div style={{ marginTop: "5vh", paddingBlock: 60 }}>
                <div
                  style={{ ...styles.productHeading, position: "relative", textAlign: 'center' }}
                >
                 Product lines
                </div>
                <div
                  style={{
                    ...styles.productsContainer,
                    flexDirection: "row",
                    position: "relative",
                    flexWrap: 'wrap'
                  }}
                >
                  {this.renderProducts()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...styles.partnersContainer, flexDirection: "column",flexWrap: 'wrap' }}>
          <div style={{...styles.heading, textAlign:"center", fontWeight: 'lighter'}}>Our Partnering Brands</div>
          <div style={{...styles.partnersContainerCard, flexDirection:"row",flexWrap: 'wrap'}}>
            {this.renderPartner()}
            </div>
          </div>
          <div className="popular-item" style={styles.PopulaItemBody}>
            <div style={{...styles.heading,  fontWeight:"lighter", textAlign:"center",}}>Popular Items</div>
            <div className="popular-sub-item" style={styles.popularItemsCardsContainer}>
              {this.renderCards()}
            </div>
          </div>
        </div>

    <div><Footer carouselItem=
    {this.state.carouselItem[this.state.currentCarouselIndex]?.attributes?.carousel_address}/>
    </div>
      </MainDiv>
      </div>
  // Customizable Area End
);
  }
}

// Customizable Area Start
const styles = {
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  landingPageText: {
    display: "flex",
    justifyContent: "center",
    fontSize: 24,
    fontFamily: "Mulish",
    fontWeight: "bold",
    color: "#323441",
    marginTop: 13,
  },
  landingPageContainer: {
    overflow: "scroll",
  },

  landingPageButton: {
    zIndex: -1,
    padding: 13,
    justifyContent: "center",
    backgroundColor: "#E7B846",
  },
  mainContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    background: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  headerLogo: {
    height: "auto",
  },
  searchContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginLeft: "25%",
    marginRight: "25%",
    height: "4vh",
    borderRadius: "20px",
    overflow: "hidden",
    background: "white",
    padding: "5px",
    border: "1px solid #898A8B",
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    padding: "10px",
    background: "transparent",
    fontSize: "14px",
  },
  searchIcon: {
    marginLeft: "10px",
    marginRight: "5px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  loginButton: {
    border: "none",
    background: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
  },
  signUpButton: {
    border: "none",
    background: "black",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(to right, #E8B846 10%, #F3E34E 80%)",
    padding: "12px",
    height: "7vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  iconsContainer:{
    display:"flex",
    margin:"2px",
    width:"8vw",
    gap:"1vw",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "3%",
  },

  logoicon: {
    height: "22px",
    marginTop: "1%",
  },
  carouselContainer: {
    width: '100%',
    height: '600px', 
    overflow: 'hidden',
  },
  carousel: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  carouselContent: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    width: '100%',
    height:"100%",
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    padding: '20vw',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  carouselInnerContent:{
left: "44%",
width: "50vw",
display: "flex",
  },
  contentLeft: {
    flex: 1,
  },
  contentRight: {
    flex: 2,
    marginLeft: '20px',
    marginTop:"3%",
    marginBottom:"20%", 
  },
  carouselHeading: {
    fontFamily: 'Mulish',
    color: '#ffffff',
    fontSize: '40px',
    marginBottom: '10px',
    width:"23vw"
  },
  carouselDescription: {
    fontFamily: 'Mulish',
    color: '#ffffff',
    fontSize: '16px',
    marginBottom: '20%',
    width:'20vw'
  },
  carouselButton: {
    fontFamily: 'Mulish',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#E8B846',
    color: '#000000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width:"max-content",
  },
  navigationButtons: {
    bottom: '20px',
    right: '20px',
  },
  navigationButton: {
    fontFamily: 'Mulish',
    padding: '5px',
    backgroundColor: '#E8B846',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '5px',
  },
  heading: {
    fontSize: "32px",
    fontFamily: "Mulish",
    marginTop: "84px",
    textAlign: 'center'
  },
  categoryContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "4%",
    marginBottom: "9%",
    width: "85vw",
  },
  PopulaItemBody: {
    padding: "2px",
    marginLeft: "5vw",
  },
  partnersContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "32px",
    backgroundColor: "#F2F2F2",
    paddingTop: "4%",
    paddingBottom: "2%",
    flexWrap: 'wrap'
  },
  partnersContainerCard: {
    display: "flex",
    justifyContent: "center",
    gap: "32px",
    backgroundColor: "#F2F2F2",
    paddingTop: "4%",
    paddingBottom: "2%",
  },
  popularItemsCardsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "6%",
    marginBottom: "5%",
    marginLeft:"6vw",
    width:"80vw",
    gap: "2%" 
  },
  categoryCardsContainer:{
    display: "flex",
    justifyContent: "center",
    marginTop: "6%",
    marginBottom: "3%",
    gap:"19px",
    margin: 'auto',
    width:"70vw",
  },
  gradientOverlay: {
    bottom: 104,
    left: 0,
    width: '100%',
    height: '30%',
    background: 'linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.9))',
    zIndex: 1, 
  },
  cardContainer: {
    width: "60vw", 
    height: "fit-content",
    marginBottom: 20,
    backgroundColor: "white",
    borderRadius: 15,
    overflow: "hidden",
    borderColor: "black",
    borderWidth: 2,
    elevation: 5,
  },
  cardImage: {
    width: "100%", 
    height: "auto", 
    resizeMode: "cover",
  },
  cardDetails: {
    padding: 10,
  },
  cardTitle: {
    fontSize: "16px",
    marginBottom: 5,
    fontFamily: "Mulish",
  },
  readMoreButton: {
    border: "none",
    backgroundColor: "white",
    cursor: "pointer",
  },
  readMoreButtonText: {
    color: "#E8B846",
    fontSize: "16px",
    fontFamily: "Mulish",
  },

  //Product Cards
  productBody: {
    backgroundImage: `url(${background})`,
    width: "100%",
    backgroundSize: 'cover',
    backgroundRepeat: "no-repeat",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "100%",
    width: "100%",
    zIndex: 0,
  },
  productsContainer: {
    width:"75vw",
    display: "flex",
    justifyContent: "center",
    gap: "17px",
    margin: 'auto',
    marginTop: "89px",
    zIndex: 1,
  },
  productContainer: {
    height: "27vh",
    maxWidth: '224px',
    width: '100%',
    borderColor: "#F2F2F2",
    zIndex: 2,
  },
  productHeading: {
    color: "white",
    fontSize: "32px",
    fontFamily: "Mulish",
    zIndex: 2,
  },

  //Footer
  footerContainer: {
    width: "100vw",
    height: "25vh",
  },
  leftFooter: {
    display: "flex",
    width: "30%",
  },
  footerLogoBody: {
    display: "flex",
    marginTop: "12",
  },
  footerLogoHeading: {
    fontSize: "22",
    fontFamily: "Mulish",
    color: "#1B2437",
    marginTop: 0,
    marginLeft: "2%",
  },
  footerBody: {
    display: "flex",
    backgroundColor: "#E9B848",
    justifyContent: "space-around",
    padding: "1%",
    height: "40vh",
  },
  rightFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "27rem",
  },
  footerHeading: {
    fontSize: 20,
    color: "#1B2437",
    fontFamily: "Mulish",
  },
  footerBottom: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2",
    backgroundColor: "#DCAE42",
    width: "100vw",
    marginTop: "5%",
    height: "7vh",
    marginBottom: "0",
  },
  bottomText: {
    marginTop: "2",
  },
};