import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    navigation: {navigate:()=>{}, getParam:()=>{}, goBack:()=>{} };
    id: string;
    // Customizable Area End
}
  // Customizable Area Start
  export interface NewsInterface {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      subtitle: string;
      description: string;
      created_at: string;
      updated_at: string;
      image: string;
    };
  }
  export interface ParticularNews {
      id: number;
      title: string;
      subtitle: string;
      description: string;
      created_at: string;
      updated_at: string;
      image: string;
  }

  // Customizable Area End

interface S {
  // Customizable Area Start
  NewsContent:NewsInterface[];
  news1:NewsInterface[];
  openNews:boolean;
  particularNewsContent:ParticularNews;
  // Customizable Area End
}
interface SS {
    // Customizable Area Start 
    id: string|number;
    // Customizable Area End
}
export default class NewsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newsID:string="";
  particularnewsID:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      , getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      NewsContent:[],
      news1:[],
      openNews:false,
      particularNewsContent:{
        id: 0,
        title: "",
        subtitle: "",
        description: "",
        created_at: "",
        updated_at: "",
        image: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson =
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) 
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && responseJson.data) {
      
        if(apiRequestCallId===this.newsID){
          this.setState({NewsContent:responseJson.data.slice(1),news1:responseJson.data.slice(0,1)})
        }
        if(apiRequestCallId===this.particularnewsID){
          this.setState({openNews:true,particularNewsContent:responseJson.data.attributes})
        }
      }
    
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
   this.getNews()
  }
  navigateToLandingPage(route: string) {
    const naviigateto = new Message(getName(MessageEnum.NavigationMessage));
    naviigateto.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    naviigateto.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(naviigateto);
  }
  
  getNews = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.newsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getParticularNews = (newsID:number) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.particularnewsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.newsEndPoint}/${newsID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  openNews=(newsID:number)=>{
    this.getParticularNews(newsID)
  }
  goBack=()=>{
    this.setState({openNews:false})
  }
  // Customizable Area End
}
