import React from "react";
// Customizable Area Start
import {
    Box,
  Button, 
  Typography,
} from "@material-ui/core";


import HeaderNew from "../../landingpage/src/HeaderNew.web";
import AboutUsController, { Props } from "./AboutUsController";
import { backArrow, bannerImage, topOval } from "./assets";
import { Footer } from "../../../components/src/Footer.web";

// Customizable Area End
export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area StartbackArrow
      <>
        <HeaderNew navigation={this.props.navigation} id={""}/>
        <Box style={{minWidth:"100%",minHeight:"443px",background: "linear-gradient(180deg, #FAFAFA 0%, #C5C5C5 100%)"}}>
            <Box className="sidebar-head-text" style={{display:"flex",gap:"8px", alignItems: 'center', width:"fit-content",marginTop:28,marginLeft:50}}>
                <img data-test-id="backArrowTest" src={backArrow} style={{width: 24,cursor:"pointer"}} onClick={() => this.navigateToLandingPage("LandingPage")} />
                <Typography style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left'
                }}>
                    <span style={{color:"rgba(128, 128, 128, 1)"}}>Back to Home /</span><span style={{color:"rgba(0,0, 0, 1)"}}>About</span>
                </Typography>
            </Box>
            <Box style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%",textAlign:"center",marginTop:"-50px"}}>
            <img src={bannerImage} alt="bannerImage" style={{ maxWidth: '100%', height: 'auto' }} />            </Box>
        </Box>
        <Box style={{maxWidth:1140,marginInline:"auto",marginTop:37,display:"flex",gap:30,flexDirection:"column"}}>
            <Typography style={{fontSize:40,fontWeight:300,lineHeight:"50.2px",padding:"10px"}} >About Us</Typography>
            <div style={{fontSize:24,fontWeight:400,lineHeight:"30.12px",padding:"10px"}} dangerouslySetInnerHTML={{ __html: this.state.aboutUsText }} />
        </Box>
        <Box style={{width:"100%",marginInline:"auto",minHeight:"163px",borderRadius:16,backgroundColor:"black",marginTop:61,marginBottom:34}}>
            <img src={topOval} />
            <Box style={{display:"flex", flexWrap:"wrap", alignItems:"center",color:"white",padding:"0px 64px",width:"89%",justifyContent:"space-between"}}>
                <Typography style={{fontWeight:200,fontSize:32,lineHeight:"30px",textAlign:"justify",margin:"10px"}}>How to Buy?</Typography>
                <Button data-test-id="backArrowContact" onClick={() => this.navigateToLandingPage("Contactus")} style={{width:'80%',margin:'10px', background: "white",color: "black",textTransform: "initial",borderRadius: "5px",fontSize:16,fontWeight:400,lineHeight:"20.50px",textAlign:"center"}}>Contact Agent</Button>
            </Box>
        </Box>
        <Footer/>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const styles = {
    
};
// Customizable Area End