import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require("react-navigation");
import StorageProvider from '../../../framework/src/StorageProvider';
import { ChangeEvent } from "react";

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: "success" | "info" | "warning" | "error";
  isMessage: string;
}

export interface ValidResponseType {
  data: OrdersBillingData[];
  meta: {
    message: string;
  };
  message: string;
}

export interface ErrorPayloadType {
  key: string;
  token: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface InvalidResponseTypePost {
  errors: string;
}


interface CompanyDetail {
  id: string;
  type: string;
  attributes: {
    id: number;
    owner_name: string;
    company_name: string;
    gst_number: string;
    pan_card: string;
    address: string;
    email: string;
    full_phone_number: string;
  };
}

interface OrderDetails {
  customer_name: string;
  sub_total: number;
  total_tax: number;
  discount_applied: number;
  total_fees: number;
  total_items: number;
  total_freight_and_package: number;
  order_status: string;
}

interface OrderItem {
  order_item_name: string;
  freight_package: number;
  part_number: string;
  hsn: string;
  gst_rate: string;
  quantity: number;
  unit: string;
  price: number;
  total_price: number;
  description: string;
  images: { id: number; url: string }[];
  catalogue_id: number;
}

interface OrdersBillingData {
  id: string;
  type: string;
  attributes: {
    id: number;
    invoice_number: string;
    invoice_date: string;
    due_date: string;
    order_id: number;
    order_address: [];
    order_details: OrderDetails;
    order_items: OrderItem[];
    company_details: CompanyDetail;
    isShowAll: boolean;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  hasSuccessErrorForOrders: HasSuccessErrorType;
  ordersData: OrdersBillingData[];
  feedbackId: number | null;
  isFeedbackOpen: boolean;
  isFeedbackSuccess: boolean;
  isFeedbackError: boolean;
  ratingValue: number;
  message: string;
  messageBlankError: boolean;
  ratingBlankError: boolean;
  anonyCheck: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersListApiCallId: string = "";
  postOrdersFeedbackApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      hasSuccessErrorForOrders: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      ordersData: [],
      feedbackId: null,
      isFeedbackOpen: false,
      isFeedbackSuccess: false,
      isFeedbackError: false,
      ratingValue: 0,
      message: "",
      messageBlankError: false,
      anonyCheck: false,
      ratingBlankError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponseOrders(responseJson)) {
        this.apiSuccessCallBacksOrders(apiRequestCallId, responseJson);
      } else if (this.isInValidResponseOrders(responseJson)) {
        this.apiFailureCallBacksOrders(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let value: string = await StorageProvider.get("token");
    this.setState({
      token: value
    })
    this.getOrderslist();
  }

  isValidResponseOrders = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.data || responseJson.message);
  };

  isInValidResponseOrders = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksOrders = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.getOrdersListApiCallId) {
      this.loaderOff();
      this.setState({
        ordersData: responseJson.data
      });
    }
    if (apiRequestCallId === this.postOrdersFeedbackApiCallId) {
      this.loaderOff();
      this.setState({
        isFeedbackSuccess: true
      });
    }
  };

  apiFailureCallBacksOrders = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType & InvalidResponseTypePost
  ) => {
    this.loaderOff();
    if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
      this.errorsInvalidToken(responseJson)
    } else {
      if (apiRequestCallId === this.getOrdersListApiCallId) {
        this.snackBarShowOrders(true, "error", responseJson.errors);
        this.setState({
          ordersData: []
        });
      }
      if (apiRequestCallId === this.postOrdersFeedbackApiCallId) {
        this.loaderOff();
        this.setState({
          isFeedbackError: true
        });
      }
    }
  };

  errorsInvalidToken = (responseJson: InvalidResponseType) => {
    let errorMessage = responseJson.errors[0].token;
    if (
      errorMessage === "Invalid token" ||
      errorMessage === "Token has Expired"
    ) {
      this.snackBarShowOrders(true, "error", errorMessage);
      this.handleNavigateFromOrders("login");
    }
  }

  getOrderslist = () => {
    this.loaderOn();
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrderslistApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressListMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postOrdersFeedback = () => {
    this.loaderOn();
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const httpBody = {
      review: {
        catalogue_id: this.state.feedbackId,
        rating: this.state.ratingValue,
        comment: this.state.message,
        anonymous: this.state.anonyCheck
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postOrdersFeedbackApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postOrdersFeedbackApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createOrderMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOrdersCloseSnackbar = () => {
    this.setState({
      hasSuccessErrorForOrders: {
        isSeverity: "error",
        isOpen: false,
        isMessage: "",
      },
    });
  };

  loaderOn = () => {
    this.setState({
      isLoading: true
    })
  }

  loaderOff = () => {
    this.setState({
      isLoading: false
    })
  }

  snackBarShowOrders = (
    show: boolean,
    type: "success" | "info" | "warning" | "error",
    message: string
  ) => {
    this.setState({
      hasSuccessErrorForOrders: {
        isOpen: show,
        isSeverity: type,
        isMessage: message,
      },
    });
  };

  handleNavigateFromOrders = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dayNum = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const suffix = this.getDaySuffix(dayNum);
    return `${dayNum}${suffix} ${month} ${year}`;
  }

  getDaySuffix(dayNum: number) {
    if (dayNum >= 11 && dayNum <= 13) {
      return 'th';
    }
    switch (dayNum % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  moreItem(moreItemID: number) {
    let orderData = this.state.ordersData;
    let orderDataIndex = this.state.ordersData.findIndex((item) => item.attributes.id === moreItemID);
    if (orderDataIndex !== -1) (
      orderData[orderDataIndex].attributes.isShowAll = true
    )
    this.setState({
      ordersData: orderData
    });
  }

  giveFeedback = (feedbackId: number) => {
    this.setState({
      feedbackId: feedbackId,
      isFeedbackOpen: true,
    })
  }

  handleCloseModalFeedback = () => {
    this.setState({
      isFeedbackOpen: false,
      ratingValue: 0,
      messageBlankError: false,
      message: "",
      anonyCheck: false,
      ratingBlankError: false
    })
  }

  onRatingchange = (event: ChangeEvent<{}>, newValue: number | null) => {
    if (newValue){
      this.setState({
        ratingValue: newValue
      }, () => {
        if (this.state.ratingValue > 0) {
          this.setState({
            ratingBlankError: false
          });
        }
      })
    } else {
      this.setState({
        ratingValue : this.state.ratingValue -1
      },()=>{
        if (this.state.ratingValue === 0) {
          this.setState({
            ratingBlankError: true
          });
        }
      })
    }
  }

  handleFeedbackInput = (messageEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = messageEvent.target.value;
  const isBlank = inputValue.trim() === "";
    this.setState({
      message: messageEvent.target.value
    });

    if (isBlank) {
      this.setState({
        messageBlankError: true
      });
    } else {
      this.setState({
        messageBlankError: false
      });
    }
  };

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      anonyCheck: event.target.checked,
    });
  };

  handleSubmit = async (preventEvent: { preventDefault: () => void; }) => {
    preventEvent.preventDefault();
    const { message, ratingValue } = this.state;
    const isBlank = message.trim() === "";

    const errors = {
      messageBlankError: isBlank,
      ratingBlankError: ratingValue === 0
    };
    this.setState(errors);
    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors) {
      this.postOrdersFeedback();
    }
  };
  
  numberWithCommas = (value: number) => {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2, // Ensure two decimal places
      maximumFractionDigits: 2 // Ensure two decimal places
    });
  };
  // Customizable Area End
}