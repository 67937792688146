import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";
import { State, City, IState, ICity }  from 'country-state-city';
import { ChangeEvent } from "react";
interface BodyData {
  data: {
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      pincode: string;
      problem_description: string;
      equipment_type_id: number;
  };
}
export interface EquipmentType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeModal:()=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  receivedRequests: IRequest[];
  rejectText: string;
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  firstName: string;
  firstNameError:string;
  lastName: string;
  lastNameError:string;
  email: string;
  emailError:string;
  phone: string;
  phoneError:string;
  address1:string;
  address1Error:string;
  address2:string;
  address2Error:string;
  city:string;
  cityError:string;
  state:string;
  stateError:string;
  pincode:string;
  pincodeError:string;
  equipmentType:EquipmentType[];
  equipmentTypeError:string;
  problemDescription:string;
  problemDescriptionError:string;
  serviceFormSubmitted:boolean;
  loginToken:string;
  modalOpen:boolean;
  selectedEquipmentType:number;
  stateList:IState[];
  cityList: ICity[];
  autoPopulateFirstName:string[];
  newName:string[];
  inputValue:string;
  filteredOptions:string[];
  options:string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      receivedRequests: [],
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
      address1: '',
      address1Error: '',
      address2: '',
      address2Error: '',
      city: '',
      cityError: '',
      state: '',
      stateError: '',
      pincode: '',
      pincodeError: '',
      equipmentType: [],
      equipmentTypeError: '',
      problemDescription: '',
      problemDescriptionError:'',
      serviceFormSubmitted:false,
      loginToken:"",
      modalOpen:false,
      selectedEquipmentType:0,
      stateList: [],
      cityList: [],
      autoPopulateFirstName:[],
      newName:[],
      inputValue:'',
      filteredOptions:[],
      options:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    switch (message.id) {
        case getName(MessageEnum.SessionResponseMessage):
            this.handleSessionResponse(message);
            break;
        case getName(MessageEnum.RestAPIResponceMessage):
            this.handleRestAPIResponse(message);
            break;
        default:
            break;
    }
  }

  handleSessionResponse(message: Message) {
      const sessionResponseTokenName = getName(MessageEnum.SessionResponseToken);
      const token = message.getData(sessionResponseTokenName);
      
      if (token) {
          this.setState({ token }, () => {
              this.getAllReceivedRequest();
          });
      }
  }

  handleRestAPIResponse(message: Message) {
    const restAPIResponseDataMessageName = getName(MessageEnum.RestAPIResponceDataMessage);
    const restAPIResponseSuccessMessageName = getName(MessageEnum.RestAPIResponceSuccessMessage);
    const restAPIResponseErrorMessageName = getName(MessageEnum.RestAPIResponceErrorMessage);

    const apiRequestCallId = message.getData(restAPIResponseDataMessageName);
    const responseJson = message.getData(restAPIResponseSuccessMessageName);
    const errorJson = message.getData(restAPIResponseErrorMessageName);

    switch (apiRequestCallId) {
        case this.serviceRequestID:
            this.handleServiceRequest(responseJson, errorJson);
            break;
        case this.equipmentTypeID:
            this.handleEquipmentType(responseJson);
            break;
        case this.getAllReceivedRequestCallId:
            this.handleReceivedRequests(responseJson);
            break;
        case this.updateRequestReviewCallId:
            this.handleUpdateRequestReview(responseJson);
            break;
        case this.deleteRequestCallId:
            this.handleDeleteRequest(responseJson);
            break;
        default:
            break;
    }
  }

  handleServiceRequest(responseJson: any, errorJson: any) {
    if (responseJson?.errors || errorJson || responseJson.errors) {
        this.setState({
            serviceFormSubmitted: false,
        });
        if(typeof responseJson.errors[0]==='object'){
          
          this.showAlert(responseJson.errors[0]?.token, "Alert Heading");
        }else{

          this.showAlert(responseJson.errors[0], "Alert Heading");
        }
    } else {
        this.setState({ serviceFormSubmitted: true });
    }
  }

  handleEquipmentType(responseJson: any) {
    this.setState({ equipmentType: responseJson });
  }

  handleReceivedRequests(responseJson: any) {
    if (responseJson !== undefined && responseJson.data) {
        this.setState({ receivedRequests: responseJson.data });
    }
  }

  handleUpdateRequestReview(responseJson: any) {
    if (responseJson !== undefined && responseJson.data) {
        this.setState({ selectedId: null, rejectText: "" });
        this.showAlert("Alert", responseJson.data.attributes.status === "rejected" ? configJSON.rejectedMsgText : configJSON.acceptedMsgText);
        this.getAllReceivedRequest();
    }
  }

  handleDeleteRequest(responseJson: any) {
    if (responseJson !== undefined && responseJson.data) {
        this.showAlert("Alert", configJSON.deletedMsgText);
        this.getAllReceivedRequest();
    }
  }


  componentDidMount = async () => {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.setState({loginToken: await getStorageData('token')});
    this.getEquipmentType()
    let stateList = State.getStatesOfCountry("IN")
    this.setState({
      stateList: stateList
    })

    const existingData = await getStorageData('userData');
    let parseData = [];  
    if (existingData) {
         parseData = JSON.parse(existingData);
        this.setState({firstName:parseData.first_name,lastName:parseData.last_name,email:parseData.email,phone:parseData.phone_number,address1:parseData.address_line_1,address2:parseData.address_line_2,state:parseData.state,pincode:parseData.pincode,problemDescription:parseData.problem_description})
   }
    if (Array.isArray(parseData)) {
      this.setState({ autoPopulateFirstName: [...parseData] });
    }
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg,);
  };

  getAllReceivedRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReceivedRequestCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReceivedRequestApiEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    if (!is_accepted && this.state.rejectText.length < 1) {
      this.setState({ rejectTextError: configJSON.rejectTextFieldIsRequired });
      return;
    }

    const httpBody = {
      is_accepted,
      ...(!is_accepted && {
        rejection_reason: this.state.rejectText,
      }),
    };

    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestReviewCallId = updateRequestReviewMsg.messageId;

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestApiEndpointStart +
        requestReviewId +
        configJSON.updateRequestApiEndpointEnd
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

  onChangeRejectText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rejectText: event.target.value });
  };

  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SentRequest");

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }

  closeViewModal = () => {
    this.setState({viewRequest: null})
  }

  onChangeFilterKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterKey: event.target.value });
  };

  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  }; 
   handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ firstName: value }, () => {
      this.validateFirstName()

    })
  }
  validateFirstName = (): boolean => {
    const { firstName } = this.state;
    const numvalidate = /\d/;
    if (!firstName || firstName.trim().length <= 2 || numvalidate.test(firstName) || firstName.length >= 30 || firstName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.firstName)) {
      this.setError("firstNameError", configJSON.firstNameError);
      return false;
    }
    this.setState({ firstNameError: "" });
    return true;
  };

  handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ lastName: value }, () => {
      this.validateLastName()

    })
  }
  validateLastName = (): boolean => {
    const { lastName } = this.state;
    const numvalidate = /\d/;
    if (!lastName || lastName.trim().length <= 3 || numvalidate.test(lastName) || lastName.length >= 30 || lastName.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.lastName)) {
      this.setError("lastNameError", configJSON.lastNameError);
      return false;
    }
    this.setState({ lastNameError: "" });
    return true;
  };
  handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ email: value }, () => {
      this.validateEmail()

    })
  }
  validateEmail = (): boolean => {
    const { email } = this.state;
      if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/g.test(email)) {
        this.setError("emailError", configJSON.emailError);
        return false;
      }
      this.setState({ emailError: "" });
    return true;
  };

  handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, '');
      const limitedValue = numericValue.slice(0, 10);
      this.setState({ phone: limitedValue }, () => {
        this.validatePhone();
      });
  }
  validatePhone = (): boolean => {
    const { phone } = this.state;
    if (!phone || phone.length !== 10) {
      this.setError("phoneError", configJSON.phoneError);
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  }
  handleCity = (
    event: ChangeEvent<{}>,
    newValue: ICity | null,
  ) => {
    if(newValue){
      this.setState({ city: newValue.name }, () => {
        this.validateCity()
      })
    }
  }

  handleCityClose = () => {
    this.setState({ city: "" }, () => {
      this.validateCity()
    })
  }
  handleAddress1Ch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ address1: event.target.value }, () => {
      this.validateAddress1()
    })
  }
  handleAddress2Ch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ address2: event.target.value }, () => {
      this.validateAddress2()
    })
  }
  validateAddress1 = (): boolean => {
    const { address1 } = this.state;
    if (!address1 || address1.trim().length <= 10 || address1.length >= 50 || address1.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/.test(this.state.address1)) {
      this.setState({ address1Error: "Please Enter a valid Address" });
      return false;
    }
    this.setState({ address1Error: "" });
    return true;
  };
  validateAddress2 = (): boolean => {
    const { address2 } = this.state;
    if (!address2 || address2.trim().length <= 2 || address2.length >= 50 || address2.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/.test(this.state.address2)) {
      this.setState({ address2Error: "Please Enter a valid Address" });
      return false;
    }
    this.setState({ address2Error: "" });
    return true;
  };
  validateCity = (): boolean => {
    const { city } = this.state;
    const numvalidate = /\d/;
    if (!city || city.trim().length <= 2 || numvalidate.test(city) || city.length >= 30 || city.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.city)) {
      this.setState({ cityError: configJSON.cityError });
      return false;
    }
    this.setState({ cityError: "" });
    return true;
  };
  handleState =(
    event: ChangeEvent<{}>,
    newValue: IState | null,
  ) => {
    if(newValue){
      let cityList = City.getCitiesOfState("IN", newValue.isoCode)
      this.setState({ state: newValue.name, cityList: cityList }, () => {
        this.validateState()
      })
    }
  }

  handleStateClose =() => {
      this.setState({ state: "" }, () => {
        this.validateState()
      })
  }
  validateState = (): boolean => {
    const { state } = this.state;
    const numvalidate = /\d/;
    if (!state || state.trim().length <= 2 || numvalidate.test(state) || state.length >= 30 || state.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(this.state.state)) {
      this.setState({ stateError: configJSON.stateError });
      return false;
    }
    this.setState({ stateError: "" });
    return true;
  };
  handlePincode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, '');
    const limitedValue = numericValue.slice(0, 6);
    this.setState({ pincode: limitedValue }, () => {
      this.validatePinCode()
    })
  }
  validatePinCode = (): boolean => {
    const { pincode } = this.state;
    if (!pincode || pincode.trim().length <= 5 || pincode.charAt(0) === "0") {
      this.setState({ pincodeError: configJSON.pincodeError });
      return false;
    }
    this.setState({ pincodeError: "" });
    return true;
  };
  selectEquipmentID=(
    event: ChangeEvent<{}>,
    newValue: EquipmentType | null,
  ) => {
    if(newValue){
      this.setState({selectedEquipmentType:newValue.id},()=>{
        this.validateEquipmentID(newValue.id)
      })
    }
  }

  selectEquipmentClose = () => {
    this.setState({ selectedEquipmentType: 0 }, () => {
      this.validateEquipmentID(0)
    })
  }
  validateEquipmentID = (equipID:number): boolean => {
    if (equipID<1) {
      this.setState({ equipmentTypeError: configJSON.equipmentTypeError });
      return false;
    }
    this.setState({ equipmentTypeError: "" });
    return true;
  };
  handleDescriptionCh = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ problemDescription: event.target.value }, () => {
      this.validateDescription()
    })
  }
  validateDescription = (): boolean => {
    const { problemDescription } = this.state;
    if (!problemDescription || problemDescription.trim().length <= 10 || problemDescription.length >= 200 || problemDescription.startsWith(' ') || /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/.test(this.state.problemDescription)) {
      this.setState({ problemDescriptionError: configJSON.problemDescriptionError });
      return false;
    }
    this.setState({ problemDescriptionError: "" });
    return true;
  };
  handleCheckValidation = () => {
    let firstName = this.validateFirstName()
    let lastName = this.validateLastName()
    let email = this.validateEmail()
    let phone = this.validatePhone()
    let address1 = this.validateAddress1()
    let address2 = this.validateAddress2()
    let city = this.validateCity()
    let state = this.validateState()
    let pincode = this.validatePinCode()
    let selectedEquipmentType = this.validateEquipmentID(this.state.selectedEquipmentType)
    if (firstName && lastName && email && phone && address1 && address2 && city && state && pincode&&selectedEquipmentType ) {
      this.submitServiceRequest();
    }
   

  };
  serviceRequestID : string="";
  submitServiceRequest = async () => {
    const { email, firstName, phone, lastName, address1, address2,city,state,pincode,selectedEquipmentType,problemDescription } = this.state
    const body: BodyData = {
      "data": {
          "first_name": firstName,
          "last_name": lastName,
          "email": email,
          "phone_number": `${"+91"}${phone}`,
          "address_line_1": address1,
          "address_line_2": address2,
          "city": city,
          "state": state,
          "pincode": pincode,
          "problem_description": problemDescription,
          "equipment_type_id": selectedEquipmentType,
      }
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":`${this.state.loginToken}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSendRequestApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceRequestEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    this.serviceRequestID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  equipmentTypeID:string="";
  getEquipmentType = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.loginToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.equipmentTypeID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.equipmentTypeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSentRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closeModal=()=>{
    this.props.closeModal()
  }
  navigateToLandingPage(route: string) {
    const naviigateto = new Message(getName(MessageEnum.NavigationMessage));
    naviigateto.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    naviigateto.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(naviigateto);
  }
  // Customizable Area End
}
