export const logo = require('../assets/logo.png');
export const background = require('../assets/background.png')
export const helpIcon = require('../assets/helpIcon.png');
export const headerLogo = require('../assets/headerLogo.png')
export const search = require('../assets/search.png');
export const user = require('../assets/user.png');
export const searchFilled = require("../assets/search-filled.png")
export const rectangle = require("../assets/Rectangle.png")
export const TCImage = require("../assets/TCimg.png");
export const backArrow = require("../assets/backArrow.svg");
export const cart = require("../assets/cart.svg");
export const wishlist = require("../assets/wishlist.svg");
export const address = require("../assets/address.svg");
export const orders = require("../assets/orders.svg");
export const settings = require("../assets/settings.svg");
export const logout = require("../assets/logout.svg");
export const editprofile = require("../assets/editprofile.svg");
export const closeIcon = require("../assets/closeIcon.png")
export const rectangleTop = require("../assets/rectangleTop.png")
export const rectangleBottom = require("../assets/rectangleBottom.png")
export const handsImage = require("../assets/handsImage.png")
export const error = require("../assets/error.png")
