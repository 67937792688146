import React from "react";
// Customizable Area Start
import {
    Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import { backArrow } from "./assets";

const CustomTypography = styled(Typography)({
  '@media(max-width: 600px)': {
    padding: '5px !important',
    fontSize: 14
  }
})
import HeaderNew from "./HeaderNew.web";
import Categoriessubcategories from "../../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import { decrementButton, grid4Button2, gridButton, heartIcon, incrementButton } from "../../../blocks/catalogue/src/assets";
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import { Category } from "./BrandListingController";
import { wishlistEmpty } from "../../../blocks/wishlist/src/assets";
import { Footer } from "../../../components/src/Footer.web";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
// Customizable Area End
import BrandListingController, { Props } from "./BrandListingController";

export default class BrandListing extends BrandListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  renderSearchData = () => {
    
    return (
      <>
        {this.state.searchData.length > 0 && this.state.searchData.map((item: Category, index) => this.getWebList(item, index))}
        {this.state.isNoDataSearch && this.renderEmptySearchData()}
      </>
    )
    
  }
  
  renderEmptySearchData = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} 
          className="emptyMainGrid" 
          data-test-id="noDataBoxTestID"
        >

          <Box className="emptyMainBox">
            <img src={wishlistEmpty} alt="" className="wishlistEmpty" />

            <Typography 
              className="ordersHeadding emptyHeadigBlod" 
              data-test-id="noDataTextTestID"
            >
              No data found!
            </Typography>

          </Box>

        </Grid>
      </>
    )
  }
  
  renderPagination = () => {
    return (
      <>
        {

          this.state.productCount > 0 &&
          <Box style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            gap: "158.5px", 
            marginTop: 63, 
            maxHeight: 40
            }}
          >

            <img className={this.state.pageNo === 1 ? "decreaseBUtton":""} data-test-id="decreaseTestId" src={decrementButton} onClick={this.decreasePageNumber} style={{ cursor: "pointer" }} />
           
            <Typography 
              data-test-id="increasePageTestId" 
              style={{ 
                fontSize: "14px",
                fontWeight: 500, 
                textAlign: "center", 
                lineHeight: 20 
              }}
            >
                Page {this.state.pageNo} of {this.countPage()}
            </Typography>
           
            <img className={this.state.pageNo === this.countPage() ? "decreaseBUtton":""} data-test-id="increaseTestId" src={incrementButton} onClick={this.increasePageNumber} style={{ cursor: "pointer" }} />
        
          </Box>

        }
      </>
    )
  }

  getWebList(item: Category, index: number) {
    let value = item?.attributes;
    const imageUrl = value.images && value.images.length > 0 ? value.images[0].url : '';
    return (
      <CardGrid item md={this.state.grid4 ? 4 : 3} sm={4} xs={12}>

        <Box className="mainFavIconBox" key={`product-item-${item.id}`} >
          <Box style={{
            borderRadius: "16px 16px 0px 0px", overflow: "hidden", position: "relative", background: 'radial-gradient(81.54% 81.54% at 50% 50.1%, #F7F8FB 0%, rgba(230, 231, 234, 0.801847) 100%)'
          }}>
            <img width="100%" height="204px" src={imageUrl} alt="No Image" style={{cursor: "pointer" }}
            onClick={() => this.goToProductDesc(item.id)}
            
            data-test-id={`productTestID${index}`}
            />
           
            <img src={heartIcon}
            data-test-id={`wistlistTestID${index}`}
            onClick={() => this.addToWishlistCat(item.id)}
            className="favBox"
            />

          </Box>

          <Box>

            <Box className="innerCardBox" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>

              <Typography 
               className="headingText" 
               style={{ 
                fontSize: "20px", 
                fontWeight: 600, 
                lineHeight: "25px" 
                }}
              >
                {value.part_number}
              </Typography>
             
              <Typography 
                className="subHeadingText" 
                style={{ 
                  fontSize: "16px", 
                  fontWeight: 300, 
                  color: "rgba(102, 102, 102, 1)", 
                  lineHeight: "24px" 
                }}
              >
                
                {value.name.length>15
                ? value.name.slice(0, 15)+"....."
                : value.name
                }

              </Typography>

              <Typography 
                style={{ 
                  fontSize: "12px", 
                  fontWeight: 400, 
                  color: "rgba(127, 127, 127, 1)", 
                  lineHeight: "24px" 
                }}
              >
               
                {value.description.length>25
                ? value.description.slice(0, 25)+"....."
                : value.description
                }

              </Typography>

            </Box>

            <Typography 
              style={{ 
                paddingLeft: "20px", 
                fontSize: "20px", 
                fontWeight: 700, 
                lineHeight: "25px" 
              }}
            >

              ₹ {value.discount_price == null ? value.price : value.discount_price} <del style={{ fontSize: "14px", fontWeight: 400, color: "rgba(188, 188, 188, 1)", lineHeight: "17.5px" }}>
                {value.discount_price == null ? "" : `₹ ${value.price}`}</del>

            </Typography>

          </Box>
        </Box>

      </CardGrid>
    );
  }

  renderMainData = () => {

    return (

      <>
        {this.state.arrayHolder1.length > 0 && this.state.arrayHolder1.map((item: Category, index) => this.getWebList(item, index))}
        {this.state.isNoDataCat && this.renderEmptySearchData()}
      </>

    )

  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.isLoading &&
          <CircularProgressBox >

            <CircularProgress 
              className="loader" 
            />

          </CircularProgressBox>
      }

        <MainnBox data-test-id="catalogue">
        
          <div>
            <HeaderNew 
              navigation={this.props.navigation} 
              id={""}
            />
          </div>
        
          <ParenntBox 
            style={{
              display: 'flex', 
              gap: '124px'
            }}
          >

            <Box 
             className="sidebar-box" 
             style={{ 
               left:this.state.isToggle?0:-312,
               maxWidth: "276px", 
               width: '100%', 
               minHeight: "100vh", 
               display: "flex", 
               flexDirection: "column", 
               gap: "11px", 
               paddingLeft: "38.5px", 
               marginTop: "30px" 
             }}
            >
              <Box 
               style={{ 
                 display: "flex", 
                 margin: "2px 0px", 
                 gap: "8px" 
                }}
              >
                <img 
                 src={backArrow} 
                 data-test-id="TestGoBack" 
                 onClick={this.goBack}
                />

                <Typography 
                  className="sidebar-text" 
                  style={{
                   fontWeight: 400,
                   fontSize: '14px',
                   textAlign: 'left',
                   lineHeight: '24px',
                  }}
                >
                  <span style={{ color: "rgba(128, 128, 128, 1)" }}>Back to Home /</span><span style={{ color: "rgba(0,0, 0, 1)" }}> Spares</span>
                
                </Typography>

              </Box>

              {this.state.isSearchBox ?
               <Box 
                className="mainBox">

                 <AdvancedSearch 
                   navigation={this.props.navigation} id={""}
                   searchButton={this.handleSearchButton}
                   isParamsonUpdated={this.state.isParamsupdated}
                   searchParamsData={this.state.searchParams}
                   data-test-id="searchComponentTestId"
                 />

               </Box> :

              <Categoriessubcategories 
                 pushSelectedData={this.pushSelectedDataRadio}
                 navigation={undefined} id={""}
                 handleClear={this.handleClear}
                 selectedSubCat={this.state.apiSelectedSubCat}
                 isParamsonUpdated={this.state.isParamsupdated}
                 data-test-id="Categoriessubcategories"
               />
              }

            </Box>

            <Box 
             style={{ 
              width: "100%", 
              marginTop: "17px", 
              height: "100%", 
             }}
            >
              
              <Box 
               className="right-box" 
               style={{ 
                display: "flex", 
                flexDirection: "column", 
                gap: "41px", 
                paddingRight: 40 
               }}
              >
                <Box 
                 className="heading-text" 
                 style={{ 
                  justifyContent: "end",
                  display: "flex", 
                 }}
                >
                  <Box className="searchtexthead">

                    <Typography 
                     style={{ 
                      width: '100%', 
                      textAlign: 'end', 
                      minWidth:'max-content'
                     }}
                    >
                      Search Result - {this.state.productCount}
                    </Typography>

                    {this.state.isSearchBox ?

                      <span 
                       className="searchText" 
                       onClick={this.advancedSearchCancel} 
                       data-test-id="cancelSearchTestId" 
                      >
                        Cancel Advanced Search
                      </span>
                      :
                      <span 
                       className="searchText" 
                       onClick={this.advancedSearchBtn} 
                       data-test-id="advancedSearchTestId"
                      >
                        Advanced Search
                      </span>

                    }

                  </Box>

                </Box>

                <Box 
                 style={{ 
                  display: "flex", 
                  gap: "29px", 
                  flexDirection: "column", 
                  width: '100%',
                  maxWidth: 962, 
                 }}
                >
                  <Box 
                   style={{ 
                    display: "flex", 
                    justifyContent: "space-between",
                   }}
                  >
                    <Box 
                     style={{ 
                      display: "flex", 
                      gap: "8px",
                     }}
                    >
                    {Object.entries(this.state.selectedSubCat).map((data, index) =>

                        <Chip
                          key={index}
                          label={this.checkLabel(data[1])}
                          data-test-id={`deleteChipTestID_${index}`}
                          onDelete={this.state.isSearchBox ? () => this.handleChipDelete(data) : () => this.handleChipDeleteCat(data)}
                          variant="outlined"
                          deleteIcon={<ClearIcon />} 
                        />

                      )}
                    </Box>
                    <Box 
                      style={{ 
                        display: 'flex', 
                        gap: 15, 
                        cursor: "pointer",
                        alignItems: 'center', 
                      }}
                    >
                      <MenuIcon onClick={this.handleMenu} className='menu-icon' style={{ display: 'none' }}/> 

                      <img 
                        src={this.state.grid4 ? gridButton : grid4Button2} 
                        data-test-id="changeGridTest" 
                        onClick={this.changeGrid} 
                      />

                    </Box>
                  </Box>

                  <Box 
                    className="innerCard" 
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      gap: 90, 
                      flexWrap: 'wrap', 
                    }}
                  >
                    <Grid container spacing={3}>

                      { 
                        this.state.isSearchBox ?
                        this.renderSearchData()
                        :
                        this.renderMainData()
                      }

                    </Grid>

                  </Box>

                </Box>
  
              </Box>

              { this.renderPagination() }

            </Box>

          </ParenntBox>
          <div>
            <Footer />
          </div>

        </MainnBox>

        <SnackBarAlert 
          handleCloseSnack={()=> this.snackBarShowCat(false, "error", "")}
          snackBarProps={this.state.hasSuccessErrorForCat}
          data-test-id="snackBarTestID"
        />

      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainnBox = styled(Box)({
  alignItems: "center",
  padding: 2,
  justifyContent: "center",
  "& .mainFavIconBox": {
    zIndex:1,
    display: "flex", 
    position: "relative",
    flexDirection: "column", 
    maxWidth: "254px", 
  },
  "& .emptyHeadigBlod": {
    fontSize: "40px !important",
    fontWeight: "bold !important",
  },
  "& .favBox": {
    position: "absolute", 
    zIndex:9999,
    bottom: "12px", 
    right: "12px", 
    height:"20px",
    width:"24px",
    cursor: "pointer"
  },
  "& .decreaseBUtton": {
    pointerEvents:"none",
    cursor:"not-allowed !important",
  },
  "& .mainBox": {
    marginTop:"38px"
  },
  "& .emptyMainGrid": {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
  },
  "& .ordersHeadding": {
    fontSize: "24px",
    position: "relative",
    color: "#000",
    fontStyle: "normal",
    zIndex: 99,
    fontWeight: 700,
    paddingBottom: "20px",
    "@media (min-width:320px) and (max-width:800px)": {
      marginBottom: "12px",
      fontSize: "24px !important",
    },
  },
  "& .emptyMainBox": {
    width: "35%"
  },
  "& .searchText": {
    cursor: "pointer",
    color: "#fff",
    background:"#000",
    fontSize: "12px",
    textAlign:'center',
    padding:"10px",
    borderRadius:"8px",
  },
  "& .wishlistEmpty": {
    paddingBottom: "20px",
    height: "200px",
    width: "200px",
  },
  "& .searchtexthead": {
    flexDirection: "column",
    display: "flex",
    gap: "8px",
  }
});
const ParenntBox = styled(Box)({
  '@media(max-width: 1260px)': {
    '& .innerCard': {
      gap: '10px !important'
    },
    gap: '24px !important',
  },
  '@media(max-width: 1439px)': {
    '& .innerCard': {
      gap: '20px !important'
    },
    gap: '50px !important',
  },
  '@media(min-width: 960px) and (max-width: 1199px)': {
    '& .sidebar-box': {
      maxWidth: '230px !important'
    },
  },
  '@media(max-width: 1153px)': { 
    '& .innerCard': {
      gap: '24px !important',
      justifyContent: 'flex-start !important',
    },
    gap: '24px !important',
  },
  '@media (max-width: 960px)': {
   
    '& .sidebar-box': {
      position: 'absolute',
      left: 0,
      top: -30,
      zIndex: 99999999999,
      background: 'white',
      paddingTop: 20
    },
    '& .sidebar-text': {
      display: 'none'
    },
    '& .right-box': {
      paddingLeft: 40
    },
    '& .sidebar-head-text': {
      display: 'flex !important'
    }, 
    '& .heading-text': {
      justifyContent: 'space-between !important'
    },
    '& .innerCard': {
      justifyContent: 'center !important',
    },
    '& .menu-icon': {
      display: 'inline-block !important',
    }
  },
  '@media (max-width: 600px)': {
    '& .heading-text': {
      flexDirection: 'column',
    },
    '& .right-box': {
      paddingRight: '16px !important',
      paddingLeft: 16,
    },
  }
})

const CardGrid = styled(Grid)({
  '@media(max-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    '& .subHeadingText': {
      height: 'unset !important',
    },
  },
  '& .innerCardBox': {
    padding:"24px 20px 32px 20px",
    '& .subHeadingText': {
      textTransform: 'uppercase',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    '@media(min-width: 960px) and (max-width: 1199px)': {
      padding: '20px 10px',
      '& .headingText': {
        fontSize: '16px !important'
      },
      '& .subHeadingText': {
        fontSize: '14px !important',
        lineHeight: 'normal !important'
      }
    }
  }
})

const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});
  const styles = {
    
  };
// Customizable Area End