import React, { Component } from 'react'
// Customizable Area Start
import {
  Grid,
  Box,
  TextField,
  Typography,
  createStyles,
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: Record<string, string>;
  newBtnClick: () => void | undefined;
  dealerBtnClick: () => void | undefined;
  handleCloseModal: () => void | undefined;
  // Customizable Area End
}

export class SignUpAsModelWeb extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    const style = this.props.classes;
    return (
      <>
        <Dialog
          open={true}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={style.signUp_ModelContainer}
          onClose={this.props.handleCloseModal}
        >
          <DialogContent className={style.signUp_Model_MainContainer}>
            <img src={require("./Singuptextborder.png")} alt="Singuptextborder" className={style.upborderimg} />
            <img src={require("./Singuptextborder.png")} alt="Singuptextborder" className={style.downborderimg} />
            <Typography className={style.messageText} >Sign Up as a</Typography>
            <Box className={style.buttons_main_container} >
              <Button onClick={this.props.newBtnClick} className={style.newUserButton} type="submit">New Customer</Button>
              <Typography className={style.dashLines} >------------------   or   ------------------</Typography>
              <Button onClick={this.props.dealerBtnClick} className={style.dealerUserButton} type="submit">Dealer</Button>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    )
  }
  // Customizable Area End
}
// Customizable Area Start
const Styles = () =>
  createStyles({
    buttons_main_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      flexShrink: 0,
    },
    dashLines: {
      color: " #4E4E4E",
      textAlign: "center",
      fontFamily: "Mulish",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      "@media (max-width: 575px)": {
        fontSize: "12px !important",
      }
    },
    upborderimg: {
      "width": "119px",
      "height": "37px",
      position: "absolute",
      top: "-15px",
      left: "-50px",
      "@media(min-width:320px) and (max-width:768px)":{
        display:"none"
      }
    },
    downborderimg: {
      "width": "119px",
      "height": "37px",
      position: "absolute",
      bottom: "-15px",
      right: "-60px",
      transform: "rotate(90deg)",
      "@media(min-width:320px) and (max-width:768px)":{
        display:"none"
      }
    },
    newUserButton: {
      width: "322px",
      height: "52px",
      background: "#000",
      border: "none",
      color: "#ffff",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "8px",
      padding: "10px 16px",
      textTransform: "capitalize",
      "&:hover": {
        background: "Black",
      },
      "@media(min-width:320px) and (max-width:575px)":{
        width: "100%",
        fontSize: "12px",
      }
    },
    dealerUserButton: {
      width: "322px",
      height: "52px",
      color: "#000",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "8px",
      padding: "10px 16px",
      background: "#FFF",
      border: "1px solid var(--Basic-Black, #000)",
      textTransform: "capitalize",
      "&:hover": {
        background: "#fff",
      },
      "@media(min-width:320px) and (max-width:768px)":{
        width: "100%",
        fontSize: "12px",
      }
    },
    signUp_Model_MainContainer: {
      padding: "50px 100px !important",
      display: "flex",
      width: "520px",
      height: "355px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "48px",
      flexShrink: 0,
      "@media (min-width:768px) and (max-width:1199px)": {
        width: "unset",
        height: "unset",
      },
      "@media (min-width:576px) and (max-width:768px)": {
        width: "unset",
        height: "unset",
      },
      "@media(min-width:320px) and (max-width:575px)":{
        width: "unset",
        height: "unset",
        padding: " 20px !important",
      }
    },
    messageText: {
      color: "#000",
      textAlign: "center",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      "@media(min-width:320px) and (max-width:768px)":{
        fontSize: "20px",
      }
    },
    signUp_ModelContainer: {    
      position: "relative",
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100% !important",
        maxHeight: "100% !important"
      },
      "& .MuiDialog-paper": {
        overflowY: "unset"
      },
      "& .MuiDialog-container ":{
        backdropFilter: "blur(6px) !important"
      }
    }
  });
export default withStyles(Styles)(SignUpAsModelWeb);
// Customizable Area End